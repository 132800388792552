import React from 'react'
import styled from 'styled-components/macro'

const TextBox = styled.span`
  min-height: 38px;
  padding: 8px;
  line-height: 20px;
  border: 1px solid ${props => (props.error ? '#dc3545' : '#ced4da')};
  border-radius: 4px;
  color: #495057;
  background-color: whitesmoke;
`

export default TextBox
