import React from 'react'
import styled from 'styled-components/macro'
import { Button } from './controls'
import Modal from './Modal'

export const infoModalRef = React.createRef()

export const openInfo = (message, descripion) => {
  infoModalRef.current.openModal(message, descripion)
}

class InfoModal extends React.Component {
  state = { open: false, more: false }

  openModal = (message, descripion) => {
    this.setState({ open: true, message, descripion })
  }

  closeModal = () => {
    this.setState({ open: false, more: false })
  }

  showMore = () => {
    this.setState({ more: true })
  }

  render() {
    if (!this.state.open) return null

    return (
      <Modal
        type="info"
        header="ინფორმაცია"
        footer={this.renderActions}
        css="min-width: 400px; padding: 12px; color: #28a745"
        closeModal={this.closeModal}
      >
        {this.state.message}
        {this.state.descripion && (
          <div>
            {!this.state.more && (
              <div className="text-blue pointer" onClick={this.showMore}>
                მეტის ნახვა
              </div>
            )}
            {this.state.more && <div>{this.state.descripion}</div>}
          </div>
        )}

        <div className="text-right gap-top">
          <Button onClick={this.closeModal}>გასაგებია</Button>
        </div>
      </Modal>
    )
  }
}

export default InfoModal
