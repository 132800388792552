import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataTable from '../DataTable'
import { Project, Report } from '../../api'
import { withTranslations } from '../Translation'
import DataMatrix from '../DataMatrix'

const timeFormatter = (totalSeconds, trans) => {
  let time = ''

  const minutes = Math.trunc(totalSeconds / 60)
  const seconds = totalSeconds % 60

  if (minutes) {
    time =
      minutes.toString() + ' ' + (minutes === 1 ? trans.minute : trans.minutes)
  }

  if (seconds) {
    time +=
      (minutes ? ` ${trans.and} ` : '') +
      seconds +
      ' ' +
      (seconds === 1 ? trans.second : trans.seconds)
  }

  return time
}

class SystemReport extends React.PureComponent {
  state = {
    results: [],
    loading: true,
  }

  componentDidMount() {
    Report.system()
      .then((results) => {
        if (results) {
          this.setState({
            results,
          })
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.projectNumber,
        name: 'projectCode',
      },
      {
        title: trans.projectName,
        name: 'projectName',
      },
      {
        title: trans.activeLeftCalls,
        name: 'activeCalls',
        formatter: (active, { tokensLeft }) => {
          return `${active} / ${tokensLeft}`
        },
      },
      {
        title: trans.talkTime,
        name: 'talkTimeAverage',
        exportUnformatted: true,
        formatter: (totalSeconds) => timeFormatter(totalSeconds, trans),
      },
      {
        title: trans.contactTime,
        name: 'contactTime',
        formatter: (totalSeconds, { contacted, totalCalls }) => {
          let time = ''

          const minutes = Math.trunc(totalSeconds / 60)
          const seconds = totalSeconds % 60

          if (minutes) {
            time =
              minutes.toString() +
              ' ' +
              (minutes === 1
                ? this.props.trans.minute
                : this.props.trans.minutes)
          }

          if (seconds) {
            time +=
              (minutes ? ` ${this.props.trans.and} ` : '') +
              seconds +
              ' ' +
              (seconds === 1
                ? this.props.trans.second
                : this.props.trans.seconds)
          }

          time +=
            time &&
            ` / ${(totalCalls && (contacted / totalCalls) * 100).toFixed(2)}`

          return time
        },
      },
      {
        title: trans.succeeded,
        name: 'succeeded',
        formatter: (value, { totalCalls }) => {
          return `${value} / ${(
            totalCalls && (value / totalCalls) * 100
          ).toFixed(2)}%`
        },
      },
      {
        title: trans.stopped,
        name: 'stopped',
        formatter: (value, { totalCalls }) => {
          return `${value} / ${(
            totalCalls && (value / totalCalls) * 100
          ).toFixed(2)}%`
        },
      },
      {
        title: trans.pending,
        name: 'pending',
        formatter: (value, { totalCalls }) => {
          return `${value} / ${(
            totalCalls && (value / totalCalls) * 100
          ).toFixed(2)}%`
        },
      },
      {
        title: trans.failed,
        name: 'failed',
        formatter: (value, { totalCalls }) => {
          return `${value} / ${(
            totalCalls && (value / totalCalls) * 100
          ).toFixed(2)}%`
        },
      },
      {
        title: trans.badCall,
        name: 'badCall',
        formatter: (value, { totalCalls }) => {
          return `${value} / ${(
            totalCalls && (value / totalCalls) * 100
          ).toFixed(2)}%`
        },
      },
    ]

    return this.memoizedColumns
  }

  render() {
    return (
      <>
        <DataTable
          rows={this.state.results}
          columns={this.columns}
          loading={this.state.loading}
        />
      </>
    )
  }
}

export default withTranslations('SystemReport')(SystemReport)
