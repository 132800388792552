import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input, Button, Checkbox } from '../controls'
import Modal, { withModalManager } from '../Modal'
import DataTable from '../DataTable'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import { Config, Role, User, Call } from '../../api'
import { withTranslations } from '../Translation'

const fields = ['comment']

class CallComment extends React.PureComponent {
  state = { loading: true }

  async componentDidMount() {
    const { lifter, params } = this.props
    const { projectId, callId } = params

    try {
      const call = await Call.getOne(projectId, callId)
      this.setState({ call })
      FormHelper.setValues(lifter, ['reviewStatus', 'comment'], {
        reviewStatus: call.reviewStatus,
        comment: call.comment,
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  reviewStatuses = [
    { value: 100, label: this.props.trans.needsReview },
    { value: 200, label: this.props.trans.completed },
  ]

  submit = () => {
    const { lifter, onSuccess, closeModal, params } = this.props

    const { projectId, callId } = params

    FormHelper.setTouched(lifter, fields, true)

    if (FormHelper.isInvalid(lifter, fields)) {
      return
    }

    this.setState({ submitting: true })

    const model = FormHelper.getValues(lifter, ['reviewStatus', 'comment'])

    Call.putComment(projectId, callId, model)
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  renderActions = () => {
    return (
      <div className="text-right">
        <Button onClick={this.submit}>{this.props.trans.save}</Button>
      </div>
    )
  }

  render() {
    if (this.state.loading) return null

    const { call } = this.state
    const { closeModal, trans } = this.props

    return (
      <Modal
        header={`${trans.comments} - ${call.identifier}`}
        footer={this.renderActions}
        css="max-width: 560px; width: 100vw; min-height: 200px"
        className="pad flex-row-stretch"
        ref={(ref) => (this.modal = ref)}
        closeModal={closeModal}
      >
        <Select
          options={this.reviewStatuses}
          placeholder={trans.reviewStatus}
          name="reviewStatus"
          className="gap-bottom"
          isClearable
        />

        <Input
          className="flex-fill"
          name="comment"
          placeholder={trans.comments}
          textArea
        />
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('CallComment')(
    withStateLifter((props) => <CallComment {...props} />)
  )
)
