import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input } from '../controls'
import DataTable from '../DataTable/DataTable'
import { Role } from '../../api'
import { withTranslations } from '../Translation'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import RoleEdit from './RoleEdit'
import RoleDelete from './RoleDelete'
import Permissions, { hasPermission } from '../../permissions'

class RoleList extends React.Component {
  users = []

  state = { users: [], roles: [] }

  memoizedColumns = null
  memoizedLocale = null
  memoizedTrans = null

  componentDidMount() {
    this.fetch()
  }

  columns = () => {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.roleName,
        name: 'roleName',
      },
      {
        title: trans.roleNameEn,
        name: 'roleNameEn',
      },
      {
        title: trans.roleNameRu,
        name: 'roleNameRu',
      },
    ]

    return this.memoizedColumns
  }

  onRowDoubleClick = ({ roleId }) => {
    this.modal.openModal({ roleId })
  }

  getContextMenuItems = ({ roleId }) => {
    var result = [
      {
        title: this.props.trans.edit,
        action: () => {
          this.modal.openModal({ roleId })
        },
        hide: !hasPermission(Permissions.RoleEdit),
      },
      {
        title: this.props.trans.delete,
        action: () => {
          this.deleteModal.openModal({ roleId })
        },
        hide: !hasPermission(Permissions.RoleDelete),
      },
    ].filter((x) => !x.hide)

    return result
  }

  fetch = async () => {
    this.roles = await Role.getAll()
    this.filter()
  }

  get lifter() {
    return this.props.lifter
  }

  handleChanged = () => {
    this.filter()
  }

  filter = () => {
    this.setState((state) => {
      const roleName = FormHelper.getValue(this.lifter, 'roleName')

      const normalized = roleName && roleName.toLowerCase()
      return {
        roles: this.roles.filter(
          (r) =>
            !roleName ||
            (r.roleName && r.roleName.toLowerCase().includes(normalized)) ||
            (r.roleNameEn && r.roleNameEn.toLowerCase().includes(normalized)) ||
            (r.roleNameRu && r.roleNameRu.toLowerCase().includes(normalized))
        ),
      }
    })
  }

  handleSuccess = () => {
    this.fetch()
  }

  render() {
    const { trans } = this.props

    return (
      <>
        <div css="flex: 1; display: flex; flex-direction: column">
          <div css="margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center">
            <div css="display: flex; align-items: center;">
              <Input
                name="roleName"
                onChanged={this.handleChanged}
                placeholder={trans.roleName}
                className="gap-right"
                css="width: 260px"
              />

              <div className="gap-left">{this.state.roles.length}</div>
            </div>

            {hasPermission(Permissions.RoleCreate) && (
              <RoleEdit
                trigger={trans.newRole}
                ref={(ref) => (this.modal = ref)}
                onSuccess={this.fetch}
              />
            )}

            <RoleDelete
              clientId={this.state.clientId}
              ref={(ref) => (this.deleteModal = ref)}
              onSuccess={this.handleSuccess}
            />
          </div>

          <div css="display: flex; align-items: stretch; flex: 1">
            <DataTable
              excelExportName="clients"
              onRowDoubleClick={this.onRowDoubleClick}
              columns={this.columns()}
              rows={this.state.roles}
              getContextMenuItems={this.getContextMenuItems}
            />
          </div>
        </div>
      </>
    )
  }
}

export default withTranslations('RoleList')(withStateLifter(RoleList))
