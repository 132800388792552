import React from 'react'
import styled from 'styled-components/macro'
import {
  MultiGrid,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  ArrowKeyStepper,
  SortIndicator,
  SortDirection,
} from 'react-virtualized'
import Excel from 'exceljs/modern.browser'
import Container from './Container'
import ContextMenu from '../ContextMenu'
import Draggable from 'react-draggable'
import ClipboardImport from '../ClipboardImport'
import Loader from '../Loader'
import { withTranslations } from '../Translation'

const ROW_HEIGHT = 34

const isNullOrUndefined = (value) => value === null || value === undefined

class DataTable extends React.Component {
  constructor(props) {
    super(props)

    this._cache = new CellMeasurerCache({
      defaultWidth: 100,
      minWidth: 10,
      fixedHeight: true,
    })

    this.state = {
      _cache: this._cache,
      scrollToRow: null,
      scrollToColumn: null,
      menuItems: [],
      isMenuOpen: false,
      pageX: 0,
      pageY: 0,
      sortBy: null,
      sortDirection: null,
      innerList: [...props.rows],
      rows: props.rows,
    }
  }

  static defaultProps = { excelExport: true }

  static getDerivedStateFromProps(props, state) {
    if (state.rows !== props.rows) {
      state._cache.clearAll()

      return {
        rows: props.rows,
        innerList: [...props.rows],
        scrollToRow: null,
        scrollToColumn: null,
      }
    }

    return null
  }

  handleScroll = (event) => {
    const { scrollTop: rowScrollLeft } =
      this._grid._topRightGrid._scrollingContainer

    const { scrollLeft } = event.target

    if (rowScrollLeft !== scrollLeft) {
      this._grid._topRightGrid._scrollingContainer.scrollLeft = scrollLeft
    }
  }

  componentWillUnmount() {
    if (
      this._grid &&
      this._grid._bottomRightGrid &&
      this._grid._bottomRightGrid._scrollingContainer
    )
      this._grid._bottomRightGrid._scrollingContainer.removeEventListener(
        'scroll',
        this.handleScroll
      )
  }

  componentDidMount() {
    // react-virtualized MutliGrid doesn't always sync scroll due to react weird behavior
    // so we use native scroll event to sync always
    setTimeout(() => {
      if (
        this._grid &&
        this._grid._bottomRightGrid &&
        this._grid._bottomRightGrid._scrollingContainer
      ) {
        this._grid._bottomRightGrid._scrollingContainer.addEventListener(
          'scroll',
          this.handleScroll
        )
      }
    })
  }

  exportToExcel = () => {
    const { excelExportName } = this.props
    var workbook = new Excel.Workbook()

    var sheet = workbook.addWorksheet(excelExportName || 'Table')

    sheet.columns = this.props.columns.map((c) => ({
      header: c.title,
      key: c.name,
    }))

    const { columns } = this.props

    const exportList = this.state.innerList.map((row) => {
      const formattedRow = { ...row }

      columns
        .filter((c) => c.formatter && !c.exportUnformatted)
        .forEach((c) => {
          formattedRow[c.name] = c.formatter(row[c.name], row)
        })

      return formattedRow
    })

    sheet.addRows(exportList)

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = (excelExportName || 'download') + '.xlsx'
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  excelExportMenuItem = () => ({
    title: this.props.trans.exportToExcel,
    action: this.exportToExcel,
  })

  onCellContextMenu = (rowIndex, columnIndex) => (event) => {
    if (rowIndex > 0) {
      event.preventDefault()
      event.stopPropagation()

      this.selectCell(rowIndex, columnIndex)

      const { getContextMenuItems, excelExport, clipboardImport } = this.props

      if (
        !getContextMenuItems &&
        !excelExport &&
        !clipboardImport &&
        !this.props.appendable
      )
        return

      const { innerList } = this.state

      const row = innerList[rowIndex - 1]
      const menuItems = getContextMenuItems ? getContextMenuItems(row) : []

      if (this.props.appendable) {
        menuItems.push(this.appendMenuItem())
        menuItems.push(this.deleteMenuItem())
      }

      if (excelExport) {
        menuItems.push(this.excelExportMenuItem())
      }

      if (clipboardImport) {
        menuItems.push(this.clipboardImportMenuItem())
      }

      let touch = event
      if (event.type === 'touchend') {
        touch = event.changedTouches[0]
      } else if (event.type === 'touchstart') {
        touch = event.touches[0]
      }

      const { pageX, pageY } = touch

      setTimeout(() => {
        this.setState({
          isMenuOpen: true,
          menuItems,
          pageX,
          pageY,
        })
      })
    }
  }

  handleColumnClick = (column) => () => {
    let { sortDirection, innerList, sortBy } = this.state

    sortDirection =
      sortBy === column.name && sortDirection
        ? sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : null
        : SortDirection.ASC

    sortBy = sortDirection ? column.name : null
    const sortKey = column.sortColumn || sortBy

    if (!sortDirection) {
      innerList = [...this.props.rows]
    } else {
      innerList = [...innerList].sort(function (row1, row2) {
        const value1 = row1[sortKey]
        const value2 = row2[sortKey]

        const isVoid1 = isNullOrUndefined(value1)
        const isVoid2 = isNullOrUndefined(value2)

        if (isVoid1 && isVoid2) {
          return 0
        }

        if (isVoid1) {
          return sortDirection === SortDirection.ASC ? -1 : 1
        }

        if (isVoid2) {
          return sortDirection === SortDirection.ASC ? 1 : -1
        }

        if (
          (sortDirection === SortDirection.ASC && value1 < value2) ||
          (sortDirection === SortDirection.DESC && value1 > value2)
        ) {
          return -1
        } else if (
          (sortDirection === SortDirection.ASC && value1 > value2) ||
          (sortDirection === SortDirection.DESC && value1 < value2)
        ) {
          return 1
        } else {
          return 0
        }
      })
    }

    this.setState({
      sortBy,
      sortDirection,
      innerList,
    })
  }

  resizeColumn = (event, columnIndex, deltaX) => {
    const column = this.columnRefs[columnIndex]
    const underMouse = document.elementFromPoint(event.clientX, event.clientY)

    if (
      column &&
      !column.contains(underMouse) &&
      column.nextSibling &&
      !column.nextSibling.contains(underMouse)
    ) {
      event.preventDefault()
      return
    }

    const width = this._cache._columnWidthCache[`0-${columnIndex}`] + deltaX

    if (width <= ROW_HEIGHT) {
      event.preventDefault()
      return
    }

    for (let rowIndex = 0; rowIndex < this._cache._rowCount; rowIndex++) {
      this._cache.set(rowIndex, columnIndex, width, ROW_HEIGHT)
    }

    this._grid.recomputeGridSize({ columnIndex, rowIndex: 0 })
  }

  columnRefs = {}
  resizeRefs = {}

  onCellFocus = (rowIndex, columnIndex) => () => {
    this.selectCell(rowIndex, columnIndex)
  }

  handleChange = (rowIndex, columnIndex) => (event) => {
    const { columns } = this.props

    const column = columns[columnIndex]
    const { value } = event.target

    if (column.number && value) {
      if (
        !column.unsigned &&
        (value.split('-').length > 2 || value.indexOf('-') > 0)
      )
        return
      if (!column.integer && value.split('.').length > 2) return

      if (value !== '-' && value !== '.') {
        if (column.integer && isNaN(parseInt(value))) return

        if (!column.integer && isNaN(parseFloat(value))) return
      }
    }

    let { innerList } = this.state

    innerList = [...innerList]
    innerList[rowIndex - 1][column.name] = value

    this.setState({ innerList })
  }

  onInputFocus = (event) => {
    event.target.select()
  }

  handleKeyPress = (rowIndex, columnIndex) => (event) => {
    const {
      key,
      target: { value },
    } = event

    const { number, integer, unsigned } = this.props.columns[columnIndex]

    if (
      number &&
      (key < '0' ||
        key > '9' ||
        (integer && key === '.') ||
        (unsigned && key === '-') ||
        (!unsigned && key === '-' && (value.match(/\-/g) || []).length > 0) ||
        (!integer && key === '.' && (value.match(/\./g) || []).length > 0))
    )
      event.preventDefault()
  }

  longTouchTimeout = null

  onCellTouchStart = (rowIndex, columnIndex) => (event) => {
    event.persist()
    this.longTouchTimeout = setTimeout(() => {
      this.onCellContextMenu(rowIndex, columnIndex)(event)
      delete this.longTouchTimeout
    }, 500)
  }

  onCellTouchEnd = (rowIndex, columnIndex) => (event) => {
    if (this.longTouchTimeout) {
      clearTimeout(this.longTouchTimeout)
      delete this.longTouchTimeout
    } else {
      this.onCellContextMenu(rowIndex, columnIndex)(event)
    }
  }

  cellRenderer = ({ key, rowIndex, columnIndex, parent, style }) => {
    const { columns, cellClassName } = this.props

    const { innerList, scrollToRow, scrollToColumn, sortBy, sortDirection } =
      this.state

    const column = columns[columnIndex]

    let content

    if (rowIndex === 0) {
      content = (
        <div
          className="cell header-cell"
          key={key}
          style={style}
          ref={(ref) => (this.columnRefs[columnIndex] = ref)}
        >
          <div
            className="header-title"
            onClick={this.handleColumnClick(column)}
          >
            <span>{column.title}</span>
            {sortBy === column.name ? (
              <SortIndicator sortDirection={sortDirection} />
            ) : (
              <div className="ReactVirtualized__Table__sortableHeaderIcon" />
            )}
          </div>
          <Draggable
            axis="x"
            defaultClassName="resize-handle"
            onDrag={(event, { deltaX }) => {
              this.resizeColumn(event, columnIndex, deltaX)
            }}
            position={{ x: 0 }}
            zIndex={999}
            ref={(ref) => (this.resizeRefs[columnIndex] = ref)}
          >
            <span />
          </Draggable>
        </div>
      )
    } else {
      const row = innerList[rowIndex - 1]

      const editing =
        scrollToRow === rowIndex &&
        scrollToColumn === columnIndex &&
        column.editable

      const value = row[column.name]
      const cellValue = value === undefined || value === null ? '' : value

      content = (
        <div
          tabIndex={editing || !column.editable ? undefined : '0'}
          onFocus={this.onCellFocus(rowIndex, columnIndex)}
          className={
            'cell row-cell' +
            (column.className
              ? ` ${
                  typeof column.className === 'function'
                    ? column.className(value, row)
                    : column.className
                }`
              : '') +
            (cellClassName
              ? ` ${
                  typeof cellClassName === 'function'
                    ? cellClassName(column, row)
                    : cellClassName
                }`
              : '') +
            (scrollToRow === rowIndex ? ' selected-row' : '') +
            (scrollToColumn === columnIndex ? ' selected-column' : '') +
            (editing ? ' cell-edit' : '') +
            (!editing &&
            column.editable &&
            !this.isValueValid(column, cellValue)
              ? ' cell-error'
              : '')
          }
          style={style}
          onClick={() => this.selectCell(rowIndex, columnIndex)}
          onContextMenu={this.onCellContextMenu(rowIndex, columnIndex)}
          onTouchStart={this.onCellTouchStart(rowIndex, columnIndex)}
          onTouchEnd={this.onCellTouchEnd(rowIndex, columnIndex)}
          onDoubleClick={() => this.handleDoubleClick(rowIndex, columnIndex)}
        >
          {editing ? (
            <input
              ref={(ref) => (this.input = ref)}
              autoFocus
              className={
                !this.isValueValid(column, cellValue) ? 'error' : undefined
              }
              value={cellValue}
              onChange={this.handleChange(rowIndex, columnIndex)}
              onKeyPress={this.handleKeyPress(rowIndex, columnIndex)}
              onFocus={this.onInputFocus}
            />
          ) : column.formatter ? (
            column.formatter(cellValue, row)
          ) : (
            cellValue
          )}
        </div>
      )
    }

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {content}
      </CellMeasurer>
    )
  }

  isRowValid = () => {
    const { columns } = this.props
    const { scrollToRow, innerList } = this.state

    if (scrollToRow > 0) {
      const row = innerList[scrollToRow - 1]

      return columns.every((column) =>
        this.isValueValid(column, row[column.name])
      )
    }

    return true
  }

  getInvalidColumnIndex = () => {
    const { columns } = this.props

    const { scrollToRow, innerList } = this.state

    const row = innerList[scrollToRow - 1]

    return columns.findIndex(
      (column) => !this.isValueValid(column, row[column.name])
    )
  }

  isValueValid = (column, value) => {
    return !column.required || !!value
  }

  isInputValid = () => {
    const { innerList, scrollToColumn, scrollToRow } = this.state
    const column = this.props.columns[scrollToColumn]

    const value = innerList[scrollToRow - 1][column.name]
    return this.isValueValid(column, value)
  }

  selectCell = (rowIndex, columnIndex) => {
    const { scrollToRow, scrollToColumn } = this.state

    const { columns } = this.props

    if (
      rowIndex > 0 &&
      columnIndex >= 0 &&
      (scrollToRow !== rowIndex || scrollToColumn !== columnIndex)
    ) {
      const column = columns[scrollToColumn]

      if (
        column &&
        (rowIndex !== scrollToRow || !columns[columnIndex].editable) &&
        !this.isRowValid()
      ) {
        if (this.isInputValid()) {
          this.setState({ scrollToColumn: this.getInvalidColumnIndex() })
        } else if (this.input) {
          this.input.focus()
        }

        return
      }

      const { onRowSelected } = this.props

      this.setState(
        {
          isMenuOpen: false,
          scrollToRow: rowIndex,
          scrollToColumn: columnIndex,
        },
        () => {
          if (onRowSelected) onRowSelected(this.state.innerList[rowIndex - 1])
        }
      )
    }
  }

  handleDoubleClick = (rowIndex) => {
    const { onRowDoubleClick } = this.props

    if (onRowDoubleClick) onRowDoubleClick(this.state.innerList[rowIndex - 1])
  }

  closeMenu = () => {
    this.setState({ isMenuOpen: false })
  }

  columnWidth = (params) => {
    // need to increase width by 1 pixel, ellipsis to disappear
    return this._cache.columnWidth(params) + 1
  }

  appendRow = (insert = false) => {
    if (this.props.appendable) {
      if (!this.isRowValid()) return

      let { innerList, scrollToRow, scrollToColumn } = this.state

      innerList = [...innerList]
      const nextState = { innerList }
      const newRow = {}

      if (insert && scrollToRow) innerList.splice(scrollToRow - 1, 0, newRow)
      else {
        innerList.push(newRow)
        nextState.scrollToRow = innerList.length
      }

      if (scrollToColumn === null) nextState.scrollToColumn = 0

      this.setState(nextState)
      this.props.rows.splice(scrollToRow, 0, newRow)
    }
  }

  removeRow = () => {
    let { innerList, scrollToRow } = this.state

    innerList = [...innerList]

    const [row] = innerList.splice(scrollToRow - 1, 1)

    if (scrollToRow > innerList.length) {
      scrollToRow = innerList.length || null
    }

    this.setState({ innerList, scrollToRow })
    this.props.rows.splice(this.props.rows.indexOf(row), 1)
  }

  handleKeyDown = (event) => {
    if (!this.props.appendable) return

    const { innerList, scrollToRow } = this.state

    if (event.ctrlKey && event.key === 'Delete') {
      event.preventDefault()

      this.removeRow()
    }

    const append =
      ['ArrowDown', 'Enter'].includes(event.key) &&
      (!scrollToRow || scrollToRow === innerList.length)

    if (append || event.key === 'Insert') {
      this.appendRow(!append)
    }
  }

  handleScrollChange = ({ scrollToRow, scrollToColumn }) => {
    this.selectCell(scrollToRow, scrollToColumn)
  }

  importFromClipboard = (append = false) => {
    this.clipboardAppend = append

    if (navigator && navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard
        .readText()
        .then((clipText) => this.importFromText(clipText))
        .catch(() => {
          this.importModal.openModal()
        })
    } else {
      this.importModal.openModal()
    }
  }

  handleClipboardTextSuccess = (text) => {
    this.importFromText(text)
  }

  appendFromClipboard = () => {
    this.importFromClipboard(true)
  }

  importFromText = (text) => {
    const { scrollToRow, scrollToColumn, innerList } = this.state
    const { appendable, columns } = this.props

    const editableColumns = columns.filter((c) => c.editable)
    const lines = text.split(/\r?\n/)
    const rows = []

    if (
      (this.clipboardAppend ||
        scrollToRow === null ||
        scrollToColumn === null) &&
      appendable
    ) {
      lines.forEach((line) => {
        if (!line.trim()) return

        const values = line.split('\t')
        const row = Object.assign(
          ...editableColumns.map((col, index) =>
            index >= values.length ? {} : { [col.name]: values[index] }
          )
        )
        rows.push(row)
      })
    } else if (
      !this.clipboardAppend &&
      scrollToRow !== null &&
      scrollToColumn !== null
    ) {
      let rowIndex = scrollToRow - 1

      for (const line of lines) {
        if (!line.trim()) break
        const values = line.split('\t')

        if (rowIndex >= innerList.length) {
          if (!appendable) break

          const row = Object.assign(
            ...editableColumns.map((col, index) =>
              index >= values.length ? {} : { [col.name]: values[index] }
            )
          )
          rows.push(row)
        } else {
          const row = innerList[rowIndex]
          editableColumns.forEach((col, index) => {
            if (index < values.length) row[col.name] = values[index]
          })
        }

        rowIndex++
      }
    }

    this.setState((state) => ({ innerList: [...state.innerList, ...rows] }))
    this.props.rows.push(...rows)
  }

  appendMenuItem = () => ({
    title: this.props.trans.appendRow,
    action: this.appendRow,
  })

  deleteMenuItem = () => ({
    title: this.props.trans.removeRow,
    action: this.removeRow,
  })

  clipboardAppendMenuItem = () => ({
    title: this.props.trans.importFromClipboard,
    action: this.appendFromClipboard,
  })

  clipboardImportMenuItem = () => ({
    title: this.props.trans.importFromClipboard,
    action: this.importFromClipboard,
  })

  onGridContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { pageX, pageY } = event

    const allItems =
      this.props.getContextMenuItems && this.props.getContextMenuItems({})
    const menuItems = allItems
      ? allItems.filter((item) => item.tableContextMenu)
      : []

    if (this.props.appendable) {
      menuItems.push(this.appendMenuItem())
    }

    if (this.props.excelExport) {
      menuItems.push(this.excelExportMenuItem())
    }

    if (this.props.clipboardImport) {
      menuItems.push(this.clipboardAppendMenuItem())
    }

    if (menuItems.length) {
      setTimeout(() => {
        this.setState({
          isMenuOpen: true,
          menuItems: menuItems,
          pageX,
          pageY,
        })
      })
    }
  }

  render() {
    const { columns, clipboardImport, loading } = this.props
    const {
      scrollToRow,
      scrollToColumn,
      isMenuOpen,
      menuItems,
      pageX,
      pageY,
      sortBy,
      sortDirection,
      innerList,
    } = this.state

    return (
      <>
        {isMenuOpen && (
          <ContextMenu
            close={this.closeMenu}
            items={menuItems}
            pageX={pageX}
            pageY={pageY}
          />
        )}

        {clipboardImport && (
          <ClipboardImport
            ref={(ref) => (this.importModal = ref)}
            onSuccess={this.handleClipboardTextSuccess}
          />
        )}

        <Container
          onKeyDown={this.handleKeyDown}
          onContextMenu={this.onGridContextMenu}
          onTouchStart={this.onGridTouchStart}
          onTouchEnd={this.onGridTouchEnd}
        >
          {loading && <Loader />}

          <ArrowKeyStepper
            className="arrow-key-stepper"
            onScrollToChange={this.handleScrollChange}
            mode="cells"
            rowCount={innerList.length + 1}
            columnCount={columns.length}
            isControlled
            scrollToRow={scrollToRow}
            scrollToColumn={scrollToColumn}
          >
            {({
              onSectionRendered,
              scrollToRow: rowIndex,
              scrollToColumn: columnIndex,
            }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <MultiGrid
                    ref={(ref) => (this._grid = ref)}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    columns={columns}
                    innerList={innerList}
                    cellRenderer={this.cellRenderer}
                    columnWidth={this.columnWidth}
                    columnCount={columns.length}
                    fixedRowCount={1}
                    height={height}
                    rowHeight={ROW_HEIGHT}
                    rowCount={innerList.length + 1}
                    width={width}
                    overscanRowCount={0}
                    overscanColumnCount={0}
                    //deferredMeasurementCache={this._cache} has performance issue
                    classNameTopRightGrid="header-row"
                    classNameBottomRightGrid="grid-body"
                    onSectionRendered={onSectionRendered}
                    scrollToRow={rowIndex}
                    scrollToColumn={columnIndex}
                  />
                )}
              </AutoSizer>
            )}
          </ArrowKeyStepper>
        </Container>
      </>
    )
  }
}

export default withTranslations('DataTable')(DataTable)
