export const results = {
  pending: 100,
  success: 200,
  reviewing: '200.100',
  callLater: 300,

  badCall: 400,
  stopped: 500,

  failed: 600,

  canceled: 900,
}

export const statuses = {
  pending: 100,

  calling: 110,
  talking: 120,

  ended: 200,

  busy: 600,
  noAnswer: 610,
  unreachable: 620,
  unassigned: 650,
  failedOther: 690,
}

export const stages = {
  ready: 'ready',
  calling: 'calling',
  failed: 'failed',
  talking: 'talking',
  ended: 'ended',
}

export const hours = [
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
]

export const minutes = [
  { value: '00', label: '00' },
  { value: '05', label: '05' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
  { value: '30', label: '30' },
  { value: '35', label: '35' },
  { value: '40', label: '40' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
  { value: '55', label: '55' },
]
