import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input } from '../controls'
import DataTable from '../DataTable/DataTable'
import { User, Role } from '../../api'
import { withTranslations } from '../Translation'
import withStateLifter from '../StateLifter'
import { FormHelper, StringHelper } from '../helpers'
import UserRoles from './UserRoles'
import UserEdit from './UserEdit'
import Permissions, { hasPermission } from '../../permissions'

class UserList extends React.Component {
  users = []

  state = { users: [], roles: [] }

  memoizedColumns = null
  memoizedLocale = null
  memoizedTrans = null

  componentDidMount() {
    Role.getAll().then((roles) => {
      const self = this
      this.setState({
        roles: roles.map((role) => ({
          value: role.roleId,
          get label() {
            return self.props.isDefaultLocale
              ? role.roleName
              : role['roleName' + StringHelper.capitalize(self.props.locale)]
          },
        })),
      })
    })

    this.fetch()
  }

  columns = () => {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.fullName,
        name: 'fullName',
        required: true,
      },
      {
        title: trans.roles,
        name: 'roleNames',
      },
      {
        title: 'SIP Username',
        name: 'sipUsername',
      },
      {
        title: 'PBX Server',
        name: 'pbxServerName',
      },
    ]

    return this.memoizedColumns
  }

  onRowDoubleClick = ({ userId }) => {
    this.modal.openModal({ userId })
  }

  getContextMenuItems = ({ userId }) => {
    return [
      {
        title: this.props.trans.edit,
        action: () => {
          this.editModal.openModal({ userId })
        },
      },
      hasPermission(Permissions.UserRoles) && {
        title: this.props.trans.manageRoles,
        action: () => {
          this.modal.openModal({ userId })
        },
      },
    ].filter(Boolean)
  }

  fetch = async () => {
    this.users = await User.getAll()
    this.filter()
  }

  get lifter() {
    return this.props.lifter
  }

  handleChanged = () => {
    this.filter()
  }

  filter = () => {
    this.setState((state) => {
      const { fullName, roleId } = FormHelper.getValues(this.lifter, [
        'fullName',
        'roleId',
      ])

      return {
        users: this.users.filter(
          (c) =>
            (!fullName ||
              (c.fullName || '')
                .toLowerCase()
                .includes(fullName.toLowerCase())) &&
            (!roleId || c.roles.includes(roleId))
        ),
      }
    })
  }

  handleSuccess = () => {
    this.fetch()
  }

  render() {
    const { trans } = this.props

    return (
      <>
        <UserRoles ref={(ref) => (this.modal = ref)} onSuccess={this.fetch} />

        <UserEdit
          ref={(ref) => (this.editModal = ref)}
          onSuccess={this.fetch}
        />

        <div css="flex: 1; display: flex; flex-direction: column">
          <div css="margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center">
            <div css="display: flex; align-items: center;">
              <Input
                name="fullName"
                onChanged={this.handleChanged}
                placeholder={trans.fullName}
                className="gap-right"
                css="width: 260px"
              />

              <Select
                name="roleId"
                options={this.state.roles}
                onChanged={this.handleChanged}
                placeholder={trans.role}
                css="width: 260px; flex: 1"
                isClearable
              />

              <div className="gap-left">{this.state.users.length}</div>
            </div>
          </div>

          <div css="display: flex; align-items: stretch; flex: 1">
            <DataTable
              excelExportName="clients"
              onRowDoubleClick={this.onRowDoubleClick}
              columns={this.columns()}
              rows={this.state.users}
              getContextMenuItems={this.getContextMenuItems}
            />
          </div>
        </div>
      </>
    )
  }
}

export default withTranslations('UserList')(withStateLifter(UserList))
