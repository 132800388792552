import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import styled from 'styled-components/macro'
import 'react-day-picker/lib/style.css'
import { format as fnsFormat, parse as fnsParse } from 'date-fns'
import moment from 'moment'
import { DateUtils } from 'react-day-picker'
import { LifterContext } from '../StateLifter'

const DayPickerInputContainer = styled.div`
  .date-input {
    height: 38px;
    padding: 8px;
    line-height: 20px;
    border: 1px solid ${(props) => (props.error ? '#dc3545' : '#ced4da')};
    border-radius: 4px;
    outline: 0;
    width: 100%;
    transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    border-bottom-left-radius: ${(props) =>
      props.overlayVisible && !props.upward ? '0px' : null};
    border-bottom-right-radius: ${(props) =>
      props.overlayVisible && !props.upward ? '0px' : null};

    border-top-left-radius: ${(props) =>
      props.overlayVisible && props.upward ? '0px' : null};
    border-top-right-radius: ${(props) =>
      props.overlayVisible && props.upward ? '0px' : null};

    &:focus {
      border-color: ${(props) => (props.error ? '#dc3545' : '#81b2e7')};
      box-shadow: ${(props) =>
        props.error
          ? '0 0 0 2px rgba(220,53,69,.25)'
          : '0 0 0 2px rgba(129,178,231,.3)'};
    }
  }

  .DayPickerInput-Overlay {
    left: -2px;
    top: ${(props) => (!props.upward ? '0px' : null)};
    bottom: ${(props) => (props.upward ? 'calc(100% + 37px)' : null)};
    overflow-y: hidden;
    border-radius: 4px;
    background-color: transparent;
    box-shadow: 0 ${(props) => (props.upward ? '-' : '')}1px 5px
      rgba(0, 0, 0, 0.14);

    border-top-left-radius: ${(props) => (props.upward ? null : '0px')};
    border-bottom-left-radius: ${(props) => (props.upward ? '0px' : null)};
  }

  .DayPicker {
    background-color: white;
    margin: 2px;
    border: 1px solid ${(props) => (props.error ? '#dc3545' : '#81b2e7')} !important;
    box-shadow: ${(props) =>
      props.error
        ? '0 0 0 2px rgba(220,53,69,.25)'
        : '0 0 0 2px rgba(129,178,231,.3)'};
    border-radius: 4px;

    border-top-left-radius: ${(props) => (props.upward ? null : '0px')};
    border-bottom-left-radius: ${(props) => (props.upward ? '0px' : null)};
  }

  .DayPickerInput {
    width: 100%;
  }

  .date-input:disabled {
    background-color: whitesmoke;
  }

  .DayPickerInput-OverlayWrapper::before {
    content: '';
    position: absolute;
    top: ${(props) => (!props.upward ? '-2px' : null)};
    left: 0px;
    right: 0px;
    height: 6px;
    z-index: 2;
    border-left: 1px solid ${(props) => (props.error ? '#dc3545' : '#81b2e7')};
    border-right: 1px solid ${(props) => (props.error ? '#dc3545' : '#81b2e7')};
    background-color: #fff;
    bottom: ${(props) => (props.upward ? 'calc(100% + 34px)' : null)};
  }

  .DayPickerInput-OverlayWrapper::after {
    content: '';
    position: absolute;
    top: ${(props) => (!props.upward ? '3px' : null)};
    width: 1px;
    right: 0px;
    height: 1px;
    z-index: 3;
    background-color: #fff;
    bottom: ${(props) => (props.upward ? 'calc(100% + 40px)' : null)};
  }

  .DayPicker-wrapper {
    padding-bottom: 0em;
  }

  .DayPicker-Month {
    margin: 0.1em;
  }

  .DayPicker-Caption {
    margin-top: 0.4em;
    margin-bottom: 0.3em;
  }

  .DayPicker-NavButton {
    top: 0.4em;
    right: 0.5em;
  }
`

const dateRegexp = /^\d\d\.\d\d\.\d\d\d\d$/
const charRegexp = /^[\d\.]$/

function parseDate(str, format, locale) {
  const date = moment(str, format, true)

  return date.isValid() ? date.toDate() : new Date()
  // const parsed = fnsParse(str, format, { locale })
  // if (DateUtils.isDate(parsed)) {
  //   return parsed
  // }
  // return undefined
}

function formatDate(date, format, locale) {
  return fnsFormat(date, format, { locale })
}

export default class DateInput extends React.Component {
  constructor(props, context) {
    super(props, context)

    const { name } = props

    if (context && context.getState && name) {
      const state = context.getState(name)

      if (!state) {
        const value = props.initialValue || ''

        context.setState(name, {
          value,
          touched: false,
          invalid: this.invalidate(value),
        })
      }

      context.setNotifier(name, () => {
        this.forceUpdate()
      })
    }
  }

  componentWillUnmount() {
    const { name } = this.props
    if (this.hasLifter && name) {
      this.context.removeNotifier(name)
    }
  }

  static contextType = LifterContext

  state = { overlayVisible: false }

  get hasLifter() {
    return this.context && !!this.context.getState
  }

  handleChange = (event) => {
    const { name, onChange, onChanged } = this.props
    let { value } = event.target

    if (value) {
      if (
        (value.length === 2 && !value.includes('.')) ||
        (value.length === 5 && (value.match(/\./g) || []).length <= 1)
      )
        value += '.'
    }

    const state = {
      value,
      touched: true,
      invalid: this.invalidate(value),
    }

    if (this.hasLifter) {
      this.context.setState(name, state, () => {
        if (onChanged) onChanged(name, state)
      })
    }

    if (onChange) onChange(value)
  }

  handleKeyPress = (event) => {
    const { value } = event.target

    if (
      !charRegexp.test(event.key) ||
      (event.key === '.' && (value.match(/\./g) || []).length === 2)
    )
      event.preventDefault()
  }

  handleDayClick = (date) => {
    if (!date) return

    const { name, onChange, onChanged } = this.props

    const value = formatDate(date, 'DD.MM.YYYY')

    const state = {
      value,
      touched: true,
      invalid: this.invalidate(value),
    }

    if (this.hasLifter) {
      this.context.setState(name, state, () => {
        if (onChanged) onChanged(name, state)
      })
    }

    if (onChange) onChange(date)
  }

  invalidate = (value) => {
    let invalid = false

    const { required } = this.props

    if (this.hasLifter && required) {
      if (required && !value) {
        invalid = true
      } else {
        invalid = !dateRegexp.test(value)

        if (!invalid) {
          invalid = !moment(value, 'DD.MM.YYYY', true).isValid()
        }
      }
    }

    return invalid
  }

  handleBlur = (event) => {
    const { name, onBlur } = this.props

    if (this.hasLifter && name) {
      this.context.setState(name, {
        touched: true,
        invalid: this.invalidate(this.getValue()),
      })
    }

    if (onBlur) this.onBlur(event)
  }

  getState = (name) => this.hasLifter && name && this.context.getState(name)

  getValue = () => {
    const state = this.getState(this.props.name)

    return (state && state.value) || ''
  }

  isTouched = () => {
    const state = this.getState(this.props.name)
    return (state && state.touched) || false
  }

  isInvalid = () => {
    const state = this.getState(this.props.name)
    return (state && state.invalid) || false
  }

  handleDayPickerShow = () => {
    this.setState({ overlayVisible: true })
  }

  handleDayPickerHide = () => {
    this.setState({ overlayVisible: false })
  }

  render() {
    const { className, value, upward, ...restProps } = this.props

    return (
      <DayPickerInputContainer
        error={this.isInvalid() && this.isTouched()}
        className={className}
        overlayVisible={this.state.overlayVisible}
        upward={upward}
      >
        <DayPickerInput
          value={this.getValue() || value}
          formatDate={formatDate}
          format="DD.MM.YYYY"
          parseDate={parseDate}
          dayPickerProps={{ onDayClick: this.handleDayClick }}
          inputProps={{
            maxLength: 10,
            autocomplete: 'off',
            placeholder: '',
            ...restProps,
            onChange: this.handleChange,
            onKeyPress: this.handleKeyPress,
            onBlur: this.handleBlur,
            className: `date-input`,
          }}
          onDayPickerShow={this.handleDayPickerShow}
          onDayPickerHide={this.handleDayPickerHide}
          //showOverlay
        />
      </DayPickerInputContainer>
    )
  }
}
