import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataTable from '../DataTable'
import { Project, Report } from '../../api'
import { withTranslations } from '../Translation'

class StatusReport extends React.PureComponent {
  state = {
    results: [],
    statuses: [],
    reasons: [],
    projects: [],
    fetching: true,
    loading: false,
  }

  get lifter() {
    return this.props.lifter
  }

  get projectId() {
    return FormHelper.getValue(this.lifter, 'projectId')
  }

  componentDidMount() {
    Project.getAll()
      .then((projects) => {
        this.setState({
          projects: projects.map((p) => ({
            value: p.projectId,
            label: p.projectName,
          })),
        })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  handleProjectChanged = () => {
    this.loadReport()
  }

  loadReport = () => {
    this.setState({ loading: true })
    const { trans } = this.props

    Promise.all([
      Report.results(this.projectId).then((results) => {
        results.push({
          result: 'total',
          count: results.reduce((sum, result) => sum + result.count, 0),
          percent: results.reduce((sum, result) => sum + result.percent, 0),
        })

        this.setState({ results })
      }),
      Report.statuses(this.projectId).then((statuses) => {
        statuses.push({
          status: 'total',
          count: statuses.reduce((sum, status) => sum + status.count, 0),
          percent: statuses.reduce((sum, status) => sum + status.percent, 0),
        })

        this.setState({ statuses })
      }),
      Report.reasons(this.projectId).then((reasons) => {
        reasons.push({
          reason: trans.total,
          count: reasons.reduce((sum, reason) => sum + reason.count, 0),
          percent: reasons.reduce((sum, reason) => sum + reason.percent, 0),
        })

        this.setState({ reasons })
      }),
    ]).finally(() => {
      this.setState({ loading: false })
    })
  }

  get resultColumns() {
    const { trans, locale } = this.props

    if (
      locale === this.memoizedResultLocale &&
      trans === this.memoizedResultTrans
    )
      return this.memoizedResultColumns

    this.memoizedResultLocale = locale
    this.memoizedResultTrans = trans
    this.memoizedResultColumns = [
      {
        title: trans.result,
        name: 'result',
        formatter: (value) => {
          return this.props.trans['result.' + value] || value
        },
      },
      {
        title: trans.count,
        name: 'count',
      },
      {
        title: trans.percent,
        name: 'percent',
        formatter: (value) => `${value.toFixed(2)}%`,
      },
    ]

    return this.memoizedResultColumns
  }

  get statusColumns() {
    const { trans, locale } = this.props

    if (
      locale === this.memoizedStatusLocale &&
      trans === this.memoizedStatusTrans
    )
      return this.memoizedStatusColumns

    this.memoizedStatusLocale = locale
    this.memoizedStatusTrans = trans
    this.memoizedStatusColumns = [
      {
        title: trans.status,
        name: 'status',
        formatter: (value) => {
          return this.props.trans['status.' + value] || value
        },
      },
      {
        title: trans.count,
        name: 'count',
      },
      {
        title: trans.percent,
        name: 'percent',
        formatter: (value) => `${value.toFixed(2)}%`,
      },
    ]

    return this.memoizedStatusColumns
  }

  get reasonColumns() {
    const { trans, locale } = this.props

    if (
      locale === this.memoizedReasonLocale &&
      trans === this.memoizedReasonTrans
    )
      return this.memoizedReasonColumns

    this.memoizedReasonLocale = locale
    this.memoizedReasonTrans = trans
    this.memoizedReasonColumns = [
      {
        title: trans.reason,
        name: 'reason',
      },
      {
        title: trans.count,
        name: 'count',
      },
      {
        title: trans.percent,
        name: 'percent',
        formatter: (value) => `${value.toFixed(2)}%`,
      },
    ]

    return this.memoizedReasonColumns
  }

  render() {
    const { fetching, loading } = this.state
    const { trans } = this.props

    return (
      <>
        <div className="flex-row-center gap-bottom">
          <Select
            name="projectId"
            className="flex-fill gap-right"
            options={this.state.projects}
            placeholder={trans.chooseProject}
            onChanged={this.handleProjectChanged}
            isDisabled={fetching || loading}
          />

          <Button
            disabled={!this.projectId || fetching || loading}
            onClick={this.loadReport}
          >
            {trans.refresh}
          </Button>
        </div>

        <div className="flex-row flex-fill">
          <div className="flex-column flex-fill gap-right" css="max-width: 25%">
            <DataTable
              rows={this.state.results}
              columns={this.resultColumns}
              loading={fetching || loading}
            />
          </div>

          <div className="flex-column flex-fill gap-right" css="max-width: 30%">
            <DataTable
              rows={this.state.statuses}
              columns={this.statusColumns}
              loading={fetching || loading}
            />
          </div>

          <div className="flex-column flex-fill">
            <DataTable
              rows={this.state.reasons}
              columns={this.reasonColumns}
              loading={fetching || loading}
            />
          </div>
        </div>
      </>
    )
  }
}

export default withTranslations('StatusReport')(
  withStateLifter((props) => <StatusReport {...props} />)
)
