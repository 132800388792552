import React from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { ReactComponent as OperIcon } from '../icons/oper.svg'
import { ReactComponent as ProjectIcon } from '../icons/project.svg'
import { ReactComponent as StatusIcon } from '../icons/status.svg'
import { ReactComponent as ReportIcon } from '../icons/report.svg'
import { ReactComponent as UsersIcon } from '../icons/users.svg'
import { ReactComponent as DataIcon } from '../icons/data.svg'
import { ReactComponent as PhonebookIcon } from '../icons/phonebook.svg'
import { ReactComponent as ClientsIcon } from '../icons/clients.svg'

const Container = styled.nav`
  width: 210px;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
`

const Header = styled.div`
  margin-top: 0px;
`

const Footer = styled.div`
  text-align: center;
  padding: 20px;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
`

const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  color: #6c757d;

  :hover {
    background-color: #83acd6 !important;
    color: #fff;
    text-decoration: none;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &.selected {
    background-color: #76a3d2;
    color: #fff;
  }
`

function selected(location, path) {
  return location.pathname === path ? 'selected' : undefined
}

const Sidebar = ({ location, trans }) => (
  <Container>
    <Header>
      <img src="/diflex.png" width="100%" alt="" />
    </Header>

    <Content>
      <Item to="call" className={selected(location, '/call')}>
        <OperIcon />
        {trans.operator}
      </Item>

      <Item to="calls" className={selected(location, '/calls')}>
        <DataIcon />
        {trans.entries}
      </Item>

      <Item to="clients" className={selected(location, '/clients')}>
        <ClientsIcon />
        {trans.clients}
      </Item>
      
      <Item to="projects" className={selected(location, '/projects')}>
        <ProjectIcon />
        {trans.projects}
      </Item>

      <Item to="tokens" className={selected(location, '/tokens')}>
        <PhonebookIcon />
        {trans.tokens}
      </Item>

      <Item>
        <StatusIcon />
        {trans.state}
      </Item>

      <Item>
        <ReportIcon />
        {trans.reports}
      </Item>

      <Item>
        <UsersIcon />
        {trans.users}
      </Item>
    </Content>

    <Footer>
      <img src="/logo.svg" width="70%" alt="" />
    </Footer>
  </Container>
)

export default withRouter(Sidebar)