import React from 'react'
import styled from 'styled-components/macro'

const Button = styled.button`
  white-space: nowrap;
  height: 38px;
  background-color: ${(props) =>
    props.danger ? '#dc3545' : props.light ? '#ddddea' : '#17a2b8'};
  border: 1px solid
    ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#cfcfe1' : '#17a2b8'};
  border-radius: 4px;
  color: ${(props) => (props.light ? '#000' : '#fff')};
  padding: 5px 10px;

  &:focus {
    outline: 0;
    background-color: ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#ddddea' : '#17a2b8'};
    border-color: ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#cfcfe1' : '#17a2b8'};
    box-shadow: 0 0 0 2px
      ${(props) =>
        props.danger
          ? '#f0aab1'
          : props.light
          ? 'rgb(216,216,231,.5)'
          : 'rgba(58,176,195,.5)'};
  }

  &:hover {
    background-color: ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#cfcfe1' : '#138496'};
    border-color: ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#babaca' : '#117a8b'};
  }

  &:active {
    background-color: ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#babaca' : '#117a8b'};
    border-color: ${(props) =>
      props.danger ? '#dc3545' : props.light ? '#a5a5b4' : '#10707f'};
    box-shadow: 0 0 0 2px
        ${(props) =>
          props.danger
            ? '#f0aab1'
            : props.light
            ? 'rgb(198,198,211,.5)'
            : 'rgba(58,176,195,.5)'},
      inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &:disabled {
    cursor: default;
    background-color: #a0a6ab;
    border-color: #899097;
    opacity: 0.65;
  }
`

export default Button
