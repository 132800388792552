import React, { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { Select, Input, DateInput, Button, Checkbox } from './controls'
import Modal, { withModalManager } from './Modal'

const ClipboardTextInput = styled(Input)`
  height: 358px;
  margin: 12px;
`

const ClipboardImport = ({ closeModal, onSuccess }) => {
  const [text, setText] = useState('')

  const onTextChange = useCallback(
    event => {
      setText(event.target.value)
    },
    [setText]
  )

  const onSubmit = useCallback(() => {
    closeModal()
    if (onSuccess) onSuccess(text)
  }, [text, onSuccess, closeModal])

  const renderActions = useCallback(
    () => (
      <div className="text-right">
        <Button onClick={onSubmit}>იმპორტი</Button>
      </div>
    ),
    [onSubmit]
  )

  return (
    <Modal
      header="ბუფერიდან იმპორტი"
      footer={renderActions}
      css="width: 800px"
      closeModal={closeModal}
    >
      <ClipboardTextInput textArea value={text} onChange={onTextChange} />
    </Modal>
  )
}

export default withModalManager(ClipboardImport)
