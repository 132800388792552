import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { Select, Input, DateInput, Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import withStateLifter from '../StateLifter'
import { Client } from '../../api'
import { withTranslations } from '../Translation'

const Form = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-gap: 12px;

  > label {
    display: flex;
    align-items: center;
  }
`

class ClientForm extends React.PureComponent {
  state = { submitting: false }

  async componentDidMount() {
    const { clientId } = this.props

    if (clientId) {
      const client = await Client.getOne(clientId)
      this.setState({ client })
    }
  }

  submit = async () => {
    const touched = { touched: true }

    const { lifter, clientId, onSuccess, closeModal } = this.props

    lifter.setStates({
      clientName: touched,
      contactPerson: touched,
      phoneNumber: touched,
      email: touched,
    })

    const states = lifter.getStates()

    if (
      states.clientName.invalid ||
      states.contactPerson.invalid ||
      states.phoneNumber.invalid ||
      states.email.invalid
    ) {
      return
    }

    this.setState({ submitting: true })

    const client = {
      clientName: states.clientName.value,
      contactPerson: states.contactPerson.value,
      phoneNumber: states.phoneNumber.value,
      email: states.email.value,
    }

    let promise
    if (!clientId) {
      promise = Client.create(client)
    } else {
      promise = Client.update(clientId, client)
    }

    promise
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  renderActions = () => {
    const { client } = this.state
    const { trans } = this.props

    return (
      <div className="flex-row-center-space">
        <span>{client && `${client.createdBy}, ${client.createdAt}`}</span>
        <Button onClick={this.submit} disabled={this.state.submitting}>
          {this.props.clientId ? trans.save : trans.add}
        </Button>
      </div>
    )
  }

  render() {
    const { client } = this.state
    const { clientId, trans } = this.props

    if (clientId && !client) return null

    return (
      <Modal
        header={clientId ? trans.editClient : trans.addClient}
        footer={this.renderActions}
        css="width: 700px; padding: 12px; background-color: whitesmoke"
        closeModal={this.props.closeModal}
      >
        <Form>
          <label>{trans.clientName}</label>
          <Input
            name="clientName"
            required
            initialValue={client && client.clientName}
          />

          <label>{trans.contactPersonName}</label>
          <Input
            name="contactPerson"
            css="width: 50%"
            initialValue={client && client.contactPerson}
          />

          <label>{trans.contactPhoneNumber}</label>
          <Input
            name="phoneNumber"
            css="width: 50%"
            initialValue={client && client.phoneNumber}
          />

          <label>{trans.clientEmail}</label>
          <Input name="email" email initialValue={client && client.email} />
        </Form>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('ClientForm')(withStateLifter(ClientForm))
)
