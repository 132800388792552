import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { Select, Input, DateInput } from '../controls'
import DataTable from '../DataTable/DataTable'
import { Project, Call, User, Config } from '../../api'
import { results, statuses } from './constants'
import { withTranslations } from '../Translation'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import { parseDate, formatDate } from '../helpers/date'
import CallDelete from './CallDelete'
import Recording from './Recording'
import CallEdit from './CallEdit'
import Permissions, { hasPermission } from '../../permissions'

class CallList extends React.PureComponent {
  get lifter() {
    return this.props.lifter
  }

  get projectId() {
    return FormHelper.getValue(this.lifter, 'projectId')
  }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.identifier,
        name: 'identifier',
      },
      {
        title: trans.phoneNumber,
        name: 'phoneNumber',
      },
      {
        title: trans.operator,
        name: 'assignedTo',
      },
      {
        title: trans.dateTime,
        name: 'generatedAt',
        sortColumn: 'generatedAtTs',
      },
      {
        title: trans.result,
        name: 'result',
        formatter: (value, { reviewStatus }) => {
          return this.props.trans[
            `result.${value}${
              value === 200 && reviewStatus === 100 ? '.100' : ''
            }`
          ]
        },
      },
      {
        name: 'callLaterAt',
        title: trans.callLaterTime,
        sortColumn: 'callLaterAtTs',
      },
      {
        title: trans.status,
        name: 'status',
        formatter: (value) => {
          return this.props.trans['status.' + value]
        },
      },
      {
        title: trans.talkDuration,
        name: 'talkTime',
        exportUnformatted: true,
        formatter: (_, { talkMinutes, talkSeconds }) => {
          let time = ''

          if (talkMinutes) {
            time =
              talkMinutes.toString() +
              ' ' +
              (talkMinutes === 1
                ? this.props.trans.minute
                : this.props.trans.minutes)
          }

          if (talkSeconds) {
            time +=
              (talkMinutes ? ` ${this.props.trans.and} ` : '') +
              talkSeconds +
              ' ' +
              (talkSeconds === 1
                ? this.props.trans.second
                : this.props.trans.seconds)
          }

          return time
        },
      },
      {
        title: trans.waitTime,
        name: 'waitTime',
        exportUnformatted: true,
        formatter: (waitTime) => {
          let time = ''

          const waitMinutes = Math.trunc(waitTime / 60)
          const waitSeconds = waitTime % 60

          if (waitMinutes) {
            time =
              waitMinutes.toString() +
              ' ' +
              (waitMinutes === 1
                ? this.props.trans.minute
                : this.props.trans.minutes)
          }

          if (waitSeconds) {
            time +=
              (waitMinutes ? ` ${this.props.trans.and} ` : '') +
              waitSeconds +
              ' ' +
              (waitSeconds === 1
                ? this.props.trans.second
                : this.props.trans.seconds)
          }

          return time
        },
      },
      {
        title: trans.reason,
        name: 'reason',
      },
      {
        title: trans.reusedTimes,
        name: 'reusedTimes',
      },
      {
        title: trans.direction,
        name: 'callDirection',
        formatter: (value) => {
          return this.props.trans['direction.' + value]
        },
      },
      {
        title: trans.comment,
        name: 'comment',
      },
      {
        title: trans.remarks,
        name: 'remarks',
      },
      {
        title: trans.description,
        name: 'description',
      },
    ]

    return this.memoizedColumns
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.setState({ columns: this.columns })
    }
  }

  state = {
    columns: this.columns,
    calls: [],
    projects: [],
    users: [],
    loading: true,
    fetching: false,
    results: Object.keys(results).map((key) => {
      const self = this
      return {
        value: results[key],
        get label() {
          return self.props.trans['result.' + results[key]]
        },
      }
    }),
    statuses: Object.keys(statuses).map((key) => {
      const self = this
      return {
        value: statuses[key],
        get label() {
          return self.props.trans['status.' + statuses[key]]
        },
      }
    }),
  }

  calls = []

  updateColumns = (config) => {
    const columns = [...this.columns]

    if (config.hasField1) {
      columns.push({
        title: config.fieldName1,
        name: 'fieldValue1',
      })
    }

    if (config.hasField2) {
      columns.push({
        title: config.fieldName2,
        name: 'fieldValue2',
      })
    }

    if (config.hasField3) {
      columns.push({
        title: config.fieldName3,
        name: 'fieldValue3',
      })
    }

    if (config.hasField4) {
      columns.push({
        title: config.fieldName4,
        name: 'fieldValue4',
      })
    }

    if (config.hasField5) {
      columns.push({
        title: config.fieldName5,
        name: 'fieldValue5',
      })
    }

    this.setState({ columns })
  }

  syncTime = async (projectId, callId) => {
    this.setState({ fetching: true })
    try {
      await Call.syncTime(projectId, callId)
      this.fetchCalls()
    } finally {
      this.setState({ fetching: false })
    }
  }

  getContextMenuItems = ({ projectId, callId, identifier, result }) => {
    const { trans } = this.props

    var items = []
    if (hasPermission(Permissions.CallEdit)) {
      items.push({
        title: trans.edit,
        action: () => {
          this.callEdit.openModal({
            projectId,
            callId,
            title: `${
              FormHelper.getOption(this.lifter, 'projectId').label
            } - ${identifier}`,
          })
        },
      })
    }

    items.push(
      {
        title: trans.listen,
        action: () => {
          this.recordingModal.openModal({ projectId, callId })
        },
      },
      {
        title: trans.refresh,
        action: () => {
          this.fetchCalls()
        },
        tableContextMenu: true,
      },
      {
        title: trans.updateTime,
        action: () => {
          this.syncTime(projectId, callId)
        },
        tableContextMenu: true,
      }
    )

    if (hasPermission(Permissions.CallDelete)) {
      items.push({
        title: trans.delete,
        action: () => {
          this.deleteModal.openModal({ projectId, callId })
        },
      })
    }

    return items
  }

  componentDidMount() {
    Promise.all([Call.getReasons(), Project.getAll(), User.getAll()])
      .then(([reasons, projects, users]) => {
        this.setState({
          reasons: reasons.map((r) => ({
            value: r.reasonId,
            label: r.reasonName,
          })),
          projects: projects.map((p) => ({
            value: p.projectId,
            label: p.projectName,
          })),
          users: users.map((u) => ({
            value: u.userId,
            label: u.fullName,
          })),
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  getCellClassName = (column, row) => {
    return row.result === results.callLater ? 'call-later' : ''
  }

  fetchCalls = () => {
    this.setState({ fetching: true })

    return Promise.all([
      Config.get(this.projectId),
      Call.getAll(this.projectId),
    ])
      .then(([config, calls]) => {
        this.config = config
        this.calls = calls
        this.updateColumns(config)
        this.filter()
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  filter = () => {
    const { identifier, userId, startDate, endDate, result, status } =
      FormHelper.getValues(this.lifter, [
        'identifier',
        'userId',
        'startDate',
        'endDate',
        'result',
        'status',
      ])

    const sdate = parseDate(startDate)
    const edate = parseDate(endDate)

    const idents =
      identifier &&
      identifier
        .split('|')
        .map((ident) => ident.trim())
        .filter((ident) => ident)

    const calls = this.calls.filter(
      (c) =>
        (!identifier || idents.some((ident) => c.identifier.includes(ident))) &&
        (!userId || c.assignedToId === userId) &&
        (!sdate.isValid() ||
          sdate.isSameOrBefore(parseDate(c.generatedAt, false))) &&
        (!edate.isValid() ||
          edate.isSameOrAfter(parseDate(c.generatedAt, false))) &&
        (!result ||
          (result === '200.100' &&
            c.result === 200 &&
            c.reviewStatus === 100) ||
          c.result === result) &&
        (!status || c.status === status)
    )

    this.setState({ calls })
  }

  handleProjectChanged = () => {
    this.fetchCalls()
  }

  handleChanged = () => {
    this.filter()
  }

  handleSuccess = () => {
    this.fetchCalls()
  }

  handleListen = () => {}

  render() {
    const { loading, fetching } = this.state
    const { projectName, trans } = this.props

    return (
      <div css="flex: 1; display: flex; flex-direction: column">
        <CallDelete
          ref={(ref) => (this.deleteModal = ref)}
          onSuccess={this.handleSuccess}
        />

        <CallEdit
          ref={(ref) => (this.callEdit = ref)}
          onSuccess={this.handleSuccess}
        />

        <Recording ref={(ref) => (this.recordingModal = ref)} />

        <div className="gap-bottom flex-row-center">
          <Select
            name="projectId"
            className="flex-fill"
            options={this.state.projects}
            placeholder={trans.chooseProject}
            onChanged={this.handleProjectChanged}
            isDisabled={fetching || loading}
          />

          <Input
            placeholder={trans.identifier}
            className="gap-left"
            css="width: 150px"
            name="identifier"
            onChanged={this.handleChanged}
            disabled={fetching || loading || !this.projectId}
          />

          <Select
            className="gap-left"
            css="width: 250px"
            options={this.state.users}
            name="userId"
            placeholder={trans.operator}
            onChanged={this.handleChanged}
            isDisabled={fetching || loading || !this.projectId}
            isClearable
          />

          <DateInput
            placeholder={trans.date + ' ≥'}
            className="gap-left"
            css="width: 100px"
            name="startDate"
            onChanged={this.handleChanged}
            disabled={fetching || loading || !this.projectId}
          />

          <DateInput
            placeholder={trans.date + ' ≤'}
            className="gap-left"
            css="width: 100px"
            name="endDate"
            onChanged={this.handleChanged}
            disabled={fetching || loading || !this.projectId}
          />

          <Select
            className="gap-left"
            css="width: 203px"
            options={this.state.results}
            placeholder={trans.result}
            name="result"
            onChanged={this.handleChanged}
            isDisabled={fetching || loading || !this.projectId}
            isClearable
          />

          <Select
            className="gap-left"
            css="width: 255px"
            options={this.state.statuses}
            placeholder={trans.status}
            name="status"
            onChanged={this.handleChanged}
            isDisabled={fetching || loading || !this.projectId}
            isClearable
          />

          <div className="gap-left">{this.state.calls.length}</div>
        </div>

        <div css="display: flex; align-items: stretch; flex: 1">
          <DataTable
            rows={this.state.calls}
            columns={this.state.columns}
            getContextMenuItems={this.getContextMenuItems}
            cellClassName={this.getCellClassName}
            loading={loading || fetching}
            excelExportName={projectName}
          />
        </div>
      </div>
    )
  }
}

export default withTranslations('CallList')(
  withStateLifter((props) => {
    const projectOption = FormHelper.getOption(props.lifter, 'projectId')
    return (
      <CallList {...props} projectName={projectOption && projectOption.label} />
    )
  })
)
