import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import { Project } from '../../api'

const ProjectDelete = withModalManager(
  ({ projectId, onSuccess, closeModal }) => {
    const [deleting, setDeleting] = useState(false)

    const handleDelete = useCallback(() => {
      setDeleting(true)

      Project.remove(projectId)
        .then(() => {
          closeModal()
          if (onSuccess) onSuccess()
        })
        .finally(() => setDeleting(false))
    }, [projectId, onSuccess, closeModal])

    return (
      <Modal
        header="პროექტის წაშლა"
        className="pad bgcolor"
        closeModal={closeModal}
        footer={
          <div css="text-align: right">
            <Button
              disabled={deleting}
              className="gap-right"
              onClick={handleDelete}
            >
              დიახ
            </Button>
            <Button disabled={deleting} onClick={closeModal}>
              გაუქმება
            </Button>
          </div>
        }
      >
        ნამდვილად გსურთ პროექტის წაშლა?
      </Modal>
    )
  }
)

export default ProjectDelete
