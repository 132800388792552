import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components/macro'
import { useDesktopQuery, minWidth, maxWidth } from './MediaQuery'
import userManager from '../userManager'
import { ReactComponent as MenuIcon } from '../icons/menu.svg'
import { ReactComponent as LogoutIcon } from '../icons/logout.svg'
import { ReactComponent as UserIcon } from '../icons/user.svg'
import LocaleContext from './Translation/LocaleContext'
import { ReactComponent as OperIcon } from '../icons/oper.svg'
import { ReactComponent as ProjectIcon } from '../icons/project.svg'
import { ReactComponent as StatusIcon } from '../icons/status.svg'
import { ReactComponent as ReportIcon } from '../icons/report.svg'
import { ReactComponent as UsersIcon } from '../icons/users.svg'
import { ReactComponent as DataIcon } from '../icons/data.svg'
import { ReactComponent as PhonebookIcon } from '../icons/phonebook.svg'
import { ReactComponent as ClientsIcon } from '../icons/clients.svg'
import Permissions, { hasPermission } from '../permissions'

export const NavbarContext = React.createContext({
  navbarVisible: false,
  setNavbarVisible: () => null,
})

const Container = styled.nav`
  display: flex;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  font-size: 14px;
  color: #6c757d;

  @media (min-width: ${minWidth}) {
    height: 40px;
    padding: 0px 12px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    justify-content: space-between;
  }

  @media (max-width: ${maxWidth}) {
    flex-direction: column;
    min-width: 260px;
  }

  select {
    padding-left: 4px;
    padding-right: 6px;
    appearance: none;
    border: 0px none;
    margin-left: 5px;
    outline: none;
  }

  a {
    color: #6c757d;

    span {
      vertical-align: middle;
    }

    svg {
      vertical-align: middle;
    }
  }

  a:hover {
    text-decoration: none;
    color: #343a40;
  }
`

const AppMenu = styled.div`
  display: flex;

  @media (min-width: ${minWidth}) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: ${maxWidth}) {
    flex-direction: column;
    align-items: stretch;
  }
`

const AuthMenu = styled.div`
  display: flex;
  align-items: stretch;

  @media (max-width: ${maxWidth}) {
    flex-direction: column;

    .auth-item {
      padding: 12px;
      margin: 3px 0px;
    }
  }
`

const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  color: #6c757d;
  position: relative;

  &.logo {
    height: 40px;
    @media (min-width: ${minWidth}) {
      padding: 0px;
    }
  }

  @media (max-width: ${maxWidth}) {
    margin: 3px 0px;
    padding: 12px;
  }

  :hover {
    background-color: #83acd6 !important;
    color: #fff !important;
    text-decoration: none !important;
  }

  .menu {
    display: none;
    background-color: #fff;
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0px 3px 3px 3px;
    overflow: hidden;
  }

  :hover .menu {
    display: block;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  &.selected {
    background-color: #76a3d2;
    color: #fff;
  }
`

const Signout = styled.span`
  margin-left: 6px;

  @media (max-width: ${maxWidth}) {
    margin-left: 0px !important;
    padding-left: 8px !important;
  }
`

const Separator = () => {
  const isDesktop = useDesktopQuery({ minWidth: 801 })
  if (isDesktop) {
    return (
      <div css="margin: 0px 3px; height: 24px; border-right: 1px solid #ced4da;"></div>
    )
  } else {
    return null
  }
}

function selected(location, path) {
  return location.pathname === path ||
    (path.startsWith('/reports') && location.pathname.startsWith(path))
    ? 'selected'
    : undefined
}

const Navbar = ({ user, onSigningOut, trans, location }) => {
  const handleLogout = useCallback(
    (event) => {
      event.preventDefault()
      if (onSigningOut) onSigningOut()
      userManager.removeUser()
      userManager.signoutRedirect()
    },
    [onSigningOut]
  )

  return (
    <Container>
      <AppMenu className="flex-row-center">
        <Item className="logo" to="/">
          <img src="/diflex.png" css="height: 40px; margin-right: 6px" alt="" />
        </Item>

        {/* <Separator /> */}

        {hasPermission(Permissions.CallForm) && (
          <>
            <Item to="/call" className={selected(location, '/call')}>
              <OperIcon />
              {trans.operator}
            </Item>

            <Separator />
          </>
        )}

        {hasPermission(Permissions.CallList) && (
          <>
            <Item to="/calls" className={selected(location, '/calls')}>
              <DataIcon />
              {trans.entries}
            </Item>
            <Separator />
          </>
        )}

        {hasPermission(Permissions.ClientList) && (
          <>
            <Item to="/clients" className={selected(location, '/clients')}>
              <ClientsIcon />
              {trans.clients}
            </Item>

            <Separator />
          </>
        )}

        {hasPermission(Permissions.ProjectList) && (
          <>
            <Item to="/projects" className={selected(location, '/projects')}>
              <ProjectIcon />
              {trans.projects}
            </Item>
            <Separator />
          </>
        )}

        {(hasPermission(Permissions.ReportQuotas) ||
          hasPermission(Permissions.ReportOperator) ||
          hasPermission(Permissions.ReportStatus) ||
          hasPermission(Permissions.ReportTokens) ||
          hasPermission(Permissions.ReportRealtime)) && (
          <>
            <Item className={selected(location, '/reports')}>
              <ReportIcon />
              {trans.reports}
              <div className="menu">
                {hasPermission(Permissions.ReportQuotas) && (
                  <Item
                    to="/reports/quotas"
                    className={selected(location, '/reports/quotas')}
                  >
                    {trans.quotaStats}
                  </Item>
                )}

                {hasPermission(Permissions.ReportOperator) && (
                  <Item
                    to="/reports/operator"
                    className={selected(location, '/reports/operator')}
                  >
                    {trans.operatorStats}
                  </Item>
                )}

                {hasPermission(Permissions.ReportStatus) && (
                  <Item
                    to="/reports/status"
                    className={selected(location, '/reports/status')}
                  >
                    {trans.statusStats}
                  </Item>
                )}

                {hasPermission(Permissions.ReportTokens) && (
                  <Item
                    to="/reports/token"
                    className={selected(location, '/reports/token')}
                  >
                    {trans.numberStats}
                  </Item>
                )}

                {hasPermission(Permissions.ReportRealtime) && (
                  <Item
                    to="/reports/realtime"
                    className={selected(location, '/reports/realtime')}
                  >
                    {trans.realtimeStats}
                  </Item>
                )}
              </div>
            </Item>
            <Separator />
          </>
        )}

        {(hasPermission(Permissions.UserList) ||
          hasPermission(Permissions.RoleList)) && (
          <Item to="/users" className={selected(location, '/users')}>
            <UsersIcon />
            {trans.security}
            <div className="menu">
              {hasPermission(Permissions.UserList) && (
                <Item to="/users" className={selected(location, '/users')}>
                  {trans.users}
                </Item>
              )}

              {hasPermission(Permissions.RoleList) && (
                <Item to="/roles" className={selected(location, '/roles')}>
                  {trans.roles}
                </Item>
              )}

              {hasPermission(Permissions.RoleList) && (
                <Item to="/train" className={selected(location, '/train')}>
                  ამოცნობა
                </Item>
              )}
            </div>
          </Item>
        )}
      </AppMenu>

      {user && (
        <AuthMenu>
          <div className="pointer auth-item">
            <UserIcon />
            <span css="margin-left: 5px">{user && user.profile.name}</span>
          </div>

          <div css="border-right: 1px solid #ced4da; margin-left: 12px"></div>

          <LocaleContext.Consumer>
            {({ locale, setLocale }) => (
              <select
                className="auth-item"
                onChange={(event) => setLocale(event.target.value)}
              >
                <option selected={locale === 'ka'} value="ka">
                  ქართული
                </option>
                <option selected={locale === 'en'} value="en">
                  English
                </option>
                <option selected={locale === 'ru'} value="ru">
                  Русский
                </option>
              </select>
            )}
          </LocaleContext.Consumer>

          <div css="border-right: 1px solid #ced4da; margin-left: 5px"></div>

          <Signout className="pointer auth-item" href="" onClick={handleLogout}>
            <LogoutIcon />
            <span>{trans.signout}</span>
          </Signout>
        </AuthMenu>
      )}
    </Container>
  )
}

export default withRouter(
  connect((state) => ({ user: state.oidc.user }))(Navbar)
)
