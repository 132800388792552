import React from 'react'
import styled from 'styled-components/macro'
import { Button, Checkbox } from '../controls'
import Modal, { withModalManager } from '../Modal'
import { User, Role } from '../../api'
import withStateLifter from '../StateLifter'
import { FormHelper, StringHelper } from '../helpers'
import { withTranslations } from '../Translation'

class UserRoles extends React.Component {
  state = { fetching: true, roles: [] }

  users = []
  members = []

  async componentDidMount() {
    const { userId } = this.props.params

    Promise.all([Role.getAll(), User.getOne(userId)])
      .then(([roles, user]) => {
        this.setState({ roles, user })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  renderActions = () => (
    <div className="flex-row-center-space">
      <Button disabled={this.state.submitting} onClick={this.submit}>
        {this.props.trans.save}
      </Button>
    </div>
  )

  submit = () => {
    const {
      onSuccess,
      closeModal,
      params: { userId },
    } = this.props

    const roles = FormHelper.getValues(
      this.props.lifter,
      this.state.roles.map((r) => r.roleId)
    )

    User.updateRoles(
      userId,
      Object.keys(roles).filter((key) => roles[key])
    )
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { fetching, roles, user } = this.state
    const { isDefaultLocale, locale } = this.props

    if (fetching) return null

    return (
      <Modal
        header={this.state.user.fullName}
        footer={this.renderActions}
        className="pad"
        css="width: 350px"
        closeModal={this.props.closeModal}
      >
        {roles.map((role, index) => (
          <Checkbox
            name={role.roleId}
            label={
              isDefaultLocale
                ? role.roleName
                : role['roleName' + StringHelper.capitalize(locale)]
            }
            initialValue={user.roles.includes(role.roleId)}
            className={index < roles.length - 1 ? 'gap-bottom' : ''}
          />
        ))}
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('UserRoles')(withStateLifter(UserRoles))
)
