import React from 'react'
import styled from 'styled-components/macro'
import { LifterContext } from '../StateLifter'
import { FormHelper } from '../helpers'

const CheckSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="icon"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
)

const CheckboxContainer = styled.label`
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    opacity: 0;
    height: 30px;
    width: 30px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 2px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .overlay-disabled {
    background-color: whitesmoke;
  }

  input:focus ~ .overlay {
    border-color: #81b2e7;
    box-shadow: 0 0 0 2px rgba(129, 178, 231, 0.3);
  }

  input:checked ~ .overlay > svg {
    display: inline;
  }

  input:not(:checked) ~ .overlay > svg {
    display: none;
  }

  .label-text {
    padding-left: 42px;
    white-space: nowrap;
  }
`

class Checkbox extends React.Component {
  constructor(props, context) {
    super(props, context)

    const { name } = props

    if (context && context.getState && name) {
      const state = context.getState(name)

      if (!state) {
        const value = props.initialValue || ''

        context.setState(name, {
          value,
          touched: false,
          invalid: this.invalidate(value),
        })
      }

      context.setNotifier(name, () => {
        this.forceUpdate()
      })
    }
  }

  static contextType = LifterContext

  componentWillUnmount() {
    const { name } = this.props
    if (this.hasLifter && name) {
      this.context.removeNotifier(name)
    }
  }

  get hasLifter() {
    return this.context && !!this.context.getState
  }

  invalidate = (value) => {
    return false
  }

  handleChange = (event) => {
    const { checked: value } = event.target
    const { name, onChange, onChanged } = this.props

    if (this.hasLifter) {
      const state = {
        value,
        touched: true,
        invalid: this.invalidate(value),
      }

      this.context.setState(name, state, () => {
        if (onChanged) onChanged(name, state)
      })
    }

    if (onChange) onChange(event)
  }

  render() {
    const checked = FormHelper.getValue(this.context, this.props.name) || false

    const { disabled } = this.props

    return (
      <CheckboxContainer className={this.props.className}>
        <input
          type="checkbox"
          checked={checked}
          onChange={this.handleChange}
          css="user-select: none"
          disabled={disabled}
        />

        <span className={`overlay${disabled ? ' overlay-disabled' : ''}`}>
          <CheckSvg />
        </span>

        {this.props.label && (
          <span className="label-text">{this.props.label}</span>
        )}
      </CheckboxContainer>
    )
  }
}

export default Checkbox
