import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input, DateInput, Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import DataTable from '../DataTable/DataTable'
import { format as formatDate } from 'date-fns'
import { Client, User, Role, Project } from '../../api'
import ClientForm from '../Client/ClientForm'
import withStateLifter from '../StateLifter'
import { withTranslations } from '../Translation'
import { StringHelper } from '../helpers'

const TabsBody = styled.div`
  flex: 1;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
`

const Form = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-gap: 12px;

  > label {
    display: flex;
    align-items: center;
  }
`

const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  user-select: none;
  padding-left: 12px;
  margin-top: 12px;

  .tab {
    position: relative;
    padding: 8px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .tab-selected {
    border: 1px solid #ced4da;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: -1px;
    background-color: whitesmoke;
    cursor: auto;
    padding-top: 7px !important;
  }
`

const fields = [
  'clientId',
  'projectCode',
  'projectName',
  'startDate',
  'endDate',
  'status',
  'surveyMethod',
  'surveyType',
  'description',
  'plannedInterviews',
]

class ProjectForm extends React.Component {
  state = { fetching: true, activeTab: 0, clients: [], users: [], members: [] }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        name: 'fullName',
        title: trans.fullName,
      },
    ]

    return this.memoizedColumns
  }

  users = []
  members = []

  async componentDidMount() {
    const { projectId, setLoading } = this.props

    setLoading(true)

    Promise.all([
      Role.getAll(),
      User.getAll(),
      projectId && Project.getOne(projectId),
      this.fetchClients(),
    ])
      .then(([roles, users, project]) => {
        this.users = project
          ? users.filter((u) => !project.members.includes(u.userId))
          : users
        this.members = project
          ? users.filter((u) => project.members.includes(u.userId))
          : []

        this.roles = roles

        const self = this
        this.setState({
          roles: roles.map((role) => ({
            value: role.roleId,
            role,
            get label() {
              return self.props.isDefaultLocale
                ? this.role.roleName
                : this.role[
                    'roleName' + StringHelper.capitalize(self.props.locale)
                  ]
            },
          })),
          project,
          users: this.users,
          members: this.members,
        })

        this.filterMembers()
        this.filterUsers()
      })
      .finally(() => {
        this.setState({ fetching: false })
        setLoading(false)
      })
  }

  statuses = [
    { value: 1, label: this.props.trans.statusNew },
    { value: 2, label: this.props.trans.statusActive },
    { value: 3, label: this.props.trans.statusPaused },
    { value: 4, label: this.props.trans.statusCompleted },
  ]

  surveyTypes = [
    { value: 1, label: this.props.trans.individual },
    { value: 2, label: this.props.trans.organizational },
  ]

  surveyMethods = [
    { value: 1, label: this.props.trans.noMethod },
    { value: 2, label: 'QuestionPro' },
    { value: 3, label: 'SurveyToGo' },
  ]

  fetchClients = async () => {
    const clients = await Client.getAll()

    return new Promise((resolve) => {
      this.setState(
        {
          clients: clients.map((c) => ({
            value: c.clientId,
            label: c.clientName,
          })),
        },
        () => resolve(this.state.clients)
      )
    })
  }

  renderActions = () => {
    const { project } = this.state
    const { trans } = this.props

    return (
      <div className="flex-row-center-space">
        <span>{project && `${project.createdBy}, ${project.createdAt}`}</span>
        <Button disabled={this.state.submitting} onClick={this.submit}>
          {project ? trans.save : trans.add}
        </Button>
      </div>
    )
  }

  submit = () => {
    const touched = { touched: true }

    const { lifter, projectId, onSuccess, closeModal } = this.props

    lifter.setStates(
      Object.assign(...fields.map((name) => ({ [name]: touched })))
    )

    const states = lifter.getStates()

    if (fields.map((name) => states[name].invalid).some((inv) => inv)) {
      return
    }

    this.setState({ submitting: true })

    const project = Object.assign(
      ...fields.map((name) => ({ [name]: states[name].value }))
    )
    project.members = this.members.map((m) => m.userId)

    let promise
    if (!projectId) {
      promise = Project.create(project)
    } else {
      promise = Project.update(projectId, project)
    }

    promise
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  handleClientCreate = async ({ clientId }) => {
    const clients = await this.fetchClients()
    const client = clients.find((c) => c.value === clientId)
    this.props.lifter.setState('clientId', { option: client, value: clientId })
  }

  addMember = (user) => {
    if (user || this.state.selectedUser) {
      const selected = user || this.state.selectedUser

      this.users = this.users.filter((u) => u !== selected)
      this.members = [...this.members, selected]

      this.filterMembers()
      this.filterUsers()
    }
  }

  removeMember = (member) => {
    if (member || this.state.selectedMember) {
      const selected = member || this.state.selectedMember

      this.users = [...this.users, selected]
      this.members = this.members.filter((m) => m !== selected)

      this.filterMembers()
      this.filterUsers()
    }
  }

  handleUserRowSelected = (row) => {
    this.setState({ selectedUser: row })
  }

  handleMemberRowSelected = (row) => {
    this.setState({ selectedMember: row })
  }

  getUsersContextMenuItems = (row) => [
    {
      title: this.props.addMember,
      action: () => {
        this.addMember(row)
      },
    },
  ]

  getMembersContextMenuItems = (row) => [
    {
      title: this.props.removeMember,
      action: () => {
        this.removeMember(row)
      },
    },
  ]

  filterUsers = () => {
    this.setState((state) => ({
      users:
        !state.usersQuery && !state.role
          ? this.users
          : this.users.filter(
              (u) =>
                (!state.usersQuery || u.fullName.includes(state.usersQuery)) &&
                (!state.role || u.roles.includes(state.role.value))
            ),
    }))
  }

  filterMembers = () => {
    this.setState((state) => ({
      members:
        !state.membersQuery && !state.role
          ? this.members
          : this.members.filter(
              (u) =>
                (!state.membersQuery ||
                  u.fullName.includes(state.membersQuery)) &&
                (!state.role || u.roles.includes(state.role.value))
            ),
    }))
  }

  handleUsersQueryChange = (event) => {
    this.setState({ usersQuery: event.target.value }, this.filterUsers)
  }

  handleMembersQueryChange = (event) => {
    this.setState({ membersQuery: event.target.value }, this.filterMembers)
  }

  handleRoleChange = (role) => {
    this.setState({ role: role }, () => {
      this.filterUsers()
      this.filterMembers()
    })
  }

  render() {
    const { clients, fetching, users, members, roles, project } = this.state

    const { trans } = this.props

    if (fetching) return null

    return (
      <Modal
        header={
          project
            ? `${trans.editProject} - ${project.projectId}`
            : trans.addProject
        }
        footer={this.renderActions}
        css="width: 960px; min-height: 500px"
        closeModal={this.props.closeModal}
      >
        <TabsContainer>
          <div
            onClick={this.handleTabClick(0)}
            className={`tab${this.tabSelected(0)}`}
          >
            {trans.general}
          </div>
          <div
            onClick={this.handleTabClick(1)}
            className={`tab${this.tabSelected(1)}`}
          >
            {trans.participants}
          </div>
        </TabsContainer>

        <TabsBody>
          {this.state.activeTab === 0 && (
            <Form>
              <label>{trans.client}</label>
              <div className="flex-row-center">
                <Select
                  required
                  name="clientId"
                  initialValue={project && project.clientId}
                  options={clients}
                  placeholder={trans.chooseClient}
                  css="flex: 1; margin-right: 12px;"
                />

                <ClientForm
                  trigger={(props) => (
                    <Button
                      {...props}
                      css="width: 38px; font-size: 20px !important"
                    >
                      +
                    </Button>
                  )}
                  onSuccess={this.handleClientCreate}
                />
              </div>

              <label>{trans.projectCode}</label>
              <Input
                required
                initialValue={project && project.projectCode}
                name="projectCode"
                css="width: 220px"
              />

              <label>{trans.projectName}</label>
              <Input
                initialValue={project && project.projectName}
                textArea
                required
                name="projectName"
              />

              <label>{trans.startDate}</label>
              <div className="flex-row-center-space">
                <DateInput
                  name="startDate"
                  required
                  initialValue={
                    project
                      ? project.startDate
                      : formatDate(new Date(), 'DD.MM.YYYY')
                  }
                  css="width: 220px"
                />

                <div className="flex-row-center">
                  <label css="margin-right: 12px">{trans.endDate}</label>
                  <DateInput
                    required
                    initialValue={project && project.endDate}
                    name="endDate"
                    css="width: 220px"
                  />
                </div>
              </div>

              <label>{trans.projectStatus}</label>
              <div className="flex-row-center-space">
                <Select
                  required
                  initialValue={project && project.status}
                  name="status"
                  options={this.statuses}
                  css="width: 220px"
                />

                <div className="flex-row-center">
                  <label css="margin-right: 12px">{trans.surveyType}</label>
                  <Select
                    required
                    initialValue={project && project.surveyType}
                    name="surveyType"
                    css="width: 220px"
                    options={this.surveyTypes}
                  />
                </div>
              </div>

              <label>{trans.plannedInterviews}</label>
              <div className="flex-row-center-space">
                <Input
                  required
                  number
                  integer
                  unsigned
                  initialValue={project && project.plannedInterviews}
                  name="plannedInterviews"
                  css="width: 220px"
                />

                <div className="flex-row-center">
                  <label css="margin-right: 12px">{trans.surveyMethod}</label>
                  <Select
                    required
                    initialValue={project && project.surveyMethod}
                    name="surveyMethod"
                    options={this.surveyMethods}
                    css="width: 220px"
                  />
                </div>
              </div>

              <label>{trans.description}</label>
              <Input
                textArea
                initialValue={project && project.description}
                name="description"
                css="height: 100px"
              />
            </Form>
          )}

          {this.state.activeTab === 1 && (
            <>
              <div css="margin-bottom: 12px;" className="flex-row-center-space">
                <Input
                  onChange={this.handleUsersQueryChange}
                  value={this.state.usersQuery}
                  className="flex-fill gap-right"
                  placeholder={trans.availableSearch}
                />

                <Select
                  css="width: 300px;"
                  value={this.state.role}
                  onChange={this.handleRoleChange}
                  options={roles}
                  isClearable
                  placeholder={trans.chooseRole}
                />

                <Input
                  onChange={this.handleMembersQueryChange}
                  value={this.state.membersQuery}
                  className="flex-fill gap-left"
                  placeholder={trans.participantSearch}
                />
              </div>

              <div css="flex: 1; display: flex; flex-direction: row; align-items: stretch">
                <DataTable
                  columns={this.columns}
                  rows={users}
                  onRowSelected={this.handleUserRowSelected}
                  onRowDoubleClick={(row) => this.addMember(row)}
                  getContextMenuItems={this.getUsersContextMenuItems}
                />

                <div css="display: flex; flex-direction: column; padding: 12px;">
                  <Button
                    css="margin-bottom: 12px;"
                    onClick={() => this.addMember()}
                  >
                    {trans.addMember} ≫
                  </Button>

                  <Button onClick={() => this.removeMember()}>
                    ≪ {trans.removeMember}
                  </Button>
                </div>

                <DataTable
                  getContextMenuItems={this.getMembersContextMenuItems}
                  columns={this.columns}
                  rows={members}
                  onRowSelected={this.handleMemberRowSelected}
                  onRowDoubleClick={(row) => this.removeMember(row)}
                />
              </div>
            </>
          )}
        </TabsBody>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('ProjectForm')(withStateLifter(ProjectForm))
)
