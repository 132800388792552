const Permissions = {
  CallForm: 10,
  CallList: 11,
  CallEdit: 12,
  CallDelete: 13,
  AlienCalls: 14,

  ClientList: 20,
  ClientCreate: 21,
  ClientEdit: 22,
  ClientDelete: 23,

  ProjectList: 30,
  ProjectCreate: 31,
  ProjectEdit: 32,
  ProjectDelete: 33,
  ProjectTokens: 34,
  ProjectConfig: 35,
  ProjectQuotas: 36,
  CallConfig: 37,

  ReportQuotas: 40,
  ReportOperator: 41,
  ReportStatus: 42,
  ReportTokens: 43,
  ReportRealtime: 44,
  ReportSystem: 45,

  UserList: 50,
  UserRoles: 51,

  RoleList: 60,
  RoleCreate: 61,
  RoleEdit: 62,
  RoleDelete: 63
}

const _permissions = []

export const setPermissions = permissions => {
  _permissions.push(...permissions)
}

export const hasPermission = permission => {
  return _permissions.includes(permission)
}

export default Permissions
