import React from 'react'
import Button from '../controls/Button'

function withModalManager(Component) {
  class ModalManager extends React.Component {
    state = { isOpen: false, isLoading: false }

    openModal = params => {
      this.setState({ isOpen: true, params })
    }

    setLoading = loading => {
      this.setState({ loading })
    }

    closeModal = () => {
      this.setState({ isOpen: false })
    }

    renderTrigger() {
      let { trigger } = this.props

      if (typeof trigger === 'function') {
        const Trigger = trigger
        trigger = <Trigger onClick={this.openModal} disabled={this.state.loading} />
      } else if (trigger && typeof trigger !== 'object') {
        trigger = (
          <Button onClick={this.openModal} disabled={this.state.loading}>
            {trigger}
          </Button>
        )
      }

      return trigger
    }

    render() {
      return (
        <>
          {this.state.isOpen && (
            <Component {...this.props} params={this.state.params} setLoading={this.setLoading} closeModal={this.closeModal} />
          )}
          {this.props.trigger && this.renderTrigger()}
        </>
      )
    }
  }

  return ModalManager
}

export default withModalManager
