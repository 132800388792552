import React from 'react'
import styled from 'styled-components/macro'
import Modal, { withModalManager } from '../Modal'
import { Call } from '../../api'
import Loader from '../Loader'
import { DropdownButton } from '../controls'

class Recording extends React.PureComponent {
  state = { callLog: null }

  componentDidMount() {
    const { projectId, callId } = this.props.params

    Call.getLogs(projectId, callId).then((callLogs) => {
      if (!callLogs.length) {
        this.props.closeModal()
      }

      this.setState({
        callLogs: callLogs.map((log, index) => ({
          ...log,
          title: log.started,
        })),
        callLog: callLogs.length && callLogs[0],
      })
    })
  }

  handleLogSelect = (callLog) => {
    this.setState({ callLog })
  }

  render() {
    const { projectId, callId } = this.props.params
    const { callLog, callLogs } = this.state

    if (!callLog) return null

    return (
      <Modal
        header="აუდიო ჩანაწერის მოსმენა"
        footer={this.renderActions}
        css="width: 600px; height: 70px; padding: 12px; background-color: whitesmoke"
        closeModal={this.props.closeModal}
      >
        <div className="flex-row-center">
          {callLogs && callLogs.length > 1 && (
            <DropdownButton
              className="gap-right"
              items={callLogs}
              onSelect={this.handleLogSelect}
            >
              ზარები ({callLogs.length})
            </DropdownButton>
          )}
          <audio
            className="flex-fill height width-100"
            controls
            autoPlay
            src={`api/calls/${projectId}/${callId}/logs/${this.state.callLog.id}/recording`}
          />
        </div>
      </Modal>
    )
  }
}

export default withModalManager(Recording)
