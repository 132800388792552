import React, { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import Button from './Button'
import ContextMenu from '../ContextMenu'

const DropdownButton = ({
  items,
  children,
  upward,
  onSelect,
  className,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)

  const open = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <div className={`relative ${className}`}>
      <Button className="width-100" {...props} onClick={open}>
        {children}
      </Button>

      {isOpen && (
        <ContextMenu
          onAction={onSelect}
          upward={upward}
          portal={false}
          items={items}
          close={close}
        />
      )}
    </div>
  )
}

export default DropdownButton
