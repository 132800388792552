import React from 'react'
import styled from 'styled-components/macro'
import { Button } from './controls'
import Modal from './Modal'
import { withTranslations } from './Translation'

export const errorModalRef = React.createRef()

export const openError = (message, descripion) => {
  errorModalRef.current.childRef.current.openModal(message, descripion)
}

class ErrorModal extends React.Component {
  state = { open: false, more: false }

  openModal = (message, descripion) => {
    this.setState({ open: true, message, descripion })
  }

  closeModal = () => {
    this.setState({ open: false, more: false })
  }

  showMore = () => {
    this.setState({ more: true })
  }

  render() {
    if (!this.state.open) return null

    const { trans } = this.props

    return (
      <Modal
        type="error"
        header={trans.title}
        footer={this.renderActions}
        css="min-width: 400px; padding: 12px;"
        closeModal={this.closeModal}
      >
        <div className="gap-bottom error">
          {this.state.message}
          {this.state.descripion && (
            <div className="gap-top">
              {!this.state.more && (
                <div className="text-blue pointer" onClick={this.showMore}>
                  {trans.seeMore}
                </div>
              )}
              {this.state.more && (
                <div className="error">{this.state.descripion}</div>
              )}
            </div>
          )}
        </div>
        <div className="text-right gap-top">
          <Button onClick={this.closeModal}>{trans.okay}</Button>
        </div>
      </Modal>
    )
  }
}

export default withTranslations('ErrorModal')(ErrorModal)
