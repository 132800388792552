import React, { useCallback, useState } from 'react'
import { Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import { Client } from '../../api'

const DeleteModal = withModalManager(({ clientId, onSuccess, closeModal }) => {
  const [deleting, setDeleting] = useState(false)

  const handleDelete = useCallback(() => {
    setDeleting(true)

    Client.remove(clientId)
      .then(() => {
        closeModal()
        if (onSuccess) onSuccess()
      })
      .finally(() => setDeleting(false))
  }, [clientId, onSuccess, closeModal])

  return (
    <Modal
      header="კლიენტის წაშლა"
      className="pad bgcolor"
      closeModal={closeModal}
      footer={
        <div css="text-align: right">
          <Button disabled={deleting} className="gap-right" onClick={handleDelete}>
            დიახ
          </Button>
          <Button disabled={deleting} onClick={closeModal}>
            გაუქმება
          </Button>
        </div>
      }
    >
      ნამდვილად გსურთ კლიენტის წაშლა?
    </Modal>
  )
})

export default DeleteModal
