import { get } from './base'

export const operator = projectId => get(`reports/operator/${projectId}`)
export const results = projectId => get(`reports/results/${projectId}`)
export const statuses = projectId => get(`reports/statuses/${projectId}`)
export const reasons = projectId => get(`reports/reasons/${projectId}`)
export const realtime = projectId => get(`reports/realtime/${projectId}`)
export const userResults = projectId => get(`reports/user/results/${projectId}`)
export const userStatuses = projectId =>
  get(`reports/user/statuses/${projectId}`)
export const userReasons = projectId => get(`reports/user/reasons/${projectId}`)
export const tokens = projectId => get(`reports/tokens/${projectId}`)
export const tokensByFields = projectId =>
  get(`reports/tokens/fields/${projectId}`)
export const system = () => get(`reports/system`)
