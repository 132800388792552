import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input, Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import DataTable from '../DataTable/DataTable'
import { Token, Config } from '../../api'
import { FormHelper } from '../helpers'
import withStateLifter from '../StateLifter'
import { openInfo } from '../InfoModal'
import { openConfirm } from '../ConfirmModal'
import { withTranslations } from '../Translation'

class ProjectTokens extends React.Component {
  state = {
    columns: [
      {
        name: 'identifier',
        title: this.props.trans.identifier,
      },
      {
        name: 'description',
        title: this.props.trans.description,
      },
      {
        name: 'assignedTo',
        title: this.props.trans.operator,
      },
    ],
    tokens: [],
    loading: true,
    fetching: false,
  }

  componentDidMount() {
    const columns = [...this.state.columns]

    Config.get(this.props.projectId)
      .then((config) => {
        let fieldValues1, fieldValues2, fieldValues3, fieldValues4, fieldValues5

        if (config.hasField1) {
          columns.push({
            name: 'fieldValueName1',
            title: config.fieldName1,
          })

          fieldValues1 = config.fieldValues
            .filter((v) => v.field === 1)
            .map((v) => ({ value: v.fieldValueId, label: v.fieldValueName }))
        }

        if (config.hasField2) {
          columns.push({
            name: 'fieldValueName2',
            title: config.fieldName2,
          })

          fieldValues2 = config.fieldValues
            .filter((v) => v.field === 2)
            .map((v) => ({ value: v.fieldValueId, label: v.fieldValueName }))
        }

        if (config.hasField3) {
          columns.push({
            name: 'fieldValueName3',
            title: config.fieldName3,
          })

          fieldValues3 = config.fieldValues
            .filter((v) => v.field === 3)
            .map((v) => ({ value: v.fieldValueId, label: v.fieldValueName }))
        }

        if (config.hasField4) {
          columns.push({
            name: 'fieldValueName4',
            title: config.fieldName4,
          })

          fieldValues4 = config.fieldValues
            .filter((v) => v.field === 4)
            .map((v) => ({ value: v.fieldValueId, label: v.fieldValueName }))
        }

        if (config.hasField5) {
          columns.push({
            name: 'fieldValueName5',
            title: config.fieldName5,
          })

          fieldValues5 = config.fieldValues
            .filter((v) => v.field === 5)
            .map((v) => ({ value: v.fieldValueId, label: v.fieldValueName }))
        }

        this.setState({
          config,
          columns,
          fieldValues1,
          fieldValues2,
          fieldValues3,
          fieldValues4,
          fieldValues5,
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })

    this.fetchTokens()
  }

  getContextMenuItems = ({ tokenId }) => [
    {
      title: this.props.trans.delete,
      action: () => {
        openConfirm(this.props.trans.confirmDelete)
          .then(() => {
            Token.remove(this.props.projectId, [tokenId])
          })
          .then(() => {
            this.fetchTokens()
          })
      },
    },

    {
      title: this.props.trans.deleteAll,
      tableContextMenu: true,
      action: () => {
        openConfirm(this.props.trans.confirmDeleteAll)
          .then(() =>
            Token.remove(
              this.props.projectId,
              this.state.tokens.map((t) => t.tokenId)
            )
          )
          .then(() => {
            this.fetchTokens()
          })
      },
    },
  ]

  componentDidUpdate(prevProps) {
    if (
      this.tokens &&
      (prevProps.identifier !== this.props.identifier ||
        prevProps.description !== this.props.description ||
        prevProps.field1ValueId !== this.props.field1ValueId ||
        prevProps.field2ValueId !== this.props.field2ValueId ||
        prevProps.field3ValueId !== this.props.field3ValueId ||
        prevProps.field4ValueId !== this.props.field4ValueId ||
        prevProps.field5ValueId !== this.props.field5ValueId)
    ) {
      const identifiers =
        this.props.identifier &&
        this.props.identifier
          .split('|')
          .map((id) => id.trim())
          .filter((identifier) => identifier)

      this.setState({
        tokens: this.tokens.filter(
          (t) =>
            (!this.props.identifier ||
              identifiers.some((identifier) =>
                t.identifier.toString().includes(identifier)
              )) &&
            (!this.props.description ||
              t.description.includes(this.props.description)) &&
            (!this.props.field1ValueId ||
              t.field1ValueId === this.props.field1ValueId) &&
            (!this.props.field2ValueId ||
              t.field2ValueId === this.props.field2ValueId) &&
            (!this.props.field3ValueId ||
              t.field3ValueId === this.props.field3ValueId) &&
            (!this.props.field4ValueId ||
              t.field4ValueId === this.props.field4ValueId) &&
            (!this.props.field5ValueId ||
              t.field5ValueId === this.props.field5ValueId)
        ),
      })
    }
  }

  fetchTokens = () => {
    this.setState({ fetching: true })

    return Token.getAll(this.props.projectId)
      .then((tokens) => {
        this.tokens = tokens
        this.setState({ tokens })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  handleFileChange = ({ target: { files } }) => {
    if (!files.length) return

    this.setState({ loading: true })
    Token.upload(this.props.projectId, files[0])
      .then((count) => {
        openInfo(this.props.trans.uploadedNewTokens.replace('{0}', count))
        this.fetchTokens()
      })
      .finally(() => {
        this.setState({ loading: false })
        this.fileInput.value = ''
      })
  }

  render() {
    const { config, tokens, columns, loading, fetching } = this.state
    const { trans } = this.props

    return (
      <Modal
        header={trans.title + (config ? ' - ' + config.projectName : '')}
        footer={this.renderActions}
        css="min-height: 540px; min-width: 1000px; padding-bottom: 12px"
        ref={(ref) => (this.modal = ref)}
        closeModal={this.props.closeModal}
      >
        <div
          className="flex-fill flex-column padding bgcolor"
          css="padding-bottom: 0px"
        >
          <div className="flex-row-center gap-bottom">
            <Input
              placeholder={trans.identifier}
              css="width: 170px"
              name="identifier"
              disabled={loading || fetching}
            />

            <Input
              name="description"
              placeholder={trans.description}
              className="gap-left"
              css="width: 170px"
              disabled={loading || fetching}
            />

            {config && config.hasField1 && (
              <Select
                isClearable
                name="field1ValueId"
                className="flex-fill gap-left"
                placeholder={config.fieldName1}
                options={this.state.fieldValues1}
                isDisabled={loading || fetching}
              />
            )}

            {config && config.hasField2 && (
              <Select
                isClearable
                name="field2ValueId"
                className="flex-fill gap-left"
                placeholder={config.fieldName2}
                options={this.state.fieldValues2}
                isDisabled={loading || fetching}
              />
            )}

            {config && config.hasField3 && (
              <Select
                isClearable
                name="field3ValueId"
                className="flex-fill gap-left"
                placeholder={config.fieldName3}
                options={this.state.fieldValues3}
                isDisabled={loading || fetching}
              />
            )}

            {config && config.hasField4 && (
              <Select
                isClearable
                name="field4ValueId"
                className="flex-fill gap-left"
                placeholder={config.fieldName4}
                options={this.state.fieldValues4}
                isDisabled={loading || fetching}
              />
            )}

            {config && config.hasField5 && (
              <Select
                isClearable
                name="field5ValueId"
                className="flex-fill gap-left"
                placeholder={config.fieldName5}
                options={this.state.fieldValues5}
                isDisabled={loading || fetching}
              />
            )}

            <div className="gap-left">{this.state.tokens.length}</div>

            <Button
              className="gap-left"
              disabled={loading || fetching}
              onClick={() => this.fileInput.click()}
            >
              {trans.upload}
            </Button>

            <input
              type="file"
              ref={(ref) => (this.fileInput = ref)}
              css="display: none"
              onChange={this.handleFileChange}
            />
          </div>

          <DataTable
            columns={columns}
            rows={tokens}
            getContextMenuItems={this.getContextMenuItems}
            loading={loading || fetching}
          />
        </div>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('ProjectTokens')(
    withStateLifter((props) => (
      <ProjectTokens
        {...props}
        identifier={FormHelper.getValue(props.lifter, 'identifier')}
        description={FormHelper.getValue(props.lifter, 'description')}
        field1ValueId={FormHelper.getValue(props.lifter, 'field1ValueId')}
        field2ValueId={FormHelper.getValue(props.lifter, 'field2ValueId')}
        field3ValueId={FormHelper.getValue(props.lifter, 'field3ValueId')}
        field4ValueId={FormHelper.getValue(props.lifter, 'field4ValueId')}
        field5ValueId={FormHelper.getValue(props.lifter, 'field5ValueId')}
      />
    ))
  )
)
