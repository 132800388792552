import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'

class ContextMenuRaw extends React.Component {
  static defaultProps = {
    portal: true,
  }

  state = { height: undefined, leftward: false, pageX: undefined }

  handleClick = () => {
    this.props.close()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
    document.addEventListener('contextmenu', this.handleClick)

    const bounding = this.ref.getBoundingClientRect()
    const parentRect = this.ref.parentElement.getBoundingClientRect()

    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth

    if (bounding.bottom > viewportHeight) {
      this.setState({ height: viewportHeight - bounding.top - 5 })
    }

    if (this.ref.offsetWidth > viewportWidth) {
      this.setState({
        pageX: this.props.portal ? '1px' : -parentRect.left + 1,
        width: viewportWidth - 2,
      })
    } else if (bounding.right > viewportWidth) {
      const rightPageX = this.props.portal ? this.props.pageX : parentRect.right

      if (this.ref.offsetWidth > rightPageX) {
        const leftPageX = this.props.portal ? this.props.pageX : parentRect.left

        const offset = this.ref.offsetWidth - (viewportWidth - leftPageX) + 10
        this.setState({
          pageX: this.props.portal ? this.props.pageX - offset : -offset,
        })
      } else {
        this.setState({
          leftward: true,
          pageX: this.props.portal ? viewportWidth - this.props.pageX : '0px',
        })
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('contextmenu', this.handleClick)
  }

  renderMenu = (items) => {
    const { pageX, pageY, className } = this.props

    return (
      <div
        className={className}
        style={{
          height: this.state.height,
          [this.state.leftward ? 'right' : 'left']: this.state.pageX || pageX,
          top: pageY,
          width: this.state.width,
        }}
        ref={(ref) => (this.ref = ref)}
      >
        {items.map((item) => (
          <div
            className="context-menu-item"
            onClick={this.handleItemClick(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
    )
  }

  handleItemClick = (item) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (item.action) item.action(item)

    if (this.props.onAction) this.props.onAction(item)

    this.props.close()
  }

  render() {
    const { items, portal } = this.props

    return portal
      ? ReactDOM.createPortal(
          this.renderMenu(items),
          document.getElementById('root')
        )
      : this.renderMenu(items)
  }
}

const ContextMenu = styled(ContextMenuRaw)`
  white-space: nowrap;
  position: absolute;
  background-color: white;
  user-select: none;
  border: 1px solid #c6c8ca;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 5px 0px;
  border-radius: 3px;
  z-index: 1;
  bottom: ${(props) => (props.upward ? '100%' : null)};
  overflow: auto;

  .context-menu-item {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #e9ecef;
    }
  }
`

export default ContextMenu
