import React from 'react'
import styled from 'styled-components/macro'
import { LifterContext } from '../StateLifter'

const InputTextArea = ({ textArea, ...rest }) => {
  return textArea ? <textarea {...rest} /> : <input {...rest} />
}

const StyledInput = styled(InputTextArea)`
  height: ${(props) => (props.textArea ? '60px' : '38px')};
  resize: none;
  padding: 8px;
  line-height: 20px;
  border: 1px solid ${(props) => (props.error ? '#dc3545' : '#ced4da')};
  border-radius: 4px;
  outline: 0;
  color: #495057;
  background-color: #fff;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

  &:focus {
    border-color: ${(props) => (props.error ? '#dc3545' : '#81b2e7')};
    box-shadow: ${(props) =>
      props.error
        ? '0 0 0 2px rgba(220,53,69,.25)'
        : '0 0 0 2px rgba(129,178,231,.3)'};
  }

  &:disabled {
    background-color: whitesmoke;
  }
`

const charRegexp = /^[\d\.\-]$/

class Input extends React.PureComponent {
  constructor(props, context) {
    super(props, context)

    const { name } = props

    if (context && context.getState && name) {
      const state = context.getState(name)

      if (!state) {
        const value = props.initialValue || ''

        context.setState(name, {
          value,
          touched: false,
          invalid: this.invalidate(value),
        })
      }

      context.setNotifier(name, this.notifier)
    }
  }

  notifier = () => {
    this.forceUpdate()
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.context.removeNotifier(prevProps.name, this.notifier)
      this.context.setNotifier(this.props.name, this.notifier)
    }
  }

  componentWillUnmount() {
    const { name } = this.props
    if (this.hasLifter && name) {
      this.context.removeNotifier(name)
    }
  }

  get hasLifter() {
    return this.context && !!this.context.getState
  }

  static contextType = LifterContext

  handleChange = (event) => {
    const { value } = event.target
    const { name, onChange, onChanged, number, unsigned, integer } = this.props

    if (number && value) {
      if (!unsigned && (value.split('-').length > 2 || value.indexOf('-') > 0))
        return
      if (!integer && value.split('.').length > 2) return

      if (value !== '-' && value !== '.') {
        if (integer && isNaN(parseInt(value))) return

        if (!integer && isNaN(parseFloat(value))) return
      }
    }

    if (this.hasLifter) {
      this.context.setState(
        name,
        {
          value,
          touched: true,
          invalid: this.invalidate(value),
        },
        () => {
          if (onChanged) onChanged(event)
        }
      )
    }

    if (onChange) onChange(event)
  }

  handleKeyPress = (event) => {
    const {
      key,
      target: { value },
    } = event
    const { number, integer, unsigned } = this.props

    if (
      number &&
      (!charRegexp.test(key) ||
        (integer && key === '.') ||
        (unsigned && key === '-') ||
        (!unsigned && key === '-' && (value.match(/\-/g) || []).length > 0) ||
        (!integer && key === '.' && (value.match(/\./g) || []).length > 0))
    )
      event.preventDefault()
  }

  invalidate = (value) => {
    let invalid = false

    const { required, email, number, integer, max, min } = this.props

    if (this.hasLifter) {
      if (required && !value) {
        invalid = true
      } else if (email && value) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        invalid = !re.test(value.toLowerCase())
      } else if (number && value) {
        const number = integer ? parseInt(value) : parseFloat(value)
        invalid =
          isNaN(number) ||
          (min !== undefined && number < min) ||
          (max !== undefined && number > max)
      }
    }

    return invalid
  }

  handleBlur = (event) => {
    const { name, onBlur } = this.props

    if (this.hasLifter && name) {
      this.context.setState(name, {
        touched: true,
        invalid: this.invalidate(this.getValue()),
      })
    }

    if (onBlur) onBlur(event)
  }

  getState = (name) => this.hasLifter && name && this.context.getState(name)

  getValue = () => {
    const { name } = this.props
    const state = this.getState(name)
    const value = state && state.value
    return value === 0 ? 0 : value || ''
  }

  isTouched = () => {
    const { name } = this.props
    const state = this.getState(name)
    return (state && state.touched) || false
  }

  isInvalid = () => {
    const { name } = this.props
    const state = this.getState(name)
    return (state && state.invalid) || false
  }

  render() {
    const { number, integer, unsigned, password, ...restProps } = this.props

    return (
      <StyledInput
        type={password ? 'password' : 'text'}
        value={this.getValue()}
        {...restProps}
        maxLength={
          number
            ? (integer && unsigned && 16) ||
              (integer && 17) ||
              (unsigned && 17) ||
              18
            : undefined
        }
        onKeyPress={this.handleKeyPress}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={this.isInvalid() && this.isTouched()}
      />
    )
  }
}

export default Input
