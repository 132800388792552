import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Route } from 'react-router'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useMobileQuery } from './components/MediaQuery'
import './app.css'
import Layout from './components/Layout'
import CallForm from './components/Call/CallForm'
import CallbackPage from './components/callback'
import userManager from './userManager'
import Dashboard from './components/Dashboard'
import Projects from './components/Project/ProjectList'
import ClientList from './components/Client/ClientList'
import CallList from './components/Call/CallList'
import Config from './components/Config'
import QuotasReport from './components/Reports/QuotasReport'
import UserList from './components/User/UserList'
import OperatorReport from './components/Reports/OperatorReport'
import StatusReport from './components/Reports/StatusReport'
import RealTimeReport from './components/Reports/RealTimeReport'
import TokenReport from './components/Reports/TokenReport'
import SystemReport from './components/Reports/SystemReport'
import RoleList from './components/Roles/RoleList'
import { setPermissions } from './permissions'
import { User } from './api'
import Loader from './components/Loader'
import Permissions, { hasPermission } from './permissions'
import TrainForm from './components/Train'

const App = ({ user, isLoadingUser, location, history }) => {
  const isSigningOut = useRef(false)

  const [loading, setLoading] = useState(true)

  const isUserValid = user && !user.expired

  const isMobile = useMobileQuery()

  useEffect(() => {
    if (
      isMobile &&
      !loading &&
      user &&
      !user.expired &&
      location.pathname !== '/config' &&
      location.pathname !== '/call'
    ) {
      history.push('/call')
    }
  }, [isMobile, history, loading, user, location.pathname])

  useEffect(() => {
    if (isUserValid && loading) {
      User.getPermissions().then((permissions) => {
        setPermissions(permissions)
        setLoading(false)
      })
    }
  }, [isUserValid, loading, setLoading])

  useEffect(() => {
    if (
      !isSigningOut.current &&
      !isLoadingUser &&
      location.pathname !== '/callback' &&
      (!user || user.expired)
    ) {
      userManager.signinRedirect()
    }
  }, [isLoadingUser, user, location])

  useEffect(() => {
    const onUnload = (event) => {
      event.preventDefault()
      event.returnValue = ''
    }

    window.addEventListener('beforeunload', onUnload)

    return () => window.removeEventListener('beforeunload', onUnload)
  }, [])

  const handleSigningOut = useCallback(() => {
    isSigningOut.current = true
  }, [isSigningOut])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        user &&
        !user.expired && (
          <Layout onSigningOut={handleSigningOut}>
            {user && !user.expired && (
              <>
                <Route
                  exact
                  path="/"
                  component={
                    hasPermission(Permissions.ReportSystem)
                      ? SystemReport
                      : Dashboard
                  }
                />
                <Route exact path="/call" component={CallForm} />
                <Route path="/projects" component={Projects} />
                <Route path="/clients" component={ClientList} />
                <Route path="/calls" component={CallList} />
                <Route path="/config" component={Config} />
                <Route path="/users" component={UserList} />
                <Route path="/reports/quotas" component={QuotasReport} />
                <Route path="/reports/operator" component={OperatorReport} />
                <Route path="/reports/status" component={StatusReport} />
                <Route path="/reports/realtime" component={RealTimeReport} />
                <Route path="/reports/token" component={TokenReport} />
                <Route path="/roles" component={RoleList} />
                <Route path="/train" component={TrainForm} />
              </>
            )}
          </Layout>
        )
      )}

      <Route path="/callback" component={CallbackPage} />
    </>
  )
}

export default withRouter(
  connect((state) => ({
    isLoadingUser: state.oidc.isLoadingUser,
    user: state.oidc.user,
  }))(App)
)
