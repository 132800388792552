import { store } from '../index'
import { openError } from '../components/ErrorModal'
import { currentLocale } from '../components/Translation'

const base = (url, method, body, isFile = false) => {
  const state = store.getState()
  const { access_token } = state.oidc.user

  const isGet = method === 'GET'

  let ok

  let formData
  if (isFile) {
    formData = new FormData()
    formData.append('file', body)
  }

  return fetch(`api/${url}`, {
    method,
    mode: 'cors',
    //cache: 'no-store',
    headers: {
      authorization: `Bearer ${access_token}`,
      'Accept-Language': currentLocale,
      //'cache-control': 'no-store',
      ...(!isGet && !isFile ? { 'content-type': 'application/json' } : {})
    },
    body:
      body && !isGet ? (isFile ? formData : JSON.stringify(body)) : undefined
  })
    .then(response => {
      ok = response.ok

      if (response.status === 204) return

      const contentType = response.headers.get('content-type')
      const isJson =
        contentType &&
        (contentType.includes('application/json') ||
          contentType.includes('application/problem+json'))

      return isJson ? response.json() : response.text()
    })
    .then(result => {
      if (ok) {
        return result
      } else {
        throw result
      }
    })
    .catch(error => {
      if (error && (error.message || error.title)) {
        openError(error.message || error.title, error.stackTrace)
      }

      throw error
    })
}

export const get = url => base(url, 'GET')

export const post = (url, body) => base(url, 'POST', body)

export const postFile = (url, file) => base(url, 'POST', file, true)

export const put = (url, body) => base(url, 'PUT', body)

export const del = (url, body) => base(url, 'DELETE', body)
