import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input, Button, Checkbox } from '../controls'
import Modal, { withModalManager } from '../Modal'
import DataTable from '../DataTable'
import withStateLifter from '../StateLifter'
import { FormHelper, StringHelper } from '../helpers'
import { Config, Role, User, Call } from '../../api'
import { withTranslations } from '../Translation'

const TabsBody = styled.div`
  flex: 1;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
`

const Form = styled.div`
  display: grid;
  grid-template-columns: 42% auto;
  grid-gap: 12px;

  > label {
    display: flex;
    align-items: center;
  }
`

const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  user-select: none;
  padding-left: 12px;
  margin-top: 12px;

  .tab {
    position: relative;
    padding: 8px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .tab-selected {
    border: 1px solid #ced4da;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: -1px;
    background-color: whitesmoke;
    cursor: auto;
    padding-top: 7px !important;
  }
`

const fields = [
  'tokensReusable',
  'tokenReuseTimes',
  'reuseReasons',

  'automateCalls',
  'automationDelay',
  'testMode',
  'automaticRecognition',
  'questionnaireReviewable',
  'alwaysCallable',
]

const operatorRoleId = 6

class CallConfig extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      fetching: true,
      activeTab: 0,
    }

    props.setLoading(true)

    Promise.all([
      Role.getAll(),
      User.getAll(),
      Config.getCall(props.projectId),
      Call.getReasons(),
    ])
      .then(([roles, users, config, reasons]) => {
        FormHelper.setValues(props.lifter, fields, config)

        this.users = users.filter((u) => !config.members.includes(u.userId))
        this.members = users.filter((u) => config.members.includes(u.userId))

        const self = this
        this.setState({
          roles: roles.map((role) => ({
            value: role.roleId,
            role,
            get label() {
              return self.props.isDefaultLocale
                ? this.role.roleName
                : this.role[
                    'roleName' + StringHelper.capitalize(self.props.locale)
                  ]
            },
          })),
          config,
          users: this.users,
          members: this.members,
          reasons: FormHelper.getOptions(
            reasons,
            'reasonId',
            'reasonName' +
              (props.isDefaultLocale
                ? ''
                : StringHelper.capitalize(props.locale))
          ),
        })

        this.filterUsers()
        this.filterMembers()
      })
      .finally(() => {
        this.setState({ fetching: false })
        props.setLoading(false)
      })
  }

  columns = [
    {
      name: 'fullName',
      title: this.props.trans.fullName,
    },
  ]

  submit = () => {
    const { lifter, projectId, onSuccess, closeModal } = this.props

    FormHelper.setTouched(lifter, fields, true)

    if (FormHelper.isInvalid(lifter, fields)) {
      return
    }

    this.setState({ submitting: true })

    const config = FormHelper.getValues(lifter, fields)
    config.members = this.members.map((m) => m.userId)

    Config.putCall(projectId, config)
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  renderActions = () => {
    return (
      <div className="text-right">
        <Button onClick={this.submit}>{this.props.trans.save}</Button>
      </div>
    )
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  addMember = (user) => {
    if (user || this.state.selectedUser) {
      const selected = user || this.state.selectedUser

      this.users = this.users.filter((u) => u !== selected)
      this.members = [...this.members, selected]

      this.filterMembers()
      this.filterUsers()
    }
  }

  removeMember = (member) => {
    if (member || this.state.selectedMember) {
      const selected = member || this.state.selectedMember

      this.users = [...this.users, selected]
      this.members = this.members.filter((m) => m !== selected)

      this.filterMembers()
      this.filterUsers()
    }
  }

  handleUserRowSelected = (row) => {
    this.setState({ selectedUser: row })
  }

  handleMemberRowSelected = (row) => {
    this.setState({ selectedMember: row })
  }

  getUsersContextMenuItems = (row) => [
    {
      title: this.props.trans.add,
      action: () => {
        this.addMember(row)
      },
    },
  ]

  getMembersContextMenuItems = (row) => [
    {
      title: this.props.trans.remove,
      action: () => {
        this.removeMember(row)
      },
    },
  ]

  filterUsers = () => {
    this.setState((state) => ({
      users: this.users.filter(
        (u) =>
          u.roles.includes(operatorRoleId) &&
          (!state.usersQuery || u.fullName.includes(state.usersQuery)) &&
          (!state.role || u.roles.includes(state.role.value))
      ),
    }))
  }

  filterMembers = () => {
    this.setState((state) => ({
      members: this.members.filter(
        (u) =>
          u.roles.includes(operatorRoleId) &&
          (!state.membersQuery || u.fullName.includes(state.membersQuery)) &&
          (!state.role || u.roles.includes(state.role.value))
      ),
    }))
  }

  handleUsersQueryChange = (event) => {
    this.setState({ usersQuery: event.target.value }, this.filterUsers)
  }

  handleMembersQueryChange = (event) => {
    this.setState({ membersQuery: event.target.value }, this.filterMembers)
  }

  handleRoleChange = (role) => {
    this.setState({ role: role }, () => {
      this.filterUsers()
      this.filterMembers()
    })
  }

  render() {
    if (this.state.fetching) return null

    const { users, members, roles } = this.state
    const { trans } = this.props

    return (
      <Modal
        header={trans.title}
        footer={this.renderActions}
        css="width: 960px; min-height: 500px"
        ref={(ref) => (this.modal = ref)}
        closeModal={this.props.closeModal}
      >
        <TabsContainer>
          <div
            onClick={this.handleTabClick(0)}
            className={`tab${this.tabSelected(0)}`}
          >
            {trans.parameters}
          </div>

          <div
            onClick={this.handleTabClick(1)}
            className={`tab${this.tabSelected(1)}`}
          >
            {trans.operators}
          </div>
        </TabsContainer>

        <TabsBody>
          {this.state.activeTab === 0 && (
            <Form>
              <label>{trans.tokensReusable}</label>
              <Checkbox name="tokensReusable" />

              <label>{trans.tokenReuseTimes}</label>

              <Input
                name="tokenReuseTimes"
                css="width: 120px"
                min={1}
                max={100}
                required
                number
                integer
                unsigned
              />

              <label>{trans.reuseReasons}</label>
              <Select
                css="grid-column: 1 / span 2"
                name="reuseReasons"
                isMulti
                options={this.state.reasons}
              />

              <label>{trans.automaticCalls}</label>
              <Checkbox name="automateCalls" />

              <label>{trans.automationDelay}</label>
              <Input
                number
                unsigned
                integer
                name="automationDelay"
                css="width: 120px"
              />

              <label>{trans.testMode}</label>
              <Checkbox name="testMode" />

              <label>{trans.automaticRecognition}</label>
              <Checkbox name="automaticRecognition" />

              <label>{trans.questionnaireReviewable}</label>
              <Checkbox name="questionnaireReviewable" />

              <label>{trans.alwaysCallable}</label>
              <Checkbox name="alwaysCallable" />
            </Form>
          )}

          {this.state.activeTab === 1 && (
            <>
              <div css="margin-bottom: 12px;" className="flex-row-center-space">
                <Input
                  onChange={this.handleUsersQueryChange}
                  value={this.state.usersQuery}
                  className="flex-fill gap-right"
                  placeholder={trans.availableSearch}
                />

                <Select
                  css="width: 300px;"
                  value={this.state.role}
                  onChange={this.handleRoleChange}
                  options={roles}
                  isClearable
                  placeholder={trans.chooseRole}
                />

                <Input
                  onChange={this.handleMembersQueryChange}
                  value={this.state.membersQuery}
                  className="flex-fill gap-left"
                  placeholder={trans.participantSearch}
                />
              </div>

              <div css="flex: 1; display: flex; flex-direction: row; align-items: stretch">
                <DataTable
                  columns={this.columns}
                  rows={users}
                  onRowSelected={this.handleUserRowSelected}
                  onRowDoubleClick={(row) => this.addMember(row)}
                  getContextMenuItems={this.getUsersContextMenuItems}
                />

                <div css="display: flex; flex-direction: column; padding: 12px;">
                  <Button
                    css="margin-bottom: 12px;"
                    onClick={() => this.addMember()}
                  >
                    {trans.add} ≫
                  </Button>

                  <Button onClick={() => this.removeMember()}>
                    ≪ {trans.remove}
                  </Button>
                </div>

                <DataTable
                  getContextMenuItems={this.getMembersContextMenuItems}
                  columns={this.columns}
                  rows={members}
                  onRowSelected={this.handleMemberRowSelected}
                  onRowDoubleClick={(row) => this.removeMember(row)}
                />
              </div>
            </>
          )}
        </TabsBody>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('CallConfig')(
    withStateLifter((props) => <CallConfig {...props} />)
  )
)
