import React from 'react'
import styled from 'styled-components/macro'
import * as tf from '@tensorflow/tfjs'
import { MobileQuery, DesktopQuery, minWidth, maxWidth } from '../MediaQuery'
import {
  Select,
  Input,
  DateInput,
  Button,
  DropdownButton,
  TextBox,
} from '../controls'
import withStateLifter from '../StateLifter'
import { Project, Call, Config, Quota, Train } from '../../api'
import { FormHelper, DateHelper, StringHelper } from '../helpers'
import DataTable from '../DataTable'
import { ReactComponent as CallIcon } from '../../icons/call.svg'
import { ReactComponent as GenerateIcon } from '../../icons/generate.svg'
import { ReactComponent as CallingIcon } from '../../icons/calling.svg'
import { ReactComponent as RecallIcon } from '../../icons/recall.svg'
import { ReactComponent as CheckIcon } from '../../icons/check.svg'
import { ReactComponent as StopIcon } from '../../icons/stop.svg'
import { ReactComponent as MicrophoneIcon } from '../../icons/microphone.svg'
import { ReactComponent as MicrophoneSlashIcon } from '../../icons/microphone-slash.svg'
import { ReactComponent as ArrowUpIcon } from '../../icons/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg'
import { ReactComponent as DisconnectedIcon } from '../../icons/disconnected.svg'
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg'
import { ReactComponent as PauseIcon } from '../../icons/pause.svg'
import { ReactComponent as PlayIcon } from '../../icons/play.svg'
import * as SIP from 'sip.js'
import { ReactComponent as ListIcon } from '../../icons/list.svg'
import { withTranslations } from '../Translation'
import { results, statuses, hours, minutes, stages } from './constants'
import Loader from '../Loader'
import { parseDate } from '../helpers/date'
import CallComment from './CallComment'
import { NavbarContext } from '../Navbar'
import { openError } from '../../components/ErrorModal'

const FRAMES = 156

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: ${maxWidth}) {
    flex-direction: column;
  }
`

const ProjectCallContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: ${maxWidth}) {
    &.no-flex {
      flex: initial;
    }
  }
`

const CallContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media (max-width: ${maxWidth}) {
    flex-direction: column;
  }
`

const SurveyContainer = styled.div`
  border: 1px solid #ced4da;
  border-radius: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${minWidth}) {
    margin-left: 12px;
  }

  @media (max-width: ${maxWidth}) {
    margin-top: 12px;
  }
`

const Form = styled.div`
  @media (min-width: ${minWidth}) {
    width: 25%;
    min-width: 378px;
  }

  display: grid;
  grid-template-columns: auto;
  grid-gap: 12px;
  grid-auto-rows: minmax(min-content, max-content);

  > label {
    display: flex;
    align-items: center;
  }
`

const Filter = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${maxWidth}) {
    flex-direction: column;
  }

  & > .first-two {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;

    @media (max-width: ${maxWidth}) {
      margin-right: 0px;
      margin-bottom: 12px;
    }
  }
`

const CallsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${minWidth}) {
    margin-left: 12px;
    width: 45%;
  }

  @media (max-width: ${maxWidth}) {
    flex: 1;
  }
`

const surveyMethods = {
  none: 1,
  questioPro: 2,
  surveyToGo: 3,
}

const surveyTypes = {
  individuals: 1,
  organizations: 2,
}

const callDirection = {
  outgoing: 0,
  incoming: 1,
  bothways: 2,
}

class CallForm extends React.PureComponent {
  state = {
    offline: false,
    surveyEnlarged: false,
    completionUrl: '',
    muted: false,
    stopwatchTime: '00:00',
    callsFetching: false,
    fetching: true,
    loading: false,
    projects: [],
    registered: false,
    stage: stages.ready,
    failOpen: false,
    stopOpen: false,
    calls: [],
    callLater: false,
    callsVisible: false,
    surveyVisible: false,
    hasAnswered: false,
    preview: false,
    ringing: false,
    results: Object.keys(results).map((key) => ({
      value: results[key],
      label: this.props.trans['result.' + results[key]],
    })),
    statuses: Object.keys(statuses).map((key) => ({
      value: statuses[key],
      label: this.props.trans['status.' + statuses[key]],
    })),
    meetQuotas: undefined,
    readyToReceive: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.setState({
        results: Object.keys(results).map((key) => ({
          value: results[key],
          label: this.props.trans['result.' + results[key]],
        })),
        statuses: Object.keys(statuses).map((key) => ({
          value: statuses[key],
          label: this.props.trans['status.' + statuses[key]],
        })),
      })
    }
  }

  online = () => {
    this.setState({ offline: false })
  }

  offline = () => {
    this.setState({ offline: true })
  }

  componentDidMount() {
    Promise.all([
      Config.getSip(),
      Call.getReasons(),
      Project.getCurrent(),
      Train.getLabels(),
      tf.loadLayersModel('api/train/model/model.json'),
    ])
      .then(([sipConfig, reasons, projects, labels, model]) => {
        this.sipConfig = sipConfig
        this.labels = labels
        this.model = model

        const self = this
        this.setState({
          badCallReasons: reasons
            .filter((r) => r.result === results.badCall)
            .map((reason) => ({
              get title() {
                return self.props.isDefaultLocale
                  ? this.reason.reasonName
                  : this.reason[
                      'reasonName' + StringHelper.capitalize(self.props.locale)
                    ]
              },
              reason,
              value: reason.reasonId,
            })),

          stopReasons: reasons
            .filter((r) => r.result === results.stopped)
            .map((reason) => ({
              get title() {
                return self.props.isDefaultLocale
                  ? this.reason.reasonName
                  : this.reason[
                      'reasonName' + StringHelper.capitalize(self.props.locale)
                    ]
              },
              reason,
              value: reason.reasonId,
            })),

          projects: projects.map((p) => ({
            value: p.projectId,
            label: p.projectName,
          })),
        })

        return projects[0] && this.loadProject(projects[0].projectId)
      })
      .finally(() => {
        this.setState({ fetching: false }, () => {
          this.createUA()
        })
      })

    window.addEventListener('online', this.online)
    window.addEventListener('offline', this.offline)
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.online)
    window.removeEventListener('offline', this.offline)
  }

  createUA = () => {
    if (!this.sipConfig || !this.sipConfig.username) {
      openError(this.props.trans.sipNoUser)
      return
    }

    this.sipUri = `${this.sipConfig.username}@${this.sipConfig.registrar}`
    this.ua = new SIP.UA({
      register: true,
      uri: `${this.sipConfig.username}@${this.sipConfig.registrar}`,
      password: this.sipConfig.password,
      hackWssInTransport: true,
      transportOptions: {
        wsServers: this.sipConfig.wsServer,
        keepAliveInterval: 6,
        maxReconnectionAttempts: 10,
        reconnectionTimeout: 6,
      },
      media: { remote: { audio: this.remoteAudio } },
    })

    this.ua.on('registered', (...args) => {
      this.setState({ registered: true })
      console.log('registered', args)
    })

    this.ua.on('unregistered', (...args) => {
      this.setState({ registered: false })
      console.log('unregistered', args)
    })

    this.ua.on('invite', this.onInvite)
  }

  onInvite = async (session) => {
    console.log('onInvite', session)
    if (
      this.state.config.callDirection === callDirection.outgoing ||
      !this.state.readyToReceive ||
      !this.ready
    ) {
      session.reject()
      return
    }

    this.session = session
    this.configureSession()
    const projectId = this.getValue('projectId')
    let call = {
      phoneNumber: session.remoteIdentity.friendlyName,
      callDirection: callDirection.incoming,
      sipCallId: this.session.request.callId,
    }
    FormHelper.setValues(this.lifter, ['phoneNumber'], call)

    try {
      call = await Call.create(projectId, call)
    } catch (error) {
      session.reject()
      openError(
        `დაფიქსირდა შემომვალი ზარი ნომრიდან ${session.remoteIdentity.friendlyName}, თუმცა ამ ნომრით უკვე არსებობს ჩანაწერი! ზარი უარყოფილია!`
      )
      return
    }

    this.call = call
    this.setState({
      muted: false,
      ringing: true,
      stage: stages.calling,
      stopwatchTime: '00:00',
      call,
    })

    this.ringtone.play()
    this.startTime = new Date().getTime()
    this.stopwatch = setInterval(this.onInterval, 1000)
  }

  ensureRegistered = () => {
    if (!this.ua || !this.ua.isRegistered()) {
      this.setState({ registered: false })
      return false
    }

    return true
  }

  stopwatch = undefined
  startTime = undefined

  clearStopwatch = () => {
    clearInterval(this.stopwatch)
    delete this.stopwatch
  }

  _call = (destination) => {
    if (!this.ensureRegistered()) return

    // Safari hack, because you cannot call .play() from a non user action
    this.remoteAudio.autoplay = true

    const target =
      process.env.NODE_ENV === 'development'
        ? '599470217'
        : localStorage.getItem('sip.mockPhoneNumber') || destination

    this.session = this.ua.invite(target, {
      extraHeaders: [`X-Call-Id: ${this.call.callId}`],
      earlyMedia: true,
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,
        },
      },
    })

    if (!this.session) return

    console.log(this.session)

    this.configureSession()

    this.setState({
      muted: false,
      stage: stages.calling,
      stopwatchTime: '00:00',
    })

    this.startTime = new Date().getTime()
    this.stopwatch = setInterval(this.onInterval, 1000)

    return this.update({
      status: statuses.calling,
      sipCallId: this.session.request.callId,
    })
  }

  configureSession = () => {
    this.session.on('SessionDescriptionHandler-created', (handler) => {
      this.session.sessionDescriptionHandler.on('addTrack', () => {
        this.setupRemoteMedia()
      })

      this.session.sessionDescriptionHandler.on('addStream', () => {
        this.setupRemoteMedia()
      })
    })

    this.session.on('progress', this.onProgress)
    this.session.on('accepted', this.onAccepted)
    this.session.on('rejected', this.onRejected)
    this.session.on('failed', this.onFailed)
    this.session.on('terminated', this.onTerminated)
    this.session.on('cancel', this.onCancel)
    this.session.on('bye', this.onBye)
    this.session.on('dtmf', (request, dtmf) => {
      this.emit('dtmf', dtmf.tone)
    })
  }

  onInterval = () => {
    const currentTime = new Date().getTime()
    const diff = Math.round((currentTime - this.startTime) / 1000)

    const minutes = `0${Math.trunc(diff / 60)}`.slice(-2)
    const seconds = `0${diff % 60}`.slice(-2)

    this.setState({
      stopwatchTime: `${minutes}:${seconds}`,
    })
  }

  onProgress = (...args) => {
    console.log('onProgress', args, this.session)
    const [response] = args
    if (
      response.statusCode === 183 &&
      response.body &&
      this.session.hasOffer &&
      !this.session.dialog
    ) {
      if (
        !response.hasHeader('require') ||
        response.getHeader('require').indexOf('100rel') === -1
      ) {
        if (
          this.session.hasOffer &&
          !this.session.hasAnswer &&
          this.session.sessionDescriptionHandler
        ) {
          this.session.status = SIP.SessionStatus.STATUS_EARLY_MEDIA

          this.session.sessionDescriptionHandler
            .setDescription(
              response.body,
              this.session.sessionDescriptionHandlerOptions,
              this.session.modifiers
            )
            .then(() => {
              this.session.status = SIP.SessionStatus.STATUS_EARLY_MEDIA
            })
            .catch((error) => {
              if (this.session.status === SIP.SessionStatus.STATUS_TERMINATED) {
                return
              }
              this.session.failed(undefined, SIP.C.causes.WEBRTC_ERROR)
              this.session.terminated(undefined, SIP.C.causes.WEBRTC_ERROR)
            })
        }
      }
    }
  }

  onRejected = (...args) => {
    console.log('onRejected', args)
  }

  update = ({
    result,
    status,
    reasonId,
    hangupCause,
    callLaterAt,
    sipCallId,
    reviewStatus,
  }) => {
    if (!this.call) return

    const {
      projectId,
      comment,
      field1ValueId,
      field2ValueId,
      field3ValueId,
      field4ValueId,
      field5ValueId,
      phoneNumber,
    } = this.getValues([
      'projectId',
      'comment',
      'field1ValueId',
      'field2ValueId',
      'field3ValueId',
      'field4ValueId',
      'field5ValueId',
      'phoneNumber',
    ])

    if (status) this.call.status = status
    if (result) this.call.result = result
    this.call.phoneNumber = phoneNumber

    return Call.update(projectId, this.call.callId, {
      phoneNumber,
      status: this.call.status,
      result: this.call.result,
      reviewStatus: reviewStatus || this.call.reviewStatus,
      remarks: this.call.remarks,
      assignedToId: this.call.assignedToId,
      reasonId,
      comment,
      callLaterAt,
      field1ValueId,
      field2ValueId,
      field3ValueId,
      field4ValueId,
      field5ValueId,
      hangupCause,
      sipCallId,
      extension: this.sipUri,
    }).then(() => {
      this.setState({ call: this.call })

      this.fetchCalls()
    })
  }

  onFailed = (response, cause) => {
    console.log('onFailed', response, cause)

    this.ringtone.pause()
    this.ringtone.currentTime = 0
    this.setState({ ringing: false })

    if (!this.call) return

    let status = this.canceled ? statuses.noAnswer : statuses.failedOther

    const hangupCause =
      response &&
      response.getHeader &&
      response.getHeader('X-Asterisk-Hangupcausecode')

    if (hangupCause) {
      //these code are screwed, can't rely on it, need machine learning
      const code = hangupCause.toString()
      if (code === '17') {
        status = statuses.busy
      } else if (code === '1') {
        status = statuses.unassigned
      } else if (code === '19') {
        status = statuses.unreachable
      } else if (code === '21') {
        status = statuses.noAnswer
      }
    } else if (this.failStatus) {
      status = this.failStatus
    }

    this.update({ result: results.failed, status, hangupCause }).then(() => {
      const { tokenManualInput, surveyType } = this.state.config

      const { automateCalls, automationDelay, automaticRecognition } =
        this.state.callConfig

      if (
        surveyType !== surveyTypes.organizations &&
        !tokenManualInput &&
        automaticRecognition &&
        this.failStatus
      ) {
        this.clear()

        if (automateCalls) {
          const autoTimer = setTimeout(() => {
            this.setState({ autoTimer: undefined }, () => {
              this.handleCall()
            })
          }, (automationDelay || 0) * 1000)

          this.setState({ autoTimer })
        }
      } else {
        this.setState({
          stage: stages.failed,
          canceled: this.canceled,
          surveyEnlarged: false,
        })
        this.canceled = false

        this.clearStopwatch()
      }

      delete this.failStatus
    })
  }

  onTerminated = (...args) => {
    console.log('onTerminated', args)

    delete this.session
  }

  onBye = (response, cause) => {
    console.log('onBye', response, cause)

    const hangupCause =
      response && response.getHeader('X-Asterisk-Hangupcausecode')

    this.setState({
      stage: stages.ended,
      surveyEnlarged: false,
      hasAnswered: true,
    })
    this.clearStopwatch()
    this.update({ status: statuses.ended, hangupCause })
  }

  onCancel = (...args) => {
    console.log('onCancel', args)
  }

  onAccepted = (...args) => {
    console.log('onAccepted', args)

    if (!this.session) return

    this.setupRemoteMedia()

    this.update({ status: statuses.talking })

    this.ringtone.pause()
    this.ringtone.currentTime = 0
    this.setState({ stage: stages.talking, hasAnswered: true, ringing: false })
  }

  isFirstChunk = true
  mediaRecorder = null

  setupRemoteMedia = () => {
    if (!this.session) return

    // If there is a video track, it will attach the video and audio to the same element
    const pc = this.session.sessionDescriptionHandler.peerConnection
    let remoteStream

    if (pc.getReceivers) {
      remoteStream = new MediaStream()
      pc.getReceivers().forEach((receiver) => {
        const track = receiver.track
        if (track) {
          remoteStream.addTrack(track)
        }
      })
    } else {
      remoteStream = pc.getRemoteStreams()[0]
    }

    const { automaticRecognition } = this.state.callConfig

    if (automaticRecognition) {
      this.isFirstChunk = true
      delete this.failStatus

      this.mediaRecorder = new MediaRecorder(remoteStream)
      this.mediaRecorder.ondataavailable = (event) => {
        if (!this.isFirstChunk) return
        this.isFirstChunk = false
        var reader = new FileReader()
        reader.readAsArrayBuffer(event.data)
        reader.onloadend = () => {
          if (this.mediaRecorder.state !== 'inactive') {
            this.mediaRecorder.stop()
          }
          const OfflineAudioContext =
            window.OfflineAudioContext || window.webkitOfflineAudioContext
          const audioCtx = new OfflineAudioContext(1, 7 * 8000, 8000)
          audioCtx.decodeAudioData(reader.result, (audioBuffer) => {
            // Perform fast Fourier transform to extract frequencies
            const analyser = audioCtx.createAnalyser()
            analyser.fftSize = 512
            var bufferSource = audioCtx.createBufferSource()
            bufferSource.buffer = audioBuffer
            bufferSource.connect(analyser)
            //analyser.connect(audioCtx.destination)
            const processor = audioCtx.createScriptProcessor(256, 1, 1)
            processor.connect(audioCtx.destination)
            const sampleData = []
            processor.onaudioprocess = function (event) {
              const freqData = new Uint8Array(analyser.frequencyBinCount)
              analyser.getByteFrequencyData(freqData)
              sampleData.push(Array.from(freqData))
            }
            analyser.connect(processor)
            bufferSource.start(0)
            audioCtx.startRendering().then(() => {
              if (!this.calling) return
              const tensorData = sampleData.map((x) => x.map((y) => [y]))

              let maxOdd = 0
              let maxOdds
              let index = 0
              for (index = 0; index < 30; index++) {
                const slicedData = tensorData.slice(index, FRAMES + index)
                const result = this.model.predict(
                  tf.tensor4d([slicedData], [1, FRAMES, 256, 1])
                )

                const odds = result.arraySync()[0]
                const odd = Math.max(...odds)
                if (odd > maxOdd) {
                  maxOdd = odd
                  maxOdds = odds
                }
                if (maxOdd === 1) break
              }

              const maxIndex = maxOdds.indexOf(maxOdd)

              if (maxOdd >= 0.99) {
                const label = this.labels.find(
                  (label) => label.labelValue === maxIndex
                )

                const { result } = label

                if (!this.session.hasAnswer && (result === 1 || result === 2)) {
                  this.failStatus =
                    result === 1 ? statuses.unreachable : statuses.unassigned

                  console.log(
                    result,
                    result === 1 ? 'unreachable' : 'unassigned'
                  )

                  this.session.cancel()
                }
              }
            })
          })
        }
      }
      this.mediaRecorder.start(7000)
    }

    this.remoteAudio.srcObject = remoteStream
    this.remoteAudio.play().catch(() => {
      console.log('play was rejected')
    })
  }

  get lifter() {
    return this.props.lifter
  }

  getFilteredCalls = () => {
    const { ident, result, status, date } = FormHelper.getValues(this.lifter, [
      'ident',
      'result',
      'status',
      'date',
    ])

    const mdate = parseDate(date)
    const idents =
      ident &&
      ident
        .split('|')
        .map((id) => id.trim())
        .filter((id) => id)

    const filteredCalls = this.calls.filter((c) => {
      return (
        (!ident || idents.some((id) => c.identifier.includes(id))) &&
        (!result ||
          (result === '200.100' &&
            c.result === 200 &&
            c.reviewStatus === 100) ||
          c.result === result) &&
        (!status || c.status === status) &&
        (!mdate.isValid() || mdate.isSame(parseDate(c.generatedAt, false)))
      )
    })

    return filteredCalls
  }

  filterCalls = () => {
    this.setState({ calls: this.getFilteredCalls() })
  }

  loadProject = (projectId) => {
    this.setState({ preview: false, loading: true })

    return Promise.all([
      Config.get(projectId),
      Config.getCall(projectId),
      Call.getAll(projectId),
    ])
      .then(([config, callConfig, calls]) => {
        this.calls = calls
        this.setState({
          config,
          callConfig,
          calls: this.getFilteredCalls(),
          fieldValues1:
            config.hasField1 &&
            config.fieldValues
              .filter((fv) => fv.field === 1)
              .map((fv) => ({
                value: fv.fieldValueId,
                label: fv.fieldValueName,
              })),

          fieldValues2:
            config.hasField2 &&
            config.fieldValues
              .filter((fv) => fv.field === 2)
              .map((fv) => ({
                value: fv.fieldValueId,
                label: fv.fieldValueName,
              })),

          fieldValues3:
            config.hasField3 &&
            config.fieldValues
              .filter((fv) => fv.field === 3)
              .map((fv) => ({
                value: fv.fieldValueId,
                label: fv.fieldValueName,
              })),

          fieldValues4:
            config.hasField4 &&
            config.fieldValues
              .filter((fv) => fv.field === 4)
              .map((fv) => ({
                value: fv.fieldValueId,
                label: fv.fieldValueName,
              })),

          fieldValues5:
            config.hasField5 &&
            config.fieldValues
              .filter((fv) => fv.field === 5)
              .map((fv) => ({
                value: fv.fieldValueId,
                label: fv.fieldValueName,
              })),
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleProjectChanged = (name, { value }) => {
    this.loadProject(value)
  }

  get projectId() {
    return this.getValue('projectId')
  }

  getValue(field) {
    return FormHelper.getValue(this.props.lifter, field)
  }

  getValues(fields) {
    return FormHelper.getValues(this.props.lifter, fields)
  }

  fetchCalls = async () => {
    this.setState({ callsFetching: true })
    try {
      this.calls = await Call.getAll(this.projectId)
      this.filterCalls()
    } finally {
      this.setState({ callsFetching: false })
    }
  }

  clear = () => {
    FormHelper.setValues(
      this.props.lifter,
      [
        'identifier',
        'phoneNumber',
        'field1ValueId',
        'field2ValueId',
        'field3ValueId',
        'field4ValueId',
        'field5ValueId',
        'comment',
        'callLaterDate',
        'callLaterHour',
        'callLaterMinute',
      ],
      {}
    )

    FormHelper.setOptions(this.props.lifter, {
      field1ValueId: undefined,
      field2ValueId: undefined,
      field3ValueId: undefined,
      field4ValueId: undefined,
      field5ValueId: undefined,
    })

    delete this.call

    this.setState({
      stage: stages.ready,
      canceled: false,
      callLater: false,
      completionUrl: '',
      call: undefined,
      hasAnswered: false,
    })
    this.clearStopwatch()
  }

  canceled = false

  handleCall = async (callId) => {
    this.setState({
      preview: false,
      canceled: false,
      callLater: false,
      completionUrl: '',
      surveyEnlarged: false,
    })

    if (this.state.autoTimer) {
      clearTimeout(this.state.autoTimer)
      this.setState({ autoTimer: undefined })

      return
    }

    if (this.calling) {
      this.session.cancel()
      this.canceled = true
      return
    } else if (this.talking) {
      this.session.bye()
      return
    }

    this.setState({ meetQuotas: undefined })

    if (!this.ensureRegistered()) return

    const areOrgs = this.state.config.surveyType === surveyTypes.organizations
    let isGenerated = false

    if (this.ended || this.failed) {
      this.recall = true
    } else {
      this.recall = false

      const projectId = this.getValue('projectId')

      if (!areOrgs || callId || !this.call) {
        let call

        this.setState({ loading: true })
        try {
          if (callId) {
            call = await Call.getOne(projectId, callId)
          } else if (this.state.config.tokenManualInput) {
            call = FormHelper.getValues(this.lifter, ['phoneNumber'])
            call = await Call.create(projectId, call)
          } else {
            call = await Call.generate(projectId)
          }
        } finally {
          this.setState({ loading: false })
        }

        if (call.identifier) {
          isGenerated = true
          this.call = call

          this.setState({ call })

          FormHelper.setValues(
            this.props.lifter,
            [
              'identifier',
              'phoneNumber',
              'field1ValueId',
              'field2ValueId',
              'field3ValueId',
              'field4ValueId',
              'field5ValueId',
              'comment',
            ],
            call
          )
        }
      }
    }

    if (!areOrgs || (!callId && !isGenerated)) {
      const phoneNumber = FormHelper.getValue(this.lifter, 'phoneNumber')
      this._call(phoneNumber)
    }
  }

  handleAnswer = () => {
    this.session.accept({
      //extraHeaders: [`X-Call-Id: ${this.call.callId}`],
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,
        },
      },
    })
  }

  handleReject = () => {
    this.session.reject()
  }

  handleReadyToReceive = () => {
    this.setState({ readyToReceive: !this.state.readyToReceive })
  }

  get ended() {
    return this.state.stage === stages.ended
  }

  get failed() {
    return this.state.stage === stages.failed
  }

  get ready() {
    return this.state.stage === stages.ready
  }

  get talking() {
    return this.state.stage === stages.talking
  }

  get calling() {
    return this.state.stage === stages.calling
  }

  handleSuccess = ({ value }) => {
    this.update({ result: results.success, reviewStatus: value }).then(() =>
      this.clear()
    )
  }

  handleBadCall = ({ value }) => {
    this.update({ result: results.badCall, reasonId: value }).then(() =>
      this.clear()
    )
  }

  handleStop = ({ value }) => {
    this.update({ result: results.stopped, reasonId: value }).then(() =>
      this.clear()
    )
  }

  handleNoAnswer = () => {
    this.update({ status: statuses.noAnswer }).then(() => this.clear())
  }

  handleUnreachable = () => {
    this.update({ status: statuses.unreachable }).then(() => this.clear())
  }

  handleUnassigned = () => {
    this.update({ status: statuses.unassigned }).then(() => this.clear())
  }

  callLaterFields = ['callLaterDate', 'callLaterHour', 'callLaterMinute']

  handleCallLater = () => {
    if (!this.state.callLater) {
      this.setState({ callLater: true })
    } else {
      const { lifter } = this.props

      FormHelper.setTouched(lifter, this.callLaterFields, true)

      if (FormHelper.isInvalid(lifter, this.callLaterFields)) {
        return
      }

      const values = FormHelper.getValues(lifter, this.callLaterFields)

      this.update({
        result: results.callLater,
        callLaterAt: `${values.callLaterDate} ${values.callLaterHour}:${values.callLaterMinute}`,
      }).then(() => this.clear())
    }
  }

  toggleCalls = () => {
    this.setState((state) => ({
      callsVisible: state.surveyVisible
        ? state.callsVisible
        : !state.callsVisible,
      surveyVisible: false,
    }))
  }

  getCellClassName = (column, row) => {
    return row.result === results.callLater ? 'call-later' : ''
  }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      { name: 'identifier', title: trans.ident },
      { name: 'phoneNumber', title: trans.number },
      {
        name: 'generatedAt',
        title: trans.dateTime,
        sortColumn: 'generatedAtTs',
      },
      {
        name: 'result',
        title: trans.result,
        formatter: (value, { reviewStatus }) => {
          return this.props.trans[
            `result.${value}${
              value === 200 && reviewStatus === 100 ? '.100' : ''
            }`
          ]
        },
      },
      {
        name: 'status',
        title: trans.status,
        formatter: (value) => {
          return this.props.trans['status.' + value]
        },
      },
      {
        name: 'talkTime',
        title: trans.talkTime,
        formatter: (_, { talkMinutes, talkSeconds }) => {
          let time = ''

          if (talkMinutes) {
            time =
              talkMinutes.toString() +
              ' ' +
              (talkMinutes === 1
                ? this.props.trans.minute
                : this.props.trans.minutes)
          }

          if (talkSeconds) {
            time +=
              (talkMinutes ? ` ${this.props.trans.and} ` : '') +
              talkSeconds +
              ' ' +
              (talkSeconds === 1
                ? this.props.trans.second
                : this.props.trans.seconds)
          }

          return time
        },
      },
      { name: 'reason', title: trans.reason },
      {
        name: 'callLaterAt',
        title: trans.callLaterTime,
        sortColumn: 'callLaterAtTs',
      },
      {
        name: 'comment',
        title: trans.comments,
      },
      {
        name: 'remarks',
        title: trans.remarks,
      },
    ]

    return this.memoizedColumns
  }

  getContextMenuItems = (call) => {
    const items = [
      {
        title: this.props.trans.listenRecordings,
        action: () => {
          this.loadLogs(call.callId)
        },
      },
      this.state.callConfig.questionnaireReviewable &&
      (call.result === results.callLater || call.result === results.success)
        ? {
            title: this.props.trans.questionnaire,
            action: () => {
              this.loadSurvey(call)
            },
          }
        : false,
      this.state.callConfig.questionnaireReviewable &&
      (call.result === results.callLater || call.result === results.success)
        ? {
            title: this.props.trans.questionnaireListen,
            action: () => {
              this.loadSurvey(call)
              this.loadLogs(call.callId)
            },
          }
        : false,
      {
        title: this.props.trans.comment,
        action: () => {
          this.openComment(call)
        },
      },
    ].filter(Boolean)

    if (
      !this.calling &&
      !this.talking &&
      (this.state.callConfig.alwaysCallable ||
        call.result === results.callLater)
    ) {
      items.push({
        title: this.props.trans.redial,
        action: () => {
          this.handleCall(call.callId)
          this.loadSurvey(call)
        },
      })
    }

    return items
  }

  loadSurvey = (call) => {
    this.setState({ loading: true })
    Call.getCompletionUrl(this.projectId, call.callId)
      .then((url) => this.setState({ completionUrl: url }))
      .finally(() => {
        this.setState({ loading: false, surveyVisible: true })
      })
  }

  openComment = (call) => {
    this.commentModal.openModal({
      projectId: this.projectId,
      callId: call.callId,
    })
  }

  loadLogs = (callId) => {
    Call.getLogs(this.projectId, callId).then((callLogs) => {
      this.setState({
        callLogs: callLogs.map((log, index) => ({
          ...log,
          title: log.started,
        })),
        callLog: callLogs.length && callLogs[0],
        callId,
      })
    })
  }

  handlePreview = () => {
    this.setState({ preview: true })
  }

  handleLogSelect = (callLog) => {
    this.setState({ callLog })
  }

  handleRowSelected = () => {
    this.setState({ callLog: undefined, callId: undefined })
  }

  handleChanged = () => {
    this.filterCalls()
  }

  handleMute = () => {
    this.setState(
      ({ muted }) => ({ muted: !muted }),
      () => {
        var pc = this.session.sessionDescriptionHandler.peerConnection
        pc.getLocalStreams().forEach((stream) => {
          stream.getAudioTracks().forEach((track) => {
            track.enabled = !this.state.muted
          })
        })
      }
    )
  }

  handleFieldValueChanged = async () => {
    const {
      field1ValueId,
      field2ValueId,
      field3ValueId,
      field4ValueId,
      field5ValueId,
    } = FormHelper.getValues(this.lifter, [
      'field1ValueId',
      'field2ValueId',
      'field3ValueId',
      'field4ValueId',
      'field5ValueId',
    ])

    this.setState({ loading: true })

    try {
      const meetQuotas = await Quota.check(
        this.projectId,
        this.call.callId,
        field1ValueId,
        field2ValueId,
        field3ValueId,
        field4ValueId,
        field5ValueId
      )

      this.setState({ meetQuotas })
    } finally {
      this.setState({ loading: false })
    }
  }

  handleCallClick = () => {
    this.handleCall()
  }

  handleDateChanged = () => {}

  handleEnlarge = () => {
    this.setState(({ surveyEnlarged }) => ({ surveyEnlarged: !surveyEnlarged }))
  }

  getReviewItems = () => [
    { value: 100, title: this.props.trans.needsReview },
    { value: 200, title: this.props.trans.completed },
  ]

  renderRecording = (isMobile = false) => (
    <div className={`flex-column gap-${isMobile ? 'top' : 'bottom'}`}>
      <div className="flex-row-center gap-bottom">
        {this.state.callLogs && this.state.callLogs.length > 1 && (
          <DropdownButton
            className="gap-right"
            items={this.state.callLogs}
            onSelect={this.handleLogSelect}
          >
            ზარები ({this.state.callLogs.length})
          </DropdownButton>
        )}

        <audio
          className="flex-fill height"
          controls
          controlsList="nodownload"
          autoPlay
          src={`api/calls/${this.projectId}/${this.state.callId}/logs/${this.state.callLog.id}/recording`}
        />
      </div>

      <div className="flex-row-center-space">
        <div>
          {DateHelper.formatTime(
            DateHelper.parseDateTime(this.state.callLog.started)
          )}
        </div>
        <div>{this.state.callLog.callSeconds} წამი</div>
        <div>
          {DateHelper.formatTime(
            DateHelper.parseDateTime(this.state.callLog.answered)
          )}
        </div>
        <div>{this.state.callLog.talkSeconds} წამი</div>
        <div>
          {DateHelper.formatTime(
            DateHelper.parseDateTime(this.state.callLog.ended)
          )}
        </div>
      </div>
    </div>
  )

  render() {
    const {
      fetching,
      projects,
      config,
      calls,
      loading,
      registered,
      offline,
      preview,
      completionUrl,
      call,
      callsFetching,
      callsVisible,
      surveyEnlarged,
      surveyVisible,
      hasAnswered,
      ringing,
    } = this.state

    const { isPhoneNumberEmpty, trans } = this.props

    if (fetching) return <Loader />

    const areOrgs = config && config.surveyType === surveyTypes.organizations

    return (
      <MainContainer>
        <CallComment
          ref={(ref) => (this.commentModal = ref)}
          onSuccess={this.fetchCalls}
        />

        {loading && <Loader />}

        <video
          id="remoteAudio"
          css="display: none"
          ref={(ref) => (this.remoteAudio = ref)}
        ></video>

        <audio
          id="ringtone"
          css="display: none"
          ref={(ref) => (this.ringtone = ref)}
          loop
          src={`${process.env.PUBLIC_URL}/ring.mp3`}
        />

        <ProjectCallContainer
          className={callsVisible && !surveyVisible && 'no-flex'}
        >
          {/* <div id="waveform" css="height: 200px; width: 240px"></div> */}

          <div className="flex-row-center gap-bottom">
            <MobileQuery>
              <NavbarContext.Consumer>
                {({ toggleNavbar }) => (
                  <Button
                    css="padding: 3px"
                    className="width gap-right"
                    onClick={toggleNavbar}
                  >
                    <ListIcon className="width-100 height-100" />
                  </Button>
                )}
              </NavbarContext.Consumer>
            </MobileQuery>

            <Select
              required
              name="projectId"
              initialValue={projects[0] && projects[0].value}
              options={projects}
              onChanged={this.handleProjectChanged}
              placeholder="აირჩიეთ პროექტი"
              isDisabled={!this.ready || fetching || loading}
              className="flex-fill"
            />

            <Button
              css="padding: 3px"
              className="width gap-left"
              onClick={this.toggleCalls}
            >
              <ListIcon className="width-100 height-100" />
            </Button>
          </div>

          <DesktopQuery>
            {(isDesktop) =>
              (isDesktop || !callsVisible || surveyVisible) && (
                <CallContainer>
                  {(!surveyEnlarged || !completionUrl) && (
                    <Form>
                      <div className="flex-row">
                        <div className="relative flex-fill gap-right flex-row">
                          {(this.calling || this.talking) && (
                            <div
                              className="absolute"
                              css="right: 9px; top: 9px;"
                            >
                              {this.state.stopwatchTime}
                            </div>
                          )}

                          <Input
                            className="width-100"
                            required
                            disabled={
                              (areOrgs &&
                                (!this.state.call ||
                                  this.calling ||
                                  this.talking)) ||
                              (!areOrgs &&
                                ((config && !config.tokenManualInput) ||
                                  !!this.state.call ||
                                  !this.ready))
                            }
                            name="phoneNumber"
                            placeholder={trans.phoneNumber}
                          />
                        </div>

                        {this.talking && (
                          <Button
                            onClick={this.handleMute}
                            className="gap-half-right"
                            danger={this.state.muted}
                            css={
                              this.state.muted
                                ? 'padding: 5px 5px !important'
                                : undefined
                            }
                            title={
                              this.state.muted
                                ? 'უხმო რეჟიმის გამორთვა'
                                : 'უხმო რეჟიმის ჩართვა'
                            }
                          >
                            {this.state.muted ? (
                              <MicrophoneSlashIcon css="width: 26px; height: 24px" />
                            ) : (
                              <MicrophoneIcon css="width: 16px; height: 24px" />
                            )}
                          </Button>
                        )}

                        {!ringing &&
                          (config.callDirection !== callDirection.incoming ||
                            this.calling ||
                            this.talking) && (
                            <Button
                              disabled={
                                fetching ||
                                loading ||
                                !registered ||
                                offline ||
                                (((config && config.tokenManualInput) ||
                                  (areOrgs && call)) &&
                                  isPhoneNumberEmpty) ||
                                (!areOrgs && (this.ended || this.failed))
                              }
                              css="padding: 5px"
                              danger={
                                this.calling || this.talking || !registered
                              }
                              onClick={this.handleCallClick}
                              title={
                                (offline && trans.noInternet) ||
                                ((!this.sipConfig ||
                                  !this.sipConfig.username) &&
                                  trans.sipNoUser) ||
                                (!registered && trans.sipOffline) ||
                                ((this.ended || this.failed) &&
                                  (areOrgs ? trans.callAgain : ' ')) ||
                                trans.generateAndCall
                              }
                            >
                              {areOrgs && (this.ended || this.failed) ? (
                                <RecallIcon css="width: 26px; height: 24px" />
                              ) : this.calling || this.talking ? (
                                <CallingIcon css="width: 26px; height: 24px" />
                              ) : this.state.autoTimer ? (
                                <StopIcon css="margin-left: 4px; margin-right: 4px; margin-bottom: 1px; width: 18px; height: 16px" />
                              ) : areOrgs && !call ? (
                                <GenerateIcon css="width: 26px; height: 24px" />
                              ) : !registered ||
                                offline ||
                                !this.sipConfig ||
                                !this.sipConfig.username ? (
                                <DisconnectedIcon css="width: 26px; height: 24px" />
                              ) : (
                                <CallIcon css="width: 26px; height: 24px" />
                              )}
                            </Button>
                          )}

                        {!ringing &&
                          config.callDirection !== callDirection.outgoing &&
                          !this.calling &&
                          !this.talking && (
                            <Button
                              disabled={
                                fetching ||
                                loading ||
                                !registered ||
                                offline ||
                                !this.ready
                              }
                              onClick={this.handleReadyToReceive}
                              className={
                                config.callDirection === callDirection.bothways
                                  ? 'gap-half-left'
                                  : undefined
                              }
                              title={
                                this.state.readyToReceive
                                  ? 'მზადყოფნის დაპაუზება'
                                  : 'მზადყოფნა ზარის მისაღებად'
                              }
                              css="padding: 5px"
                              danger={this.state.readyToReceive}
                            >
                              {this.state.readyToReceive ? (
                                <PauseIcon css="width: 26px; height: 20px" />
                              ) : (
                                <PlayIcon css="width: 26px; height: 20px" />
                              )}
                            </Button>
                          )}

                        {ringing && (
                          <>
                            <Button
                              onClick={this.handleAnswer}
                              className="gap-half-right"
                              title="ყურმილის აღება"
                              css="padding: 5px"
                            >
                              <CallIcon
                                css="width: 26px; height: 24px"
                                className="shake"
                              />
                            </Button>

                            <Button
                              onClick={this.handleReject}
                              title="გათიშვა"
                              css="padding: 5px"
                              danger
                            >
                              <CancelIcon css="width: 26px; height: 20px" />
                            </Button>
                          </>
                        )}
                      </div>

                      {(offline ||
                        !registered ||
                        !this.sipConfig ||
                        !this.sipConfig.username) && (
                        <div className="text-error">
                          {(offline && trans.noInternet) ||
                            ((!this.sipConfig || !this.sipConfig.username) &&
                              trans.sipNoUser) ||
                            (!registered && trans.sipOffline)}
                        </div>
                      )}

                      {areOrgs && call && (
                        <div>
                          {trans.identifier}: {call && call.identifier}
                        </div>
                      )}

                      {call && call.description && (
                        <TextBox className="word-break-all">
                          {call.description}
                        </TextBox>
                      )}

                      {(!surveyEnlarged || !completionUrl) && (
                        <Input
                          placeholder={trans.comments}
                          name="comment"
                          disabled={
                            (areOrgs && !call) ||
                            (!areOrgs && !this.talking && !this.ended)
                          }
                          textArea
                          css="height: 58px"
                        />
                      )}

                      {!surveyEnlarged && (
                        <>
                          {config && config.hasField1 && (
                            <>
                              <Select
                                name="field1ValueId"
                                options={this.state.fieldValues1}
                                placeholder={config.fieldName1}
                                onChanged={this.handleFieldValueChanged}
                                isDisabled={
                                  !config.fieldsEditable ||
                                  (!this.talking && !this.ended)
                                }
                              />
                            </>
                          )}

                          {config && config.hasField2 && (
                            <>
                              <Select
                                name="field2ValueId"
                                options={this.state.fieldValues2}
                                placeholder={config.fieldName2}
                                onChanged={this.handleFieldValueChanged}
                                isDisabled={
                                  !config.fieldsEditable ||
                                  (!this.talking && !this.ended)
                                }
                              />
                            </>
                          )}

                          {config && config.hasField3 && (
                            <>
                              <Select
                                name="field3ValueId"
                                options={this.state.fieldValues3}
                                placeholder={config.fieldName3}
                                onChanged={this.handleFieldValueChanged}
                                isDisabled={
                                  !config.fieldsEditable ||
                                  (!this.talking && !this.ended)
                                }
                              />
                            </>
                          )}

                          {config && config.hasField4 && (
                            <>
                              <Select
                                name="field4ValueId"
                                options={this.state.fieldValues4}
                                placeholder={config.fieldName4}
                                onChanged={this.handleFieldValueChanged}
                                isDisabled={
                                  !config.fieldsEditable ||
                                  (!this.talking && !this.ended)
                                }
                              />
                            </>
                          )}

                          {config && config.hasField5 && (
                            <>
                              <Select
                                name="field5ValueId"
                                options={this.state.fieldValues5}
                                placeholder={config.fieldName5}
                                onChanged={this.handleFieldValueChanged}
                                isDisabled={
                                  !config.fieldsEditable ||
                                  (!this.talking && !this.ended)
                                }
                              />
                            </>
                          )}
                        </>
                      )}

                      {this.state.meetQuotas === true ? (
                        <span css="color: green">ქვოტებს აკმაყოფილებს</span>
                      ) : this.state.meetQuotas === false ? (
                        <span css="color: red">ქვოტებს არ აკმყოფილებს</span>
                      ) : undefined}

                      {(this.ended || this.failed) && !surveyEnlarged && (
                        <>
                          {hasAnswered && (
                            <div className="flex-row">
                              <DropdownButton
                                items={this.getReviewItems()}
                                onSelect={this.handleSuccess}
                                className="gap-right flex-fill"
                                disabled={!this.ended && !this.failed}
                              >
                                {trans.succeeded}
                              </DropdownButton>

                              <DropdownButton
                                items={this.state.badCallReasons}
                                onSelect={this.handleBadCall}
                                className="gap-right flex-fill"
                                disabled={!this.ended && !this.failed}
                              >
                                {trans.badCall}
                              </DropdownButton>

                              <DropdownButton
                                className="flex-fill"
                                items={this.state.stopReasons}
                                disabled={!this.ended && !this.failed}
                                onSelect={this.handleStop}
                              >
                                {trans.stopped}
                              </DropdownButton>
                            </div>
                          )}

                          {this.state.callLater ? (
                            <div className="flex-row-center">
                              <DateInput
                                placeholder={trans.date}
                                className="width-60 gap-right"
                                name="callLaterDate"
                                required
                                upward
                                readOnly={!isDesktop}
                              />
                              <Select
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                placeholder={trans.hour}
                                className="width-25 gap-half-right"
                                name="callLaterHour"
                                options={hours}
                                required
                              />
                              :
                              <Select
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                placeholder={trans.minute}
                                className="width-25 gap-half-left gap-right"
                                name="callLaterMinute"
                                options={minutes}
                                required
                              />
                              <Button
                                onClick={this.handleCallLater}
                                css="padding: 5px 7px"
                              >
                                <CheckIcon />
                              </Button>
                            </div>
                          ) : (
                            <>
                              <Button
                                disabled={!this.ended && !this.failed}
                                onClick={this.handleCallLater}
                                className="flex-fill"
                                light
                              >
                                {trans.callLater}
                              </Button>
                            </>
                          )}

                          {this.state.stage === stages.failed && (
                            <>
                              <div className="flex-row-center flex-fill">
                                <Button
                                  light
                                  onClick={this.handleNoAnswer}
                                  className="flex-fill gap-half-right"
                                >
                                  {trans.noAnswer}
                                </Button>

                                <Button
                                  light
                                  onClick={this.handleUnreachable}
                                  className="flex-fill gap-half-left"
                                >
                                  {trans.unreachable}
                                </Button>
                              </div>

                              <div className="flex-row-center flex-fill">
                                <Button
                                  light
                                  onClick={this.handleUnassigned}
                                  className="flex-fill"
                                >
                                  {trans.unassigned}
                                </Button>
                              </div>
                            </>
                          )}

                          {!areOrgs && (this.ended || this.failed) && (
                            <Button
                              onClick={this.handleCallClick}
                              className="flex-fill"
                              title={trans.callAgain}
                            >
                              <RecallIcon
                                css="width: 26px; height: 24px;"
                                className="gap-half-right"
                              />
                              {trans.callAgain}
                            </Button>
                          )}
                        </>
                      )}
                    </Form>
                  )}
                  <MobileQuery>
                    {this.state.callLog ? this.renderRecording(true) : null}
                  </MobileQuery>

                  <SurveyContainer>
                    <MobileQuery>
                      <Button
                        onClick={this.handleEnlarge}
                        className="width absolute gap-top gap-right right-0 opaque-5"
                      >
                        {surveyEnlarged ? (
                          <ArrowDownIcon className="width-100 height-100" />
                        ) : (
                          <ArrowUpIcon className="width-100 height-100" />
                        )}
                      </Button>
                    </MobileQuery>
                    {config &&
                    config.surveyUrl &&
                    (hasAnswered || preview || completionUrl) ? (
                      <iframe
                        className="flex-fill"
                        css="border: 0"
                        title="კითხვარი იტვირთება..."
                        src={
                          completionUrl ||
                          config.surveyUrl +
                            (config.surveyMethod === surveyMethods.questioPro
                              ? '?ext_ref='
                              : '') +
                            (call
                              ? call.identifier
                              : 'preview-' +
                                Math.floor(
                                  Math.random() * Number.MAX_SAFE_INTEGER
                                )) +
                            (config.surveyMethod === surveyMethods.questioPro
                              ? '&email=' +
                                (call
                                  ? call.identifier
                                  : 'preview-' +
                                    Math.floor(
                                      Math.random() * Number.MAX_SAFE_INTEGER
                                    ))
                              : '') +
                            (call && call.identifier
                              ? (config.surveyMethod ===
                                surveyMethods.questioPro
                                  ? '&custom1='
                                  : '&ExtRefNum=') + call.createdBy
                              : '')
                        }
                      />
                    ) : (
                      <div className="flex-fill flex-row-center justify-center">
                        <Button
                          disabled={fetching || loading}
                          onClick={this.handlePreview}
                        >
                          {trans.previewQuestionnaire}
                        </Button>
                      </div>
                    )}
                  </SurveyContainer>
                </CallContainer>
              )
            }
          </DesktopQuery>
        </ProjectCallContainer>

        <MobileQuery>
          {(isMobile) =>
            callsVisible &&
            (!isMobile || !surveyVisible) && (
              <CallsContainer>
                <Filter className="gap-bottom">
                  <div className="first-two">
                    <DateInput
                      placeholder={trans.date}
                      className="gap-right"
                      css="width: 100px; min-width: 100px; max-width: 100px;"
                      name="date"
                      onChanged={this.handleChanged}
                    />

                    <Input
                      name="ident"
                      className="flex-fill width-100"
                      placeholder={trans.numberSearch}
                      onChanged={this.handleChanged}
                      disabled={fetching || loading}
                    />

                    <div className="gap-left">{this.state.calls.length}</div>
                  </div>

                  <div className="flex-fill flex-row-center">
                    <Select
                      name="result"
                      className="flex-fill gap-right"
                      placeholder={trans.result}
                      options={this.state.results}
                      onChanged={this.handleChanged}
                      isDisabled={fetching || loading}
                      isClearable
                    />

                    <Select
                      name="status"
                      placeholder={trans.status}
                      className="flex-fill"
                      options={this.state.statuses}
                      onChanged={this.handleChanged}
                      isClearable
                      isDisabled={fetching || loading}
                    />
                  </div>
                </Filter>

                {this.state.callLog && this.renderRecording()}

                <DataTable
                  rows={calls}
                  columns={this.columns}
                  getContextMenuItems={this.getContextMenuItems}
                  onRowSelected={this.handleRowSelected}
                  cellClassName={this.getCellClassName}
                  loading={callsFetching}
                  excelExport={false}
                />
              </CallsContainer>
            )
          }
        </MobileQuery>
      </MainContainer>
    )
  }
}

export default withTranslations('CallForm')(
  withStateLifter((props) => {
    const projectOption = FormHelper.getOption(props.lifter, 'projectId')
    return (
      <CallForm
        {...props}
        isPhoneNumberEmpty={!FormHelper.getValue(props.lifter, 'phoneNumber')}
        projectName={projectOption && projectOption.label}
      />
    )
  })
)
