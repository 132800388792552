import React, { useCallback, useState } from 'react'
import { Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import { Role } from '../../api'

const RoleDelete = withModalManager(({ roleId, onSuccess, closeModal }) => {
  const [deleting, setDeleting] = useState(false)

  const handleDelete = useCallback(() => {
    setDeleting(true)

    Role.remove(roleId)
      .then(() => {
        closeModal()
        if (onSuccess) onSuccess()
      })
      .finally(() => setDeleting(false))
  }, [roleId, onSuccess, closeModal])

  return (
    <Modal
      header="როლის წაშლა"
      className="pad bgcolor"
      closeModal={closeModal}
      footer={
        <div css="text-align: right">
          <Button
            disabled={deleting}
            className="gap-right"
            onClick={handleDelete}
          >
            დიახ
          </Button>
          <Button disabled={deleting} onClick={closeModal}>
            გაუქმება
          </Button>
        </div>
      }
    >
      ნამდვილად გსურთ როლის წაშლა?
    </Modal>
  )
})

export default RoleDelete
