import styled from 'styled-components/macro'

const Container = styled.div`
  user-select: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  //border: 1px solid #c6c8ca;
  background-color: white;
  //position: relative;
  position: relative;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   width: 10px;
  //   background-color: red;
  //   top: 0px;
  //   bottom: 0px;
  //   right: 0px;
  // }

  .arrow-key-stepper {
    flex: 1;
    height: 100%;
  }

  .row-header {
    background-color: #e9ecef;
    border: 1px solid #c6c8ca;
    border-bottom: none;
  }

  .column-header {
    background-color: #e9ecef;
    border: 1px solid #c6c8ca;
    border-right: none;
  }

  .grid-body {
    outline: 0;
    border: 1px solid #c6c8ca;
  }

  .cell {
    line-height: 33px;
    padding: 0px 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    outline: 0;

    &-edit {
      padding: 0px;
    }

    &-error {
      background-color: #f2dede !important;
    }
  }

  .cell > input {
    outline: 0;
    width: 100%;
    border 0px none;
    line-height: 33px;
    height: 33px;
    padding: 0px 9px;

    &.error {
      background-color: #f2dede;
    }
  }

  .header-cell {
    border-top: 1px solid #c6c8ca;
    border-left: 1px solid #c6c8ca;
    background-color: #e9ecef;
  }

  .row-header-cell {
    position: relative;
    margin: 0;
    border-right: 1px solid #c6c8ca;
  }

  .column-header-cell {
    position: relative;
    margin: 0;
    border-bottom: 1px solid #c6c8ca;
    max-width: ${props => props.rowColumnMaxWidth};
  }

  .data-cell {
    border-right: 1px dotted #c6c8ca;
    border-bottom: 1px dotted #c6c8ca;

    &.selected-row, &.selected-column {
      background-color: rgba(0, 0, 0, 0.075);
    }

    &.selected-row.selected-column {
        background-color: rgba(0, 0, 0, 0.11);
        box-shadow: -1px -1px 0 0 #c6c8ca;
        border-right: 1px solid #c6c8ca;
        border-bottom: 1px solid #c6c8ca;
    }
  }

  .totals-cell {
    border-right: 1px dotted #c6c8ca;
    border-bottom: 1px dotted #c6c8ca;
    background-color: #e9ecef;
  }

  .resize-handle {
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    cursor: col-resize;
    height: 100%;
    border-right: 1px solid #c6c8ca;
    z-index: 1000;
  }
`

export default Container
