import React, { useCallback, useState } from 'react'
import { Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import { Call } from '../../api'
import styled from 'styled-components/macro'

const CallDelete = withModalManager(({ params: { projectId, callId }, onSuccess, closeModal }) => {
  const [deleting, setDeleting] = useState(false)

  const handleDelete = useCallback(() => {
    setDeleting(true)

    Call.remove(projectId, callId)
      .then(() => {
        closeModal()
        if (onSuccess) onSuccess()
      })
      .finally(() => setDeleting(false))
  }, [projectId, callId, onSuccess, closeModal])

  return (
    <Modal
      header="ჩანაწერის წაშლა"
      className="pad bgcolor"
      closeModal={closeModal}
      footer={
        <div css="text-align: right">
          <Button disabled={deleting} className="gap-right" onClick={handleDelete}>
            დიახ
          </Button>
          <Button disabled={deleting} onClick={closeModal}>
            გაუქმება
          </Button>
        </div>
      }
    >
      ნამდვილად გსურთ ჩანაწერის წაშლა?
    </Modal>
  )
})

export default CallDelete
