import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataTable from '../DataTable'
import { Project, Report } from '../../api'
import { withTranslations } from '../Translation'
import DataMatrix from '../DataMatrix'

class OperatorReport extends React.PureComponent {
  state = {
    stats: [],
    results: [],
    statuses: [],
    reasons: [],
    reasonColumns: [],
    reasonRows: [],
    projects: [],
    fetching: true,
    loading: false,
  }

  get lifter() {
    return this.props.lifter
  }

  get projectId() {
    return FormHelper.getValue(this.lifter, 'projectId')
  }

  componentDidMount() {
    Project.getAll()
      .then((projects) => {
        this.setState({
          projects: projects.map((p) => ({
            value: p.projectId,
            label: p.projectName,
          })),
        })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  handleProjectChanged = () => {
    this.loadReport()
  }

  loadReport() {
    this.setState({ loading: true })

    const operatorPromise = Report.operator(this.projectId).then((stats) => {
      const totalsRow = {
        fullName: 'სულ',
        succeeded: stats.reduce((sum, stat) => sum + stat.succeeded, 0),
        contacted: stats.reduce((sum, stat) => sum + stat.contacted, 0),
        total: stats.reduce((sum, stat) => sum + stat.total, 0),
        talkTimeAverage:
          stats.reduce((sum, stat) => sum + stat.talkTimeAverage, 0) /
          (stats.length || 1),
      }
      totalsRow.talkMinutes = Math.trunc(totalsRow.talkTimeAverage / 60)
      totalsRow.talkSeconds = Math.round(totalsRow.talkTimeAverage % 60)
      stats.push(totalsRow)
      this.setState({ stats })
    })

    const resultsPromise = Report.userResults(this.projectId).then(
      (results) => {
        results.find((x) => x.fullName === 'Total').fullName = 'სულ'

        this.setState({ results })
      }
    )

    const statusesPromise = Report.userStatuses(this.projectId).then(
      (statuses) => {
        statuses.find((x) => x.fullName === 'Total').fullName = 'სულ'

        this.setState({ statuses })
      }
    )

    const reasonsPromise = Report.userReasons(this.projectId).then(
      (reasons) => {
        const reasonRows = reasons
          .filter((reason, index, self) => {
            return (
              self.findIndex((value) => value.userId === reason.userId) ===
              index
            )
          })
          .map((x) => ({ value: x.userId, title: x.fullName }))

        const reasonColumns = reasons
          .filter((reason, index, self) => {
            return (
              self.findIndex((value) => value.reasonId === reason.reasonId) ===
              index
            )
          })
          .map((x) => ({ value: x.reasonId, title: x.reasonName }))

        this.setState({ reasons, reasonRows, reasonColumns })
      }
    )

    Promise.all([
      operatorPromise,
      resultsPromise,
      statusesPromise,
      reasonsPromise,
    ]).finally(() => {
      this.setState({ loading: false })
    })
  }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.operator,
        name: 'fullName',
      },
      {
        title: trans.succeeded,
        name: 'succeeded',
      },
      {
        title: trans.contacted,
        name: 'contacted',
      },
      {
        title: trans.totalNumber,
        name: 'total',
      },
      {
        title: trans.talkDurationAverage,
        name: 'talkTimeAverage',
        exportUnformatted: true,
        formatter: (_, { talkMinutes, talkSeconds }) => {
          let time = ''

          if (talkMinutes) {
            time =
              talkMinutes.toString() +
              ' ' +
              (talkMinutes === 1
                ? this.props.trans.minute
                : this.props.trans.minutes)
          }

          if (talkSeconds) {
            time +=
              (talkMinutes ? ` ${this.props.trans.and} ` : '') +
              talkSeconds +
              ' ' +
              (talkSeconds === 1
                ? this.props.trans.second
                : this.props.trans.seconds)
          }

          return time
        },
      },
    ]

    return this.memoizedColumns
  }

  get resultColumns() {
    const { trans, locale } = this.props

    if (
      locale === this.memoizedResultLocale &&
      trans === this.memoizedResultTrans
    )
      return this.memoizedResultColumns

    this.memoizedResultLocale = locale
    this.memoizedResultTrans = trans
    this.memoizedResultColumns = [
      {
        title: trans.operator,
        name: 'fullName',
      },
      {
        title: trans.pending,
        name: 'pending',
      },
      {
        title: trans.succeeded,
        name: 'success',
      },
      {
        title: trans.callLater,
        name: 'callLater',
      },
      {
        title: trans.badCall,
        name: 'badCall',
      },
      {
        title: trans.stopped,
        name: 'stopped',
      },
      {
        title: trans.failed,
        name: 'failed',
      },
      {
        title: trans.total,
        name: 'total',
      },
    ]

    return this.memoizedResultColumns
  }

  get statusColumns() {
    const { trans, locale } = this.props

    if (
      locale === this.memoizedStatusLocale &&
      trans === this.memoizedStatusTrans
    )
      return this.memoizedStatusColumns

    this.memoizedStatusLocale = locale
    this.memoizedStatusTrans = trans
    this.memoizedStatusColumns = [
      {
        title: trans.operator,
        name: 'fullName',
      },
      {
        title: trans.pending,
        name: 'pending',
      },
      {
        title: trans.calling,
        name: 'calling',
      },
      {
        title: trans.talking,
        name: 'talking',
      },
      {
        title: trans.ended,
        name: 'ended',
      },
      {
        title: trans.busy,
        name: 'busy',
      },
      {
        title: trans.noAnswer,
        name: 'noAnswer',
      },
      {
        title: trans.unreachable,
        name: 'unreachable',
      },
      {
        title: trans.unassigned,
        name: 'unassigned',
      },
      {
        title: trans.failedOther,
        name: 'failedOther',
      },
      {
        title: trans.total,
        name: 'total',
      },
    ]

    return this.memoizedStatusColumns
  }

  render() {
    const { fetching, loading } = this.state
    const { trans } = this.props

    return (
      <>
        <div className="flex-row-center gap-bottom">
          <Select
            name="projectId"
            className="flex-fill gap-right"
            options={this.state.projects}
            placeholder={trans.chooseProject}
            onChanged={this.handleProjectChanged}
            isDisabled={fetching || loading}
          />

          <Button
            disabled={!this.projectId || fetching || loading}
            onClick={this.loadReport}
          >
            {trans.refresh}
          </Button>
        </div>

        <div className="flex-column flex-fill">
          <div className="flex-row flex-fill gap-bottom">
            <div className="flex-column flex-fill gap-right">
              <DataTable rows={this.state.stats} columns={this.columns} />
            </div>

            <DataTable rows={this.state.results} columns={this.resultColumns} />
          </div>

          <div className="flex-row flex-fill">
            <div className="flex-column flex-fill gap-right">
              <DataTable
                rows={this.state.statuses}
                columns={this.statusColumns}
              />
            </div>

            <DataMatrix
              excelExport
              totalsRow
              totalsRowHeader={trans.total}
              totalsColumn
              totalsColumnHeader={trans.total}
              rows={this.state.reasonRows}
              columns={this.state.reasonColumns}
              header={''}
              data={this.state.reasons}
              rowKey="userId"
              columnKey="reasonId"
              valueKey="count"
            />
          </div>
        </div>
      </>
    )
  }
}

export default withTranslations('OperatorReport')(
  withStateLifter((props) => <OperatorReport {...props} />)
)
