export const getValues = (lifter, fields: string[]) => {
  const states = lifter.getStates(fields)

  return Object.assign(
    {},
    ...fields.map(name => ({
      [name]: states[name] && states[name].value
    }))
  )
}

export const getValue = (lifter, field: string) => {
  const state = lifter && lifter.getState && lifter.getState(field)

  return state && state.value
}

export const getOption = (lifter, field: string) => {
  const state = lifter && lifter.getState && lifter.getState(field)

  return state && state.option
}

export const isInvalid = (lifter, fields: string[]) => {
  const states = lifter.getStates(fields)

  return fields.some(name => (states[name] && states[name].invalid) || false)
}

export const isValid = (lifter, fields: string[]) => {
  const states = lifter.getStates(fields)

  return fields.every(name => (states[name] && !states[name].invalid) || true)
}

export const setTouched = (lifter, fields, touched: boolean) => {
  const state = { touched }

  lifter.setStates(
    Object.assign(
      {},
      ...fields.map(name => ({
        [name]: state
      }))
    )
  )
}

export const setValues = (lifter, fields: string[], values: object) => {
  lifter.setStates(
    Object.assign(
      {},
      ...fields.map(name => ({
        [name]: { value: values[name] }
      }))
    )
  )
}

export const setOptions = (lifter, options: object) => {
  lifter.setStates(
    Object.assign(
      {},
      ...Object.keys(options).map(name => ({
        [name]: { option: options[name] }
      }))
    )
  )
}

export const setValue = (lifter, field: string, value: any) => {
  setValues(lifter, [field], { [field]: value })
}

export const getOptions = (rows, valueKey, labelKey) => {
  return rows.map(r => ({
    value: r[valueKey],
    label: r[labelKey]
  }))
}
