import React from 'react'
import styled from 'styled-components/macro'
import withStateLifter from './StateLifter'
import { Input, Button } from './controls'
import { FormHelper } from './helpers'
import { maxWidth } from './MediaQuery'

const Form = styled.div`
  display: grid;
  grid-template-columns: 10% 20%;
  grid-gap: 12px;

  @media (max-width: ${maxWidth}) {
    grid-template-columns: 1fr 2fr;
  }

  & > label {
    display: flex;
    align-items: center;
  }
`

const fields = [
  /*'uri', 'password', 'wsServer',*/ 'spyPassword',
  'mockPhoneNumber',
]

class Config extends React.PureComponent {
  submit = () => {
    const { lifter } = this.props

    FormHelper.setTouched(lifter, fields, true)

    if (FormHelper.isInvalid(lifter, fields)) {
      return
    }

    const config = FormHelper.getValues(lifter, fields)

    // localStorage.setItem('sip.uri', config.uri)
    // localStorage.setItem('sip.password', config.password)
    // localStorage.setItem('sip.wsServer', config.wsServer)
    localStorage.setItem('sip.spyPassword', config.spyPassword)
    localStorage.setItem('sip.mockPhoneNumber', config.mockPhoneNumber)
  }

  render() {
    return (
      <Form>
        {/* <label>SIP Uri</label>
        <Input
          name="uri"
          placeholder="99500@devsip.diflex.ge"
          initialValue={localStorage.getItem('sip.uri')}
          required
        />

        <label>SIP Password</label>
        <Input
          name="password"
          password
          initialValue={localStorage.getItem('sip.password')}
          required
        />

        <label>WS Server</label>
        <Input
          name="wsServer"
          placeholder="wss://devsip.diflex.ge:8089/ws"
          initialValue={localStorage.getItem('sip.wsServer')}
          required
        /> */}

        <label>Spy Password</label>
        <Input
          name="spyPassword"
          initialValue={localStorage.getItem('sip.spyPassword')}
        />

        <label>Mock Phone Number</label>
        <Input
          name="mockPhoneNumber"
          initialValue={localStorage.getItem('sip.mockPhoneNumber')}
        />

        <div css="grid-column: 2">
          <Button onClick={this.submit}>შენახვა</Button>
        </div>
      </Form>
    )
  }
}

export default withStateLifter(Config)
