import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataTable from '../DataTable'
import { Project, Report, Config } from '../../api'
import { withTranslations } from '../Translation'

class TokenReport extends React.PureComponent {
  state = {
    totals: [],
    projects: [],
    byFields: [],
    columns: [],
    fetching: true,
    loading: false,
  }

  get lifter() {
    return this.props.lifter
  }

  get projectId() {
    return FormHelper.getValue(this.lifter, 'projectId')
  }

  componentDidMount() {
    Project.getAll()
      .then((projects) => {
        this.setState({
          projects: projects.map((p) => ({
            value: p.projectId,
            label: p.projectName,
          })),
        })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  handleProjectChanged = () => {
    this.loadReport()
  }

  loadReport = () => {
    this.setState({ loading: true })

    const { trans } = this.props

    Promise.all([
      Config.get(this.projectId).then((config) => {
        const columns = []

        if (config.hasField1) {
          columns.push({
            title: config.fieldName1,
            name: 'fieldValue1',
          })
        }

        if (config.hasField2) {
          columns.push({
            title: config.fieldName2,
            name: 'fieldValue2',
          })
        }

        if (config.hasField3) {
          columns.push({
            title: config.fieldName3,
            name: 'fieldValue3',
          })
        }

        if (config.hasField4) {
          columns.push({
            title: config.fieldName4,
            name: 'fieldValue4',
          })
        }

        if (config.hasField5) {
          columns.push({
            title: config.fieldName5,
            name: 'fieldValue5',
          })
        }

        columns.push({
          title: trans.total,
          name: 'total',
        })

        columns.push({
          title: trans.called,
          name: 'called',
        })

        columns.push({
          title: trans.left,
          name: 'left',
        })

        this.setState({ columns })
      }),
      Report.tokens(this.projectId).then((totals) => {
        this.setState({ totals })
      }),
      Report.tokensByFields(this.projectId).then((byFields) => {
        byFields.push({
          fieldValue1: trans.total,
          fieldValue2: trans.total,
          fieldValue3: trans.total,
          fieldValue4: trans.total,
          fieldValue5: trans.total,
          total: byFields.reduce((sum, row) => sum + row.total, 0),
          called: byFields.reduce((sum, row) => sum + row.called, 0),
          left: byFields.reduce((sum, row) => sum + row.left, 0),
        })
        this.setState({ byFields })
      }),
    ]).finally(() => {
      this.setState({ loading: false })
    })
  }

  render() {
    const { fetching, loading } = this.state

    const { trans } = this.props

    return (
      <>
        <div className="flex-row-center gap-bottom">
          <Select
            name="projectId"
            className="flex-fill gap-right"
            options={this.state.projects}
            placeholder={trans.chooseProject}
            onChanged={this.handleProjectChanged}
            isDisabled={fetching || loading}
          />

          <Button
            disabled={!this.projectId || fetching || loading}
            onClick={this.loadReport}
          >
            {trans.refresh}
          </Button>
        </div>

        <div className="flex-column flex-fill">
          <div className="gap-bottom">
            <div css="display: grid; grid-template-columns: max-content max-content; grid-gap: 12px;">
              <div>{trans.totalsTotal}:</div>
              <div>{this.state.totals.total}</div>

              <div>{trans.totalsUsedAll}:</div>
              <div>{this.state.totals.usedAll}</div>

              <div>{trans.totalsUsed}:</div>
              <div>{this.state.totals.used}</div>

              <div>{trans.totalsLeft}:</div>
              <div>{this.state.totals.left}</div>

              <div>{trans.totalsReusable}:</div>
              <div>{this.state.totals.reusable}</div>
            </div>
          </div>

          <div className="flex-column flex-fill gap-right">
            <DataTable
              rows={this.state.byFields}
              columns={this.state.columns}
              loading={fetching || loading}
            />
          </div>
        </div>
      </>
    )
  }
}

export default withTranslations('TokenReport')(
  withStateLifter((props) => <TokenReport {...props} />)
)
