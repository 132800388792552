import React, { useCallback, useState } from 'react'
import Navbar, { NavbarContext } from './Navbar'
import Sidebar from './Sidebar'
import styled from 'styled-components/macro'
import { useMobileQuery, maxWidth } from './MediaQuery'
import { withTranslations } from './Translation'
import ErrorModal, { errorModalRef } from './ErrorModal'
import InfoModal, { infoModalRef } from './InfoModal'
import ConfirmModal, { confirmModalRef } from './ConfirmModal'

const Container = styled.div`
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: ${maxWidth}) {
    flex-direction: row;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: whitesmoke;
  min-width: 300px;
  overflow: auto;
`

const Layout = ({ onSigningOut, children, trans }) => {
  const [navbarVisible, setNavbarVisible] = useState(false)

  const isMobile = useMobileQuery()

  const toggleNavbar = useCallback(() => {
    setNavbarVisible(!navbarVisible)
  }, [navbarVisible, setNavbarVisible])

  return (
    <>
      {/* {!sidebarHidden && <Sidebar trans={trans} />} */}

      <ErrorModal ref={errorModalRef} />
      <InfoModal ref={infoModalRef} />

      <ConfirmModal ref={confirmModalRef} />

      <Container>
        {(navbarVisible || !isMobile) && (
          <Navbar trans={trans} onSigningOut={onSigningOut} />
        )}
        <NavbarContext.Provider value={{ toggleNavbar }}>
          <Content>{children}</Content>
        </NavbarContext.Provider>
      </Container>
    </>
  )
}

export default withTranslations('Layout')(Layout)
