import React, { useCallback, useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import {
  Select,
  Input,
  DateInput,
  Button,
  DropdownButton,
  TextBox,
} from '../controls'
import Modal, { withModalManager } from '../Modal'
import { Config, Call, User } from '../../api'
import { hours, minutes, results } from './constants'
import { FormHelper, DateHelper, StringHelper } from '../helpers'
import withStateLifter from '../StateLifter'
import { withTranslations } from '../Translation'
import Loader from '../Loader'

const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  user-select: none;
  padding-left: 12px;
  margin-top: 12px;

  .tab {
    position: relative;
    padding: 8px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .tab-selected {
    border: 1px solid #ced4da;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: -1px;
    background-color: whitesmoke;
    cursor: auto;
    padding-top: 7px !important;
  }
`

const TabsBody = styled.div`
  flex: 1;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
`

const Form = styled.div`
  min-width: 265px;
  display: grid;
  grid-template-columns: 21% auto;
  grid-gap: 12px;
  grid-auto-rows: minmax(min-content, max-content);

  > label {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
  }
`

const FIELDS = [
  'identifier',
  'phoneNumber',
  'result',
  'status',
  'field1ValueId',
  'field2ValueId',
  'field3ValueId',
  'field4ValueId',
  'field5ValueId',
  'assignedToId',
  'comment',
  'remarks',
  'callLaterDate',
  'callLaterHour',
  'callLaterMinute',
  'reasonId',
]

const CallEdit = ({
  params: { title, projectId, callId },
  closeModal,
  lifter,
  onSuccess,
  isDefaultLocale,
  locale,
  trans,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const [state, setState] = useState({
    users: [],
    reasons: [],
    logs: [],
  })

  const [surveyUrl, setSurveyUrl] = useState()
  const [loading, setLoading] = useState()

  const result = FormHelper.getValue(lifter, 'result')
  const callLater = result === results.callLater
  const hasSurvey = result === results.success || result === results.callLater

  const resultOptions = React.useMemo(
    () => [
      { value: 100, label: trans['result.100'] },
      { value: 200, label: trans['result.200'] },
      { value: 300, label: trans['result.300'] },
      { value: 400, label: trans['result.400'] },
      { value: 500, label: trans['result.500'] },
      { value: 600, label: trans['result.600'] },
      { value: 900, label: trans['result.900'] },
    ],
    [trans]
  )

  const statusOptions = React.useMemo(
    () => [
      { value: 100, label: trans['status.100'] },
      { value: 110, label: trans['status.110'] },
      { value: 120, label: trans['status.120'] },
      { value: 200, label: trans['status.200'] },
      { value: 600, label: trans['status.600'] },
      { value: 610, label: trans['status.610'] },
      { value: 620, label: trans['status.620'] },
      { value: 650, label: trans['status.650'] },
      { value: 690, label: trans['status.690'] },
    ],
    [trans]
  )

  useEffect(() => {
    Promise.all([
      Config.get(projectId),
      Call.getOne(projectId, callId),
      User.getAll(),
      Call.getReasons(),
      Call.getLogs(projectId, callId),
    ]).then(([config, call, users, reasons, logs]) => {
      const callLaterAt =
        call.callLaterAt && DateHelper.parseDateTime(call.callLaterAt, false)

      FormHelper.setValues(lifter, FIELDS, {
        ...call,
        callLaterDate: callLaterAt && DateHelper.formatDate(callLaterAt),
        callLaterHour: callLaterAt && callLaterAt.format('HH'),
        callLaterMinute: callLaterAt && callLaterAt.format('mm'),
      })

      setState({
        config,
        call,
        logs: logs.map((log) => ({
          ...log,
          title: log.started,
        })),
        log: logs.length && logs[0],
        reasons: FormHelper.getOptions(
          reasons,
          'reasonId',
          'reasonName' +
            (isDefaultLocale ? '' : StringHelper.capitalize(locale))
        ),
        users: FormHelper.getOptions(users, 'userId', 'fullName'),
        fieldValues1:
          config.hasField1 &&
          config.fieldValues
            .filter((fv) => fv.field === 1)
            .map((fv) => ({
              value: fv.fieldValueId,
              label: fv.fieldValueName,
            })),

        fieldValues2:
          config.hasField2 &&
          config.fieldValues
            .filter((fv) => fv.field === 2)
            .map((fv) => ({
              value: fv.fieldValueId,
              label: fv.fieldValueName,
            })),

        fieldValues3:
          config.hasField3 &&
          config.fieldValues
            .filter((fv) => fv.field === 3)
            .map((fv) => ({
              value: fv.fieldValueId,
              label: fv.fieldValueName,
            })),

        fieldValues4:
          config.hasField4 &&
          config.fieldValues
            .filter((fv) => fv.field === 4)
            .map((fv) => ({
              value: fv.fieldValueId,
              label: fv.fieldValueName,
            })),

        fieldValues5:
          config.hasField5 &&
          config.fieldValues
            .filter((fv) => fv.field === 5)
            .map((fv) => ({
              value: fv.fieldValueId,
              label: fv.fieldValueName,
            })),
      })
    })
  }, [callId, projectId, lifter, isDefaultLocale, locale])

  const urlFetched = useRef(false)

  const handleTabClick = useCallback(
    (index) => async () => {
      if (index === 2 && hasSurvey && !surveyUrl && !urlFetched.current) {
        setLoading(true)
        Call.getCompletionUrl(projectId, callId)
          .then((url) => {
            setSurveyUrl(url)
            urlFetched.current = true
          })
          .finally(() => {
            setLoading(false)
          })
      }

      setActiveTab(index)
    },
    [setActiveTab, setSurveyUrl, callId, projectId, hasSurvey, surveyUrl]
  )

  const tabSelected = useCallback(
    (index) => {
      return activeTab === index ? ' tab-selected' : ''
    },
    [activeTab]
  )

  const onResultChanged = useCallback(
    (name, { value: result }) => {
      if (result !== results.stopped && result !== results.badCall) {
        FormHelper.setValue(lifter, 'reasonId', '')
      }

      if (result !== results.callLater) {
        FormHelper.setValues(
          lifter,
          ['callLaterDate', 'callLaterHour', 'callLaterMinute'],
          {
            callLaterDate: '',
            callLaterHour: '',
            callLaterMinute: '',
          }
        )
      }
    },
    [lifter]
  )

  const onCallLogSelect = useCallback(
    (log) => {
      setState({ ...state, log })
    },
    [state, setState]
  )

  const onSubmit = useCallback(() => {
    const values = FormHelper.getValues(lifter, FIELDS)

    if (values.callLaterDate) {
      values.callLaterAt = `${values.callLaterDate} ${values.callLaterHour}:${values.callLaterMinute}`
    }

    delete values.callLaterDate
    delete values.callLaterHour
    delete values.callLaterMinute

    Call.update(projectId, callId, values).then(() => {
      if (onSuccess) onSuccess()
      closeModal()
    })
  }, [lifter, projectId, callId, closeModal, onSuccess])

  const {
    config,
    fieldValues1,
    fieldValues2,
    fieldValues3,
    fieldValues4,
    fieldValues5,
    call,
    users,
    reasons,
    logs,
    log,
  } = state

  return (
    <Modal
      header={title}
      closeModal={closeModal}
      css="width: 960px; min-height: 580px"
      footer={() => (
        <div className="text-right">
          <Button onClick={onSubmit}>{trans.save}</Button>
        </div>
      )}
    >
      <div className="flex-column gap-left gap-top gap-right">
        <div className="flex-row-center">
          {logs && logs.length > 1 && (
            <DropdownButton
              className="gap-right"
              items={logs}
              onSelect={onCallLogSelect}
            >
              ზარები ({logs.length})
            </DropdownButton>
          )}

          <audio
            className="flex-fill height"
            controls
            autoPlay
            src={
              log && `api/calls/${projectId}/${callId}/logs/${log.id}/recording`
            }
          />
        </div>

        {!!log && (
          <div className="flex-row-center-space gap-top">
            <div>{log.started}</div>
            <div>{log.callSeconds} წამი</div>
            <div>{log.answered}</div>
            <div>{log.talkSeconds} წამი</div>
            <div>{log.ended}</div>
          </div>
        )}
      </div>

      <TabsContainer>
        <div onClick={handleTabClick(0)} className={`tab${tabSelected(0)}`}>
          {trans.main}
        </div>

        <div onClick={handleTabClick(1)} className={`tab${tabSelected(1)}`}>
          {trans.quotaFields}
        </div>

        {hasSurvey && (
          <div onClick={handleTabClick(2)} className={`tab${tabSelected(2)}`}>
            {trans.survey}
          </div>
        )}
      </TabsContainer>

      <TabsBody>
        {activeTab === 0 && (
          <Form>
            <label>{trans.phoneNumber}</label>
            <div className="flex-row-center-space">
              <TextBox className="width-35">
                {call && `${call.identifier} / ${call.phoneNumber}`}
              </TextBox>

              <label className="gap-right flex-fill text-right">
                {trans.result}
              </label>

              <Select
                name="result"
                className="width-40"
                options={resultOptions}
                placeholder={trans.result}
                onChanged={onResultChanged}
              />
            </div>

            <label>{trans.status}</label>

            <div className="flex-row-center-space">
              <Select
                name="status"
                options={statusOptions}
                placeholder={trans.status}
                className="width-35"
              />

              <label className="flex-fill gap-right text-right">
                {trans.callLaterTime}
              </label>
              <div className="width-40 flex-row-center">
                <DateInput
                  placeholder={trans.date}
                  className="width-40 gap-right"
                  name="callLaterDate"
                  required={callLater}
                  disabled={!callLater}
                />
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={trans.hour}
                  className="width-30 gap-half-right"
                  name="callLaterHour"
                  options={hours}
                  required={callLater}
                  isDisabled={!callLater}
                />
                :
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={trans.minute}
                  className="width-30 gap-half-left"
                  name="callLaterMinute"
                  options={minutes}
                  required={callLater}
                  isDisabled={!callLater}
                />
              </div>
            </div>

            <label>{trans.reason}</label>

            <Select
              name="reasonId"
              options={reasons}
              placeholder={trans.reason}
              isDisabled={
                result !== results.badCall && result !== results.stopped
              }
            />

            <label>{trans.comments}</label>
            <Input
              placeholder={trans.comments}
              name="comment"
              textArea
              css="height: 58px"
            />

            <label>{trans.remarks}</label>
            <Input
              placeholder={trans.remarks}
              name="remarks"
              textArea
              css="height: 58px"
            />

            <label>{trans.assignedUser}</label>
            <div className="flex-row-center">
              <Select
                placeholder="ოპერატორი"
                className="flex-fill gap-right"
                name="assignedToId"
                options={users}
                required
              />

              <span className="gap-left">
                {trans.assignDateTime}: {call && `${call.assignedAt}`}
              </span>
            </div>

            <label className="gap-top">{trans.createdBy}: </label>
            <div className="flex-row-center-space gap-top">
              {call && call.createdBy}

              <span>
                {trans.createdAt}: {call && call.createdAt}
              </span>
            </div>

            <label className="gap-top">{trans.updatedBy}: </label>
            <div className="flex-row-center-space gap-top">
              {call && call.updatedBy}

              <span>
                {trans.updatedAt}: {call && call.updatedAt}
              </span>
            </div>
          </Form>
        )}

        {activeTab === 1 && (
          <Form>
            {config && config.hasField1 && (
              <>
                <label>{config.fieldName1}</label>

                <Select
                  name="field1ValueId"
                  options={fieldValues1}
                  placeholder={config.fieldName1}
                />
              </>
            )}

            {config && config.hasField2 && (
              <>
                <label>{config.fieldName2}</label>

                <Select
                  name="field2ValueId"
                  options={fieldValues2}
                  placeholder={config.fieldName2}
                />
              </>
            )}

            {config && config.hasField3 && (
              <>
                <label>{config.fieldName3}</label>

                <Select
                  name="field3ValueId"
                  options={fieldValues3}
                  placeholder={config.fieldName3}
                />
              </>
            )}

            {config && config.hasField4 && (
              <>
                <label>{config.fieldName4}</label>

                <Select
                  name="field4ValueId"
                  options={fieldValues4}
                  placeholder={config.fieldName4}
                />
              </>
            )}

            {config && config.hasField5 && (
              <>
                <label>{config.fieldName5}</label>

                <Select
                  name="field5ValueId"
                  options={fieldValues5}
                  placeholder={config.fieldName5}
                />
              </>
            )}

            <label>{trans.description}</label>
            <TextBox>{call.description}</TextBox>
          </Form>
        )}

        {loading && <Loader />}

        {surveyUrl && (
          <iframe
            className="flex-fill"
            css={`
              border: 0;
              ${activeTab !== 2 ? ' display: none' : ''}
            `}
            title="კითხვარი იტვირთება..."
            src={surveyUrl}
          />
        )}
      </TabsBody>
    </Modal>
  )
}

export default withModalManager(
  withStateLifter(withTranslations('CallEdit')(CallEdit))
)
