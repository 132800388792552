import React from 'react'
import MediaQuery, { useMediaQuery } from 'react-responsive'

export const maxWidth = '800px'
export const minWidth = '801px'

export const MobileQuery = ({ children }) => (
  <MediaQuery maxWidth={800}>{children}</MediaQuery>
)

export const DesktopQuery = ({ children }) => (
  <MediaQuery minWidth={801}>{children}</MediaQuery>
)

export const useMobileQuery = () => {
  return useMediaQuery({ maxWidth: 800 })
}

export const useDesktopQuery = () => {
  return useMediaQuery({ minWidth: 801 })
}
