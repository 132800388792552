import React from 'react'
import styled from 'styled-components/macro'
import { Button } from './controls'
import Modal from './Modal'

export const confirmModalRef = React.createRef()

export const openConfirm = (message, caption, agreeText) => {
  return new Promise((resolve, reject) => {
    confirmModalRef.current.openModal(
      message,
      caption,
      agreeText,
      resolve,
      reject
    )
  })
}

class ConfirmModal extends React.Component {
  state = { open: false }

  openModal = (message, caption, agreeText, resolve, reject) => {
    this.setState({ open: true, message, caption, agreeText, resolve, reject })
  }

  closeModal = () => {
    this.state.reject()
    this.setState({ open: false })
  }

  confirm = () => {
    this.state.resolve()
    this.setState({ open: false })
  }

  render() {
    if (!this.state.open) return null

    return (
      <Modal
        header={this.state.caption || 'დადასტურება'}
        footer={this.renderActions}
        css="min-width: 400px; padding: 12px"
        closeModal={this.closeModal}
      >
        <div className="gap-bottom">{this.state.message}</div>

        <div className="text-right gap-top">
          <Button className="gap-right" onClick={this.confirm}>
            {this.props.agreeText || 'დიახ'}
          </Button>
          <Button light onClick={this.closeModal}>
            დახურვა
          </Button>
        </div>
      </Modal>
    )
  }
}

export default ConfirmModal
