import makeCall from './makeCall'

export default function setupRemoteMedia(current, train) {
  const { session, remoteAudioRef } = current

  if (!session) return

  const pc = session.sessionDescriptionHandler.peerConnection
  let remoteStream

  if (pc.getReceivers) {
    remoteStream = new MediaStream()
    pc.getReceivers().forEach((receiver) => {
      const track = receiver.track
      if (track) {
        remoteStream.addTrack(track)
      }
    })
  } else {
    remoteStream = pc.getRemoteStreams()[0]
  }

  let isFirstChunk = true
  const mediaRecorder = new MediaRecorder(remoteStream)
  mediaRecorder.ondataavailable = (event) => {
    if (!isFirstChunk) return
    isFirstChunk = false

    var reader = new FileReader()
    reader.readAsArrayBuffer(event.data)
    reader.onloadend = () => {
      if (mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop()
      }

      const OfflineAudioContext =
        window.OfflineAudioContext || window.webkitOfflineAudioContext

      //8kHz is PSTN sampling rate, we take first 10 seconds for training
      const audioCtx = new OfflineAudioContext(1, (train ? 10 : 7) * 8000, 8000)
      audioCtx.decodeAudioData(reader.result, (audioBuffer) => {
        if (session.hasAnswer) {
          session.bye()
        } else {
          session.cancel()
        }

        // Perform fast Fourier transform to extract frequencies
        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 512
        var bufferSource = audioCtx.createBufferSource()
        bufferSource.buffer = audioBuffer
        bufferSource.connect(analyser)
        //analyser.connect(audioCtx.destination)
        const processor = audioCtx.createScriptProcessor(256, 1, 1)
        processor.connect(audioCtx.destination)
        const sampleData = []
        processor.onaudioprocess = function (event) {
          const freqData = new Uint8Array(analyser.frequencyBinCount)
          analyser.getByteFrequencyData(freqData)
          sampleData.push(Array.from(freqData))
        }
        analyser.connect(processor)
        bufferSource.start(0)

        audioCtx.startRendering().then(() => {
          const { onDataReceive, setState } = current
          onDataReceive(sampleData, train)

          if (train) {
            setTimeout(() => {
              makeCall(current, train)
            }, 1000)
          } else {
            if (current.testIndex < current.testNumbers.length - 1) {
              current.testIndex++
              setTimeout(() => {
                makeCall(current, train)
              }, 1000)
            } else {
              setState({ calling: false })
            }
          }
        })
      })
    }
  }

  mediaRecorder.start(train ? 10000 : 7000)

  remoteAudioRef.current.srcObject = remoteStream
  remoteAudioRef.current.play().catch(() => {
    console.log('play was rejected')
  })
}
