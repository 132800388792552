import React from 'react'
import styled from 'styled-components/macro'
import { Select, Input, DateInput } from '../controls'
import ProjectForm from './ProjectForm'
import ProjectTokens from './ProjectTokens'
import DataTable from '../DataTable/DataTable'
import { Project, Client } from '../../api'
import ProjectDelete from './ProjectDelete'
import { parseDate } from '../helpers/date'
import ProjectConfig from './ProjectConfig'
import ProjectQuotas from './ProjectQuotas'
import CallConfig from './CallConfig'
import Permissions, { hasPermission } from '../../permissions'
import { withTranslations } from '../Translation'

class ProjectList extends React.Component {
  state = { projects: [], clients: [] }

  componentDidMount() {
    this.fetchClients()
    this.fetchProjects()
  }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.client,
        name: 'clientName',
      },
      {
        title: trans.projectCode,
        name: 'projectCode',
      },
      {
        title: trans.projectName,
        name: 'projectName',
      },
      {
        title: trans.startDate,
        name: 'startDate',
      },
      {
        title: trans.endDate,
        name: 'endDate',
      },
      {
        title: trans.projectStatus,
        name: 'statusText',
      },
    ]

    return this.memoizedColumns
  }

  get statuses() {
    const { trans, locale } = this.props

    if (locale === this.memoLocale && trans === this.memoTrans)
      return this.memoStatuses

    this.memoLocale = locale
    this.memoTrans = trans
    this.memoStatuses = [
      { value: 1, label: trans.statusNew },
      { value: 2, label: trans.statusActive },
      { value: 3, label: trans.statusPaused },
      { value: 4, label: trans.statusCompleted },
    ]

    return this.memoStatuses
  }

  fetchClients = async () => {
    const clients = await Client.getAll()

    this.setState({
      clients: clients.map((c) => ({ value: c.clientId, label: c.clientName })),
    })
  }

  fetchProjects = async () => {
    this.projects = await Project.getAll()

    this.setState({ projects: this.projects })
  }

  handleSuccess = () => {
    this.fetchProjects()
  }

  onRowDoubleClick = ({ projectId }) => {
    if (hasPermission(Permissions.ProjectEdit)) {
      this.setState({ projectId }, () => {
        this.editModal.openModal()
      })
    }
  }

  getContextMenuItems = ({ projectId }) => {
    this.setState({ projectId })
    const { trans } = this.props

    var result = [
      {
        title: trans.edit,
        action: () => {
          this.editModal.openModal()
        },
        hide: !hasPermission(Permissions.ProjectEdit),
      },
      {
        title: trans.configuration,
        action: () => {
          this.configModal.openModal()
        },
        hide: !hasPermission(Permissions.ProjectConfig),
      },
      {
        title: trans.numberDatabase,
        action: () => {
          this.tokensModal.openModal()
        },
        hide: !hasPermission(Permissions.ProjectTokens),
      },
      {
        title: trans.quotaDistribution,
        action: () => {
          this.quotasModal.openModal()
        },
        hide: !hasPermission(Permissions.ProjectQuotas),
      },
      {
        title: trans.fieldConfiguration,
        action: () => {
          this.callConfigModal.openModal()
        },
        hide: !hasPermission(Permissions.CallConfig),
      },
      {
        title: trans.refresh,
        action: () => {
          this.fetchProjects()
        },
      },
      {
        title: trans.delete,
        action: () => {
          this.setState({ projectId }, () => {
            this.deleteModal.openModal()
          })
        },
        hide: !hasPermission(Permissions.ProjectDelete),
      },
    ].filter((itemm) => !itemm.hide)

    return result
  }

  filter = () => {
    this.setState((state) => {
      const {
        client,
        projectCode,
        projectName,
        startDate,
        endDate,
        status,
      } = state

      const sdate = parseDate(startDate)
      const edate = parseDate(endDate)

      return {
        projects: this.projects.filter(
          (p) =>
            (!client || p.clientId === client.value) &&
            (!projectCode ||
              (p.projectCode || '')
                .toLowerCase()
                .includes(projectCode.toLowerCase())) &&
            (!projectName ||
              (p.projectName || '')
                .toLowerCase()
                .includes(projectName.toLowerCase())) &&
            (!status || p.status === status.value) &&
            (!sdate.isValid() ||
              edate.isValid() ||
              sdate.isSameOrBefore(parseDate(p.startDate))) &&
            (!edate.isValid() ||
              sdate.isValid() ||
              edate.isSameOrAfter(parseDate(p.endDate))) &&
            (!sdate.isValid() ||
              !edate.isValid() ||
              (sdate.isSameOrBefore(parseDate(p.endDate)) &&
                edate.isSameOrAfter(parseDate(p.startDate))))
        ),
      }
    })
  }

  render() {
    const { clients, projects } = this.state
    const { trans } = this.props

    return (
      <div css="flex: 1; display: flex; flex-direction: column">
        <div css="margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center">
          <div css="display: flex; align-items: center; flex-direction: row; flex: 1; margin-right: 10px">
            <Select
              onChange={(client) => this.setState({ client }, this.filter)}
              value={this.state.client}
              isClearable
              css="width: 150px; flex: 1"
              options={clients}
              placeholder={trans.client}
            />

            <Input
              onChange={(event) =>
                this.setState({ projectCode: event.target.value }, this.filter)
              }
              value={this.state.projectCode}
              placeholder={trans.projectCode}
              css="margin-left: 10px; flex: 1; max-width: 200px"
            />

            <Input
              onChange={(event) =>
                this.setState({ projectName: event.target.value }, this.filter)
              }
              value={this.state.projectName}
              placeholder={trans.projectName}
              css="margin-left: 10px; flex: 1; width: 150px"
            />

            <DateInput
              onChange={(startDate) =>
                this.setState({ startDate }, this.filter)
              }
              value={this.state.startDate}
              placeholder={trans.start}
              css="margin-left: 10px; width: 130px"
            />

            <DateInput
              onChange={(endDate) => this.setState({ endDate }, this.filter)}
              value={trans.end}
              placeholder="დასრულება"
              css="margin-left: 10px; width: 130px"
            />

            <Select
              isClearable
              onChange={(status) => this.setState({ status }, this.filter)}
              value={this.state.status}
              css="margin-left: 10px; width: 150px; flex: 1"
              options={this.statuses}
              placeholder={trans.status}
            />

            <div className="gap-left">{this.state.projects.length}</div>
          </div>

          {hasPermission(Permissions.ProjectCreate) && (
            <ProjectForm
              trigger={trans.newProject}
              onSuccess={this.handleSuccess}
            />
          )}

          <ProjectForm
            ref={(ref) => (this.editModal = ref)}
            projectId={this.state.projectId}
            onSuccess={this.handleSuccess}
          />

          <ProjectDelete
            projectId={this.state.projectId}
            ref={(ref) => (this.deleteModal = ref)}
            onSuccess={this.handleSuccess}
          />

          <ProjectConfig
            ref={(ref) => (this.configModal = ref)}
            projectId={this.state.projectId}
          />

          <ProjectQuotas
            ref={(ref) => (this.quotasModal = ref)}
            projectId={this.state.projectId}
          />

          <ProjectTokens
            ref={(ref) => (this.tokensModal = ref)}
            projectId={this.state.projectId}
          />

          <CallConfig
            ref={(ref) => (this.callConfigModal = ref)}
            projectId={this.state.projectId}
          />
        </div>

        <div css="display: flex; align-items: stretch; flex: 1">
          <DataTable
            onRowDoubleClick={this.onRowDoubleClick}
            columns={this.columns}
            rows={projects}
            getContextMenuItems={this.getContextMenuItems}
          />
        </div>
      </div>
    )
  }
}

export default withTranslations('ProjectList')(ProjectList)
