import { createUserManager } from 'redux-oidc'

export const isSilentRefresh = window.location.pathname === '/silent-renew'

const userManagerConfig = {
  client_id: 'diflex_spa',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  response_type: 'code',
  scope: 'openid profile email phone diflex_api',
  authority:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_ENV === 'cloud'
        ? 'https://account.diflex-global.com/'
        : 'https://account.diflex.ge/'
      : 'https://localhost:44308/',
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/silent-renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
}

const userManager = !isSilentRefresh
  ? createUserManager(userManagerConfig)
  : undefined

export default userManager
