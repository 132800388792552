import setupRemoteMedia from './setupRemoteMedia'
import * as SIP from 'sip.js'

function onInterval(current) {
  const currentTime = new Date().getTime()
  const diff = Math.round((currentTime - current.startTime) / 1000)

  const minutes = `0${Math.trunc(diff / 60)}`.slice(-2)
  const seconds = `0${diff % 60}`.slice(-2)

  current.setState({ time: `${minutes}:${seconds}` })
}

export default function makeCall(current, train) {
  const {
    userAgent,
    setState,
    remoteAudioRef,
    testNumbers,
    testIndex,
    currentPhoneCalls,
    callsPerPhone,
    trainLabels,
    state,
  } = current

  if (!userAgent || !userAgent.isRegistered()) {
    setState({ registered: false, calling: false })
    return
  }

  let label
  let phoneNumber

  if (train) {
    if (currentPhoneCalls === 0 || currentPhoneCalls >= callsPerPhone) {
      if (trainLabels.length) {
        label = trainLabels.shift()
      }
    } else {
      label = current.label
    }

    phoneNumber = label && label.phoneNumber

    current.label = label
    current.currentPhoneCalls =
      currentPhoneCalls < callsPerPhone ? currentPhoneCalls + 1 : 1
  } else if (testNumbers.length) {
    phoneNumber = testNumbers[testIndex].phoneNumber
  }

  if (!phoneNumber) {
    setState({ calling: false })
    return
  }

  setState({
    phoneNumber,
    label,
    totalCalls: state.totalCalls + 1,
    currentPhoneCalls: current.currentPhoneCalls,
  })

  const { current: remoteAudio } = remoteAudioRef

  // Safari hack, because you cannot call .play() from a non user action
  remoteAudio.autoplay = true

  console.log('onCall')
  const session = userAgent.invite(phoneNumber, {
    extraHeaders: [`X-Call-Id: TRAIN`],
    earlyMedia: true,
    sessionDescriptionHandlerOptions: {
      constraints: {
        audio: true,
        video: false,
      },
    },
  })

  if (!session) {
    setState({ calling: false })
    return
  }

  setState({ calling: true, time: '00:00' })

  current.startTime = new Date().getTime()
  current.stopwatch = setInterval(() => onInterval(current), 1000)

  current.session = session

  function handleAddTrackStream() {
    setupRemoteMedia(current, train)
  }

  session.on('SessionDescriptionHandler-created', (handler) => {
    session.sessionDescriptionHandler.on('addTrack', handleAddTrackStream)

    session.sessionDescriptionHandler.on('addStream', handleAddTrackStream)
  })

  function stopCalling(response) {
    const code = response && response.getHeader('X-Asterisk-Hangupcausecode')

    let status
    if (code === '17') {
      status = 'დაკავებაა'
    } else if (code === '1') {
      status = 'არარეგისტრირებულია'
    } else if (code === '19') {
      status = 'გათიშულია'
    } else if (code === '21') {
      status = 'არ პასუხობს'
    } else {
      status = 'სხვა'
    }

    status += ': ' + code

    setState({ status })
  }

  session.on('progress', (response) => {
    if (
      response.statusCode === 183 &&
      response.body &&
      session.hasOffer &&
      !session.dialog &&
      !session.hasAnswer &&
      session.sessionDescriptionHandler &&
      (!response.hasHeader('require') ||
        response.getHeader('require').indexOf('100rel') === -1)
    ) {
      session.status = SIP.SessionStatus.STATUS_EARLY_MEDIA

      session.sessionDescriptionHandler
        .setDescription(
          response.body,
          session.sessionDescriptionHandlerOptions,
          session.modifiers
        )
        .then(() => {
          session.status = SIP.SessionStatus.STATUS_EARLY_MEDIA
        })
        .catch((error) => {
          if (session.status === SIP.SessionStatus.STATUS_TERMINATED) {
            return
          }

          session.failed(undefined, SIP.C.causes.WEBRTC_ERROR)
          session.terminated(undefined, SIP.C.causes.WEBRTC_ERROR)
        })
    }
  })

  session.on('accepted', () => {
    //session.bye()
  })

  session.on('rejected', stopCalling)
  session.on('failed', stopCalling)
  session.on('terminated', stopCalling)
  session.on('cancel', stopCalling)
  session.on('bye', stopCalling)
}
