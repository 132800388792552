import React from 'react'
import styled from 'styled-components/macro'
import { Input, Button, Checkbox, Select } from '../controls'
import Modal, { withModalManager } from '../Modal'
import DataTable from '../DataTable'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import { Config } from '../../api'
import Loader from '../Loader'
import { withTranslations } from '../Translation'

const TabsBody = styled.div`
  flex: 1;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
`

const Form = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-gap: 12px;

  > label {
    display: flex;
    align-items: center;
  }
`

const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  user-select: none;
  padding-left: 12px;
  margin-top: 12px;

  .tab {
    position: relative;
    padding: 8px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .tab-selected {
    border: 1px solid #ced4da;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: -1px;
    background-color: whitesmoke;
    cursor: auto;
    padding-top: 7px !important;
  }
`

const FIELDS = [
  'surveyUrl',
  'surveyId',
  'surveyApiKey',
  'totalInterviews',

  'tokenManualInput',
  'tokensAssigned',

  'hasQuotas',
  'fieldsEditable',
  'ignoreQuotas',
  'testMode',

  'hasField1',
  'hasField2',
  'hasField3',
  'hasField4',
  'hasField5',

  'fieldName1',
  'fieldName2',
  'fieldName3',
  'fieldName4',
  'fieldName5',

  'callDirection',
]

class ProjectConfig extends React.PureComponent {
  state = {
    fetching: true,
    activeTab: 0,
    fieldValues1: [],
    fieldValues2: [],
    fieldValues3: [],
    fieldValues4: [],
    fieldValues5: [],
    quotas: [],
  }

  callDirections = [
    { value: 0, label: this.props.trans.outgoing },
    { value: 1, label: this.props.trans.incoming },
    { value: 2, label: this.props.trans.bothways },
  ]

  componentDidMount() {
    Config.get(this.props.projectId)
      .then((config) => {
        FormHelper.setValues(this.props.lifter, FIELDS, config)

        this.setState({
          fieldValues1: config.fieldValues.filter((fv) => fv.field === 1),
          fieldValues2: config.fieldValues.filter((fv) => fv.field === 2),
          fieldValues3: config.fieldValues.filter((fv) => fv.field === 3),
          fieldValues4: config.fieldValues.filter((fv) => fv.field === 4),
          fieldValues5: config.fieldValues.filter((fv) => fv.field === 5),
        })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  setQuotas = (quotas) => {
    this.setState({ quotas })
  }

  submit = () => {
    const { lifter, projectId, onSuccess, closeModal } = this.props

    FormHelper.setTouched(lifter, FIELDS, true)

    if (FormHelper.isInvalid(lifter, FIELDS)) {
      return
    }

    this.setState({ submitting: true })

    const config = FormHelper.getValues(lifter, FIELDS)
    config.fieldValues = [
      ...this.state.fieldValues1.map((fv) => ({ ...fv, field: 1 })),
      ...this.state.fieldValues2.map((fv) => ({ ...fv, field: 2 })),
      ...this.state.fieldValues3.map((fv) => ({ ...fv, field: 3 })),
      ...this.state.fieldValues4.map((fv) => ({ ...fv, field: 4 })),
      ...this.state.fieldValues5.map((fv) => ({ ...fv, field: 5 })),
    ]

    Config.put(projectId, config)
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  renderActions = () => {
    return (
      <div className="text-right">
        <Button onClick={this.submit}>{this.props.trans.save}</Button>
      </div>
    )
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  render() {
    const { fetching, activeTab } = this.state
    const { trans } = this.props

    if (fetching) return <Loader />

    const hasFieldName = `hasField${activeTab}`
    const hasField = this.props[hasFieldName]

    return (
      <Modal
        header={trans.title}
        footer={this.renderActions}
        css="width: 960px; min-height: 500px"
        ref={(ref) => (this.modal = ref)}
        closeModal={this.props.closeModal}
      >
        <TabsContainer>
          <div
            onClick={this.handleTabClick(0)}
            className={`tab${this.tabSelected(0)}`}
          >
            {trans.quotaConfiguration}
          </div>

          <div
            onClick={this.handleTabClick(1)}
            className={`tab${this.tabSelected(1)}`}
          >
            {this.props.fieldName1 || trans.variable1}
          </div>

          <div
            onClick={this.handleTabClick(2)}
            className={`tab${this.tabSelected(2)}`}
          >
            {this.props.fieldName2 || trans.variable2}
          </div>

          <div
            onClick={this.handleTabClick(3)}
            className={`tab${this.tabSelected(3)}`}
          >
            {this.props.fieldName3 || trans.variable3}
          </div>

          <div
            onClick={this.handleTabClick(4)}
            className={`tab${this.tabSelected(4)}`}
          >
            {this.props.fieldName4 || trans.variable4}
          </div>

          <div
            onClick={this.handleTabClick(5)}
            className={`tab${this.tabSelected(5)}`}
          >
            {this.props.fieldName5 || trans.variable5}
          </div>
        </TabsContainer>

        <TabsBody>
          {activeTab === 0 && (
            <Form>
              <label>{trans.surveyAddress}</label>
              <Input name="surveyUrl" />

              <label>{trans.surveyId} (ID)</label>
              <Input name="surveyId" />

              <label>{trans.surveyApiKey}</label>
              <Input name="surveyApiKey" />

              <label>{trans.totalInterviews}</label>
              <Input name="totalInterviews" css="width: 120px" />

              <label>{trans.numberManualInput}</label>
              <Checkbox name="tokenManualInput" />

              <label>{trans.assignOperator}</label>
              <Checkbox name="tokensAssigned" />

              <label>{trans.quotasExist}</label>
              <Checkbox name="hasQuotas" />

              <label>{trans.fieldsEditable}</label>
              <Checkbox name="fieldsEditable" />

              <label>{trans.callDirection}</label>
              <Select
                name="callDirection"
                options={this.callDirections}
                css="width: 220px"
              />
            </Form>
          )}

          {activeTab > 0 && (
            <div className="flex-row flex-fill">
              <div className="flex-column flex-fill pad-right">
                <div className="flex-row-center gap-bottom">
                  <div className="gap-right">{trans.active}</div>

                  <Checkbox name={hasFieldName} />

                  <div className="gap-left gap-right">{trans.name}</div>

                  <Input
                    disabled={!hasField}
                    className="width-40"
                    placeholder={trans[`variable${activeTab}`]}
                    name={`fieldName${activeTab}`}
                  />
                </div>

                <DataTable
                  appendable={hasField}
                  clipboardImport={hasField}
                  columns={[
                    {
                      name: 'fieldValueName',
                      editable: hasField,
                      required: true,
                      title:
                        this.props[`fieldName${activeTab}`] ||
                        trans[`variable${activeTab}`],
                    },
                  ]}
                  rows={this.state[`fieldValues${activeTab}`]}
                />

                <div className="flex-row gap-top">
                  <Button
                    disabled={!hasField}
                    onClick={() =>
                      this.setState({
                        [`fieldValues${activeTab}`]: [
                          ...this.state[`fieldValues${activeTab}`],
                          {},
                        ],
                      })
                    }
                  >
                    {trans.add}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </TabsBody>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('ProjectConfig')(
    withStateLifter((props) => (
      <ProjectConfig
        {...props}
        hasField1={FormHelper.getValue(props.lifter, 'hasField1')}
        hasField2={FormHelper.getValue(props.lifter, 'hasField2')}
        hasField3={FormHelper.getValue(props.lifter, 'hasField3')}
        hasField4={FormHelper.getValue(props.lifter, 'hasField4')}
        hasField5={FormHelper.getValue(props.lifter, 'hasField5')}
        fieldName1={FormHelper.getValue(props.lifter, 'fieldName1')}
        fieldName2={FormHelper.getValue(props.lifter, 'fieldName2')}
        fieldName3={FormHelper.getValue(props.lifter, 'fieldName3')}
        fieldName4={FormHelper.getValue(props.lifter, 'fieldName4')}
        fieldName5={FormHelper.getValue(props.lifter, 'fieldName5')}
      />
    ))
  )
)
