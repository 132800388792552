import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import Modal, { withModalManager } from '../Modal'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataMatrix from '../DataMatrix'
import { Quota, Config } from '../../api'
import { withTranslations } from '../Translation'

const TabsBody = styled.div`
  flex: 1;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;

  .row-header {
    background-color: #e9ecef;
  }
`

const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  user-select: none;
  padding-left: 12px;

  .tab {
    position: relative;
    padding: 8px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .tab-selected {
    border: 1px solid #ced4da;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: -1px;
    background-color: whitesmoke;
    cursor: auto;
    padding-top: 7px !important;
  }
`

class ProjectQuotas extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: null,
      groups: [],
    }

    const hasFields = []

    Config.get(props.projectId)
      .then((config) => {
        const fields = []
        if (config.hasField1) {
          fields.push({
            value: 1,
            label: config.fieldName1,
            values: config.fieldValues
              .filter((fv) => fv.field === 1)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })

          hasFields.push(1)
        }

        if (config.hasField2) {
          fields.push({
            value: 2,
            label: config.fieldName2,
            values: config.fieldValues
              .filter((fv) => fv.field === 2)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })

          hasFields.push(2)
        }

        if (config.hasField3) {
          fields.push({
            value: 3,
            label: config.fieldName3,
            values: config.fieldValues
              .filter((fv) => fv.field === 3)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })

          hasFields.push(3)
        }

        if (config.hasField4) {
          fields.push({
            value: 4,
            label: config.fieldName4,
            values: config.fieldValues
              .filter((fv) => fv.field === 4)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })

          hasFields.push(4)
        }

        if (config.hasField5) {
          fields.push({
            value: 5,
            label: config.fieldName5,
            values: config.fieldValues
              .filter((fv) => fv.field === 5)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })

          hasFields.push(5)
        }

        this.setState({ fields, config })

        return Quota.getAll(props.projectId)
      })
      .then((quotas) => {
        const singleFields = [
          ...new Set(quotas.oneFieldQuotas.map((sq) => sq.field)),
        ]

        const doubleFields = quotas.twoFieldQuotas.filter(
          (dq, index, self) =>
            self.findIndex(
              (dq2) => dq2.field === dq.field && dq2.byField === dq.byField
            ) === index
        )

        this.setState({
          activeTab: singleFields.length || doubleFields.length ? 0 : null,
          groups: [
            ...singleFields.map((field) => ({
              field: this.state.fields.find((f) => f.value === field),
              quotas: quotas.oneFieldQuotas.filter(
                (two) => two.field === field
              ),
            })),
            ...doubleFields.map(({ field, byField }) => ({
              field: this.state.fields.find((f) => f.value === field),
              byField: this.state.fields.find((f) => f.value === byField),
              quotas: quotas.twoFieldQuotas.filter(
                (two) => two.field === field && two.byField === byField
              ),
            })),
          ],
        })
      })
  }

  submit = () => {
    const oneFieldQuotas = this.state.groups
      .filter((g) => !g.byField)
      .map((group) => group.quotas)
      .flat()
      .filter((q) => q.quota)
      .map(({ fieldValueId, quota }) => ({ fieldValueId, quota }))
    const twoFieldQuotas = this.state.groups
      .filter((g) => g.byField)
      .map((group) => group.quotas)
      .flat()
      .filter((q) => q.quota)
      .map(({ fieldValueId, byFieldValueId, quota }) => ({
        fieldValueId,
        byFieldValueId,
        quota,
      }))

    Quota.update(this.props.projectId, {
      oneFieldQuotas,
      twoFieldQuotas,
    }).then(() => {
      this.props.closeModal()
    })
  }

  setQuotas = (quotas) => {
    const groups = [...this.state.groups]
    groups[this.state.activeTab] = { ...groups[this.state.activeTab], quotas }

    this.setState({ groups })
  }

  handleGroupAdd = () => {
    this.setState((state) => ({
      groups: [
        ...state.groups,
        {
          field: state.field,
          byField: state.byField,
          quotas: state.byField
            ? []
            : state.field.values.map((fv) => ({
                fieldValueId: fv.value,
                fieldValueName: fv.title,
                quota: undefined,
              })),
        },
      ],
      activeTab: state.groups.length,
      field: null,
      byField: null,
    }))
  }

  renderActions = () => {
    return (
      <div className="flex-row-center-space">
        <Button onClick={this.submit}>{this.props.trans.save}</Button>
      </div>
    )
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  handleTabClose = (index) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    const groups = [...this.state.groups]
    groups.splice(index, 1)

    const nextState = { groups }
    if (groups.length - 1 < this.state.activeTab) {
      nextState.activeTab = groups.length ? groups.length - 1 : null
    }

    this.setState(nextState)
  }

  oneFieldColumns = [{ title: this.props.trans.quota }]

  render() {
    const { trans } = this.props

    const isOneField =
      this.state.activeTab !== null &&
      !this.state.groups[this.state.activeTab].byField

    return (
      <Modal
        header={trans.quotaDistribution}
        footer={this.renderActions}
        css="width: 960px; min-height: 500px"
        ref={(ref) => (this.modal = ref)}
        closeModal={this.props.closeModal}
      >
        <div className="flex-row-center pad">
          <Select
            onChange={(option) => this.setState({ field: option })}
            css="width: 200px"
            className="gap-right"
            options={this.state.fields}
            value={this.state.field}
          />

          <Select
            isDisabled={!this.state.field}
            css="width: 200px"
            className="gap-right"
            onChange={(option) => this.setState({ byField: option })}
            options={
              this.state.fields &&
              this.state.field &&
              this.state.fields.filter(
                (f) => f.value !== this.state.field.value
              )
            }
            value={this.state.byField}
          />
          <Button
            onClick={this.handleGroupAdd}
            className="gap-right"
            disabled={!this.state.field}
          >
            {trans.add}
          </Button>
          <span css="margin-left: 12px">
            {trans.totalInterviews}:{' '}
            {this.state.config && this.state.config.totalInterviews}
          </span>
        </div>

        <TabsContainer>
          {this.state.groups.map((group, index) => (
            <div
              onClick={this.handleTabClick(index)}
              className={`tab${this.tabSelected(index)}`}
            >
              {group.field.label +
                (group.byField ? ' / ' + group.byField.label : '')}
              {index === this.state.activeTab && (
                <i
                  css="font-style: normal; cursor: pointer; margin-left: 7px"
                  onClick={this.handleTabClose(index)}
                >
                  x
                </i>
              )}
            </div>
          ))}
        </TabsContainer>

        <TabsBody>
          {this.state.activeTab !== null && (
            <>
              <div className="flex-fill flex-column">
                <DataMatrix
                  editable
                  excelExport
                  clipboardImport
                  totalsRow
                  totalsRowHeader={trans.total}
                  totalsColumn={!isOneField}
                  totalsColumnHeader={trans.total}
                  rows={this.state.groups[this.state.activeTab].field.values}
                  rowColumnMaxWidth="800px"
                  columns={
                    isOneField
                      ? this.oneFieldColumns
                      : this.state.groups[this.state.activeTab].byField.values
                  }
                  header={
                    this.state.groups[this.state.activeTab].field.label +
                    (!isOneField
                      ? ' / ' +
                        this.state.groups[this.state.activeTab].byField.label
                      : '')
                  }
                  data={this.state.groups[this.state.activeTab].quotas}
                  number
                  integer
                  unsigned
                  setData={this.setQuotas}
                  rowKey="fieldValueId"
                  columnKey="byFieldValueId"
                  valueKey="quota"
                />
              </div>
            </>
          )}
        </TabsBody>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('ProjectQuotas')(
    withStateLifter((props) => (
      <ProjectQuotas
        {...props}
        fieldName1={FormHelper.getValue(props.lifter, 'fieldName1')}
        fieldName2={FormHelper.getValue(props.lifter, 'fieldName2')}
        fieldName3={FormHelper.getValue(props.lifter, 'fieldName3')}
        fieldName4={FormHelper.getValue(props.lifter, 'fieldName4')}
        fieldName5={FormHelper.getValue(props.lifter, 'fieldName5')}
      />
    ))
  )
)
