import { get, post, put, del } from './base'

export const getAll = () => get('projects').then(projects => projects || [])
export const getCurrent = () =>
  get('projects/current').then(projects => projects || [])
export const getOne = projectId => get(`projects/${projectId}`)

export const create = project => post('projects', project)
export const update = (projectId, project) =>
  put(`projects/${projectId}`, project)
export const remove = projectId => del(`projects/${projectId}`)
