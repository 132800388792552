import React from 'react'

export const LifterContext = React.createContext({})

function withStateLifter(Component) {
  class StateLifter extends React.Component {
    state = {}

    notifiers = {}

    componentWillUnmount() {
      this.notifiers = {}
    }

    notify = name => {
      const notifier = this.notifiers[name]
      if (notifier) notifier()
    }

    lifter = {
      getStates: () => {
        return this.state
      },

      setNotifier: (name, handler) => {
        this.notifiers[name] = handler
      },

      removeNotifier: name => {
        delete this.notifiers[name]
      },

      setStates: states => {
        const keys = Object.keys(states)
        this.setState(
          state => {
            return {
              ...Object.assign(
                ...keys.map(key => ({
                  [key]: { ...state[key], ...states[key] },
                })),
              ),
            }
          },
          () => {
            keys.forEach(key => {
              this.notify(key)
            })
          },
        )
      },

      setState: (name, state, callback) => {
        this.setState(
          _state => ({
            [name]: { ..._state[name], ...state },
          }),
          () => {
            this.notify(name)
            if (callback) callback(name, state)
          },
        )
      },

      getState: name => {
        return this.state[name]
      },
    }

    render() {
      return (
        <LifterContext.Provider value={this.lifter}>
          <Component {...this.props} lifter={this.lifter} />
        </LifterContext.Provider>
      )
    }
  }

  return StateLifter
}

export default withStateLifter
