import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataMatrix from '../DataMatrix'
import { Project, Quota, Config } from '../../api'
import { withTranslations } from '../Translation'

const TabsBody = styled.div`
  flex: 1;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;

  .row-header {
    background-color: #e9ecef;
  }
`

const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  user-select: none;
  padding-left: 12px;

  .tab {
    position: relative;
    padding: 8px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .tab-selected {
    border: 1px solid #ced4da;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: -1px;
    background-color: whitesmoke;
    cursor: auto;
    padding-top: 7px !important;
  }
`

class QuotasReport extends React.PureComponent {
  state = {
    activeTab: null,
    groups: [],
    projects: [],
    fetching: true,
    loading: false,
  }

  get lifter() {
    return this.props.lifter
  }

  get projectId() {
    return FormHelper.getValue(this.lifter, 'projectId')
  }

  componentDidMount() {
    Project.getAll()
      .then((projects) => {
        this.setState({
          projects: projects.map((p) => ({
            value: p.projectId,
            label: p.projectName,
          })),
        })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  handleProjectChanged = () => {
    this.loadReport()
  }

  loadReport() {
    this.setState({ loading: true })

    Config.get(this.projectId)
      .then((config) => {
        const fields = []
        if (config.hasField1) {
          fields.push({
            value: 1,
            label: config.fieldName1,
            values: config.fieldValues
              .filter((fv) => fv.field === 1)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })
        }

        if (config.hasField2) {
          fields.push({
            value: 2,
            label: config.fieldName2,
            values: config.fieldValues
              .filter((fv) => fv.field === 2)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })
        }

        if (config.hasField3) {
          fields.push({
            value: 3,
            label: config.fieldName3,
            values: config.fieldValues
              .filter((fv) => fv.field === 3)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })
        }

        if (config.hasField4) {
          fields.push({
            value: 4,
            label: config.fieldName4,
            values: config.fieldValues
              .filter((fv) => fv.field === 4)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })
        }

        if (config.hasField5) {
          fields.push({
            value: 5,
            label: config.fieldName5,
            values: config.fieldValues
              .filter((fv) => fv.field === 5)
              .map((fv) => ({
                value: fv.fieldValueId,
                title: fv.fieldValueName,
              })),
          })
        }

        this.setState({ fields, config })

        return Quota.report(this.projectId)
      })
      .then((quotas) => {
        const singleFields = [
          ...new Set(quotas.oneFieldQuotas.map((sq) => sq.field)),
        ]

        const doubleFields = quotas.twoFieldQuotas.filter(
          (dq, index, self) =>
            self.findIndex(
              (dq2) => dq2.field === dq.field && dq2.byField === dq.byField
            ) === index
        )

        this.setState({
          activeTab: singleFields.length || doubleFields.length ? 0 : null,
          groups: [
            ...singleFields.map((field) => ({
              field: this.state.fields.find((f) => f.value === field),
              quotas: quotas.oneFieldQuotas.filter(
                (two) => two.field === field
              ),
            })),
            ...doubleFields.map(({ field, byField }) => ({
              field: this.state.fields.find((f) => f.value === field),
              byField: this.state.fields.find((f) => f.value === byField),
              quotas: quotas.twoFieldQuotas.filter(
                (two) => two.field === field && two.byField === byField
              ),
            })),
          ],
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  formatter = (datum) => {
    return `${datum.done} / ${datum.quota} / ${datum.tokensLeft}`
  }

  get oneFieldColumns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans

    this.memoizedColumns = [{ title: this.props.trans.quota }]

    return this.memoizedColumns
  }

  render() {
    const isOneField =
      this.state.activeTab !== null &&
      !this.state.groups[this.state.activeTab].byField

    const { fetching, loading } = this.state

    const { trans } = this.props

    return (
      <>
        <div className="flex-row-center gap-bottom">
          <Select
            name="projectId"
            className="flex-fill gap-right"
            options={this.state.projects}
            placeholder={trans.chooseProject}
            onChanged={this.handleProjectChanged}
            isDisabled={fetching || loading}
          />

          <Button
            disabled={!this.projectId || fetching || loading}
            onClick={this.loadReport}
          >
            {trans.refresh}
          </Button>
        </div>

        <TabsContainer>
          {this.state.groups.map((group, index) => (
            <div
              onClick={this.handleTabClick(index)}
              className={`tab${this.tabSelected(index)}`}
            >
              {group.field.label +
                (group.byField ? ' / ' + group.byField.label : '')}
            </div>
          ))}
        </TabsContainer>

        <TabsBody>
          {this.state.activeTab !== null && (
            <>
              <div className="flex-fill flex-column">
                <DataMatrix
                  excelExport
                  totalsRow
                  totalsRowHeader={trans.total}
                  totalsColumn={!isOneField}
                  totalsColumnHeader={trans.total}
                  rows={this.state.groups[this.state.activeTab].field.values}
                  columns={
                    isOneField
                      ? this.oneFieldColumns
                      : this.state.groups[this.state.activeTab].byField.values
                  }
                  header={
                    this.state.groups[this.state.activeTab].field.label +
                    (!isOneField
                      ? ' / ' +
                        this.state.groups[this.state.activeTab].byField.label
                      : '')
                  }
                  data={this.state.groups[this.state.activeTab].quotas}
                  rowKey="fieldValueId"
                  columnKey="byFieldValueId"
                  valueKey={['quota', 'done', 'tokensLeft']}
                  formatter={this.formatter}
                />
              </div>
            </>
          )}
        </TabsBody>
      </>
    )
  }
}

export default withTranslations('QuotasReport')(
  withStateLifter((props) => <QuotasReport {...props} />)
)
