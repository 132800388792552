import { get, post, put, del } from './base'

export const getAll = () => get('users').then((users) => users || [])
export const getOne = (userId) =>
  get(`users/${userId}`).then((users) => users || [])
export const sync = (user) => post('users', user)
export const updateRoles = (userId, roles) =>
  put(`users/${userId}/roles`, roles)
export const getPermissions = () => get('users/permissions')
export const update = (userId, user) => put(`users/${userId}`, user)
