import React from 'react'
import styled from 'styled-components/macro'
import { Select, Button } from '../controls'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import DataTable from '../DataTable'
import { Project, Report, Config } from '../../api'
import { withTranslations } from '../Translation'
import * as SIP from 'sip.js'

class RealTimeReport extends React.PureComponent {
  state = {
    data: [],
    projects: [],
    fetching: true,
    loading: false,
    spying: false,
  }

  get lifter() {
    return this.props.lifter
  }

  get projectId() {
    return FormHelper.getValue(this.lifter, 'projectId')
  }

  async componentDidMount() {
    try {
      const [sipConfig, projects] = await Promise.all([
        Config.getSip(),
        Project.getAll(),
      ])
      this.sipConfig = sipConfig
      this.setState({
        projects: projects.map((p) => ({
          value: p.projectId,
          label: p.projectName,
        })),
      })
    } finally {
      this.setState({ fetching: false })
    }
  }

  componentWillUnmount() {
    if (!this.session) return

    if (this.session.hasAnswer) {
      this.session.bye()
    } else {
      this.session.cancel()
    }
  }

  handleProjectChanged = () => {
    this.loadReport()
  }

  loadReport = () => {
    this.setState({ loading: true })

    Report.realtime(this.projectId)
      .then((data) => {
        this.setState({ data })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  get columns() {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.operator,
        name: 'fullName',
      },
      {
        title: trans.phoneNumber,
        name: 'identifier',
      },
      {
        title: trans.channelExtension,
        name: 'extension',
      },
      {
        title: trans.talkDuration,
        name: 'duration',
        exportUnformatted: true,
        formatter: (_, { talkMinutes, talkSeconds }) => {
          let time = ''

          if (talkMinutes) {
            time =
              talkMinutes.toString() +
              ' ' +
              (talkMinutes === 1
                ? this.props.trans.minute
                : this.props.trans.minutes)
          }

          if (talkSeconds) {
            time +=
              (talkMinutes ? ` ${this.props.trans.and} ` : '') +
              talkSeconds +
              ' ' +
              (talkSeconds === 1
                ? this.props.trans.second
                : this.props.trans.seconds)
          }

          return time
        },
      },
    ]
    return this.memoizedColumns
  }

  spy = async (extension) => {
    this.extension = extension

    //this.sipUri = localStorage.getItem('sip.uri')
    this.ua = new SIP.UA({
      register: true,
      uri: `${this.sipConfig.username}@${this.sipConfig.registrar}`, // this.sipUri, //'99500@devsip.diflex.ge',
      password: this.sipConfig.password, //localStorage.getItem('sip.password'), //'940e5bdc1cf29e26a580509e57ee079b',
      transportOptions: {
        wsServers: this.sipConfig.wsServer, //localStorage.getItem('sip.wsServer'), //'wss://devsip.diflex.ge:8089/ws'
      },
      media: { remote: { audio: this.remoteAudio } },
    })

    this.ua.on('registered', (...args) => {
      console.log('registered', args)

      this.call()
    })

    this.ua.on('unregistered', (...args) => {
      console.log('unregistered', args)
    })
  }

  call = (identifier) => {
    // Safari hack, because you cannot call .play() from a non user action
    this.remoteAudio.autoplay = true

    const extension = this.extension.split('@')[0]

    this.session = this.ua.invite(
      `${this.sipConfig.spyExtension}${extension}`,
      {
        earlyMedia: true,
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
        },
      }
    )

    if (!this.session) return

    this.setState({ spying: true, extension })

    this.session.on('SessionDescriptionHandler-created', (handler) => {
      this.session.sessionDescriptionHandler.on('addTrack', () => {
        this.setupRemoteMedia()
      })

      this.session.sessionDescriptionHandler.on('addStream', () => {
        this.setupRemoteMedia()
      })
    })

    this.session.on('progress', this.onProgress)
    this.session.on('accepted', this.onAccepted)
    this.session.on('rejected', this.onRejected)
    this.session.on('failed', this.onFailed)
    this.session.on('terminated', this.onTerminated)
    this.session.on('cancel', this.onCancel)
    this.session.on('bye', this.onBye)
    this.session.on('dtmf', (request, dtmf) => {
      //this.session.emit('dtmf', dtmf.tone)
    })
  }

  setupRemoteMedia = () => {
    if (!this.session) return

    // If there is a video track, it will attach the video and audio to the same element
    const pc = this.session.sessionDescriptionHandler.peerConnection
    let remoteStream

    if (pc.getReceivers) {
      remoteStream = new MediaStream()
      pc.getReceivers().forEach((receiver) => {
        const track = receiver.track
        if (track) {
          remoteStream.addTrack(track)
        }
      })
    } else {
      remoteStream = pc.getRemoteStreams()[0]
    }

    this.remoteAudio.srcObject = remoteStream
    this.remoteAudio.play().catch(() => {
      console.log('play was rejected')
    })
  }

  onProgress = (...args) => {
    console.log('onProgress', args, this.session)
    const [response] = args
    if (
      response.statusCode === 183 &&
      response.body &&
      this.session.hasOffer &&
      !this.session.dialog
    ) {
      if (
        !response.hasHeader('require') ||
        response.getHeader('require').indexOf('100rel') === -1
      ) {
        if (
          this.session.hasOffer &&
          !this.session.hasAnswer &&
          this.session.sessionDescriptionHandler
        ) {
          this.session.status = SIP.SessionStatus.STATUS_EARLY_MEDIA

          // this.session.sessionDescriptionHandler.on('addTrack', () => {
          //   this.setupRemoteMedia()
          // })

          // this.session.sessionDescriptionHandler.on('addStream', () => {
          //   this.setupRemoteMedia()
          // })

          this.session.sessionDescriptionHandler
            .setDescription(
              response.body,
              this.session.sessionDescriptionHandlerOptions,
              this.session.modifiers
            )
            .then(() => {
              this.session.status = SIP.SessionStatus.STATUS_EARLY_MEDIA
            })
            .catch((error) => {
              if (this.session.status === SIP.SessionStatus.STATUS_TERMINATED) {
                return
              }
              this.failed(undefined, SIP.C.causes.WEBRTC_ERROR)
              this.terminated(undefined, SIP.C.causes.WEBRTC_ERROR)
            })
        }
      }
    }
  }

  onAccepted = (...args) => {
    console.log('onAccepted', args)

    if (!this.session) return

    this.setupRemoteMedia()

    //const spyPassword = localStorage.getItem('sip.spyPassword')
    this.session.dtmf(`${this.sipConfig.spyPassword}#`)
    // setTimeout(() => {
    //   const extension = this.extension.split('@')[0]
    //   this.session.dtmf(`${extension}#`)

    //   this.setState({ spying: true, extension })
    // })
  }

  onRejected = (...args) => {
    console.log('onRejected', args)
  }

  onFailed = (...args) => {
    console.log('onFailed', args)
  }

  onTerminated = (...args) => {
    console.log('onTerminated', args)

    delete this.session
    this.ua.unregister()
    this.setState({ spying: false })
  }

  onCancel = (...args) => {
    console.log('onCancel', args)
  }

  onBye = (response, cause) => {
    console.log('onBye', response, cause)
  }

  getContextMenuItems = ({ extension }) => {
    var result = [
      {
        title: this.props.trans.listen,
        action: () => {
          this.spy(extension)
        },
      },
    ]

    return result
  }

  handleBye = () => {
    this.session.bye()
  }

  handleListen = () => {
    this.session.dtmf(`${this.sipConfig.listen}#`)
  }

  handleWhisper = () => {
    this.session.dtmf(`${this.sipConfig.whisper}#`)
  }

  handleBarge = () => {
    this.session.dtmf(`${this.sipConfig.barge}#`)
  }

  render() {
    const { fetching, loading } = this.state

    const { trans } = this.props

    return (
      <>
        <video
          id="remoteAudio"
          css="display: none"
          ref={(ref) => (this.remoteAudio = ref)}
        ></video>

        <div className="flex-row-center gap-bottom">
          {this.state.spying && (
            <>
              <Button
                className="gap-right"
                onClick={this.handleBye}
                title={trans.stopListening}
                danger
              >
                {this.state.extension}
              </Button>

              <Button
                className="gap-right"
                onClick={this.handleListen}
                title="Listen - 4"
                light
              >
                {trans.listen}
              </Button>

              <Button
                className="gap-right"
                onClick={this.handleWhisper}
                title="Whisper - 5"
                light
              >
                {trans.whisper}
              </Button>

              <Button
                className="gap-right"
                onClick={this.handleBarge}
                title="Barge - 6"
                light
              >
                {trans.barge}
              </Button>
            </>
          )}

          <Select
            name="projectId"
            className="flex-fill gap-right"
            options={this.state.projects}
            placeholder={trans.chooseProject}
            onChanged={this.handleProjectChanged}
            isDisabled={fetching || loading}
          />

          <Button
            disabled={!this.projectId || fetching || loading}
            onClick={this.loadReport}
          >
            {trans.refresh}
          </Button>
        </div>

        <DataTable
          rows={this.state.data}
          columns={this.columns}
          getContextMenuItems={this.getContextMenuItems}
          loading={this.state.loading}
        />
      </>
    )
  }
}

export default withTranslations('RealTimeReport')(
  withStateLifter((props) => <RealTimeReport {...props} />)
)
