import { get, post, put, del } from './base'

export const getReasons = () => get('calls/reasons')

export const getAll = (projectId) =>
  get(`calls/${projectId}`).then((calls) => calls || [])
export const getOne = (projectId, callId) => get(`calls/${projectId}/${callId}`)

export const generate = (projectId) => post(`calls/generate/${projectId}`)
export const create = (projectId, call) => post(`calls/${projectId}`, call)
export const update = (projectId, callId, call) =>
  put(`calls/${projectId}/${callId}`, call)
export const remove = (projectId, callId) => del(`calls/${projectId}/${callId}`)

export const getLogs = (projectId, callId) =>
  get(`calls/${projectId}/${callId}/logs`).then((callLogs) => callLogs || [])

export const getCompletionUrl = (projectId, callId) =>
  get(`calls/${projectId}/${callId}/completion`)

export const getComment = (projectId, callId) =>
  get(`calls/${projectId}/${callId}/comment`)
export const putComment = (projectId, callId, model) =>
  put(`calls/${projectId}/${callId}/comment`, model)

export const syncTime = (projectId, callId) =>
  put(`calls/${projectId}/${callId}/sync-time`)
