import React from 'react'
import styled from 'styled-components/macro'
import { Button, Checkbox } from '../controls'
import Modal, { withModalManager } from '../Modal'
import { User, Role } from '../../api'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import { Input } from '../controls'
import Permissions from '../../permissions'

const PermissionKeys = Object.keys(Permissions)
const PermissionValues = Object.values(Permissions)

class RoleEdit extends React.Component {
  state = { fetching: true, roles: [], permissions: [] }

  users = []
  members = []

  async componentDidMount() {
    const { roleId } = this.props.params

    if (roleId) {
      Role.getOne(roleId)
        .then((role) => {
          FormHelper.setValues(
            this.props.lifter,
            [
              'roleName',
              'roleNameEn',
              'roleNameRu',
              ...role.permissions.map((p) => p.toString()),
            ],
            {
              roleName: role.roleName,
              roleNameEn: role.roleNameEn,
              roleNameRu: role.roleNameRu,
              ...Object.assign(
                {},
                ...role.permissions.map((p) => ({ [p.toString()]: true }))
              ),
            }
          )

          this.setState({ permissions: role.permissions })
        })
        .finally(() => {
          this.setState({ fetching: false })
        })
    } else {
      this.setState({ fetching: false })
    }
  }

  renderActions = () => (
    <div className="flex-row-center-space">
      <Button disabled={this.state.submitting} onClick={this.submit}>
        შენახვა
      </Button>
    </div>
  )

  submit = () => {
    const {
      onSuccess,
      closeModal,
      params: { roleId },
    } = this.props

    const values = FormHelper.getValues(this.props.lifter, [
      'roleName',
      'roleNameEn',
      'roleNameRu',
    ])

    const permissions = FormHelper.getValues(
      this.props.lifter,
      PermissionValues
    )

    const role = {
      ...values,
      permissions: Object.keys(permissions).filter((key) => permissions[key]),
    }

    const promise = roleId ? Role.update(roleId, role) : Role.create(role)

    promise
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { fetching } = this.state

    const { roleId } = this.props.params

    const roleName = FormHelper.getValue(this.props.lifter, 'roleName')

    if (fetching) return null

    return (
      <Modal
        header={roleId ? roleName : 'ახალი როლის შექმნა'}
        footer={this.renderActions}
        className="pad"
        css="width: 600px;"
        closeModal={this.props.closeModal}
      >
        <div className="flex-row-center gap-bottom">
          <label className="gap-right width-45">როლის დასახელება</label>

          <Input name="roleName" className="flex-fill" required />
        </div>

        <div className="flex-row-center gap-bottom">
          <label className="gap-right width-45">
            როლის დასახელება (ინგლისურად)
          </label>

          <Input name="roleNameEn" className="flex-fill" required />
        </div>

        <div className="flex-row-center gap-bottom">
          <label className="gap-right width-45">
            როლის დასახელება (რუსულად)
          </label>

          <Input name="roleNameRu" className="flex-fill" required />
        </div>

        <div
          className="flex-column pad-half"
          css="height: 300px; overflow: auto;"
        >
          {PermissionKeys.map((permKey, index) => (
            <Checkbox
              name={Permissions[permKey]}
              label={permKey}
              className={index < PermissionKeys.length - 1 ? 'gap-bottom' : ''}
            />
          ))}
        </div>
      </Modal>
    )
  }
}

export default withModalManager(withStateLifter(RoleEdit))
