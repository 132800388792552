import styled from 'styled-components/macro'

const Container = styled.div`
  user-select: none;
  flex: 1;
  display: flex;
  background-color: white;
  position: relative;

  .arrow-key-stepper {
    flex: 1;
  }

  .header-row {
    background-color: #e9ecef;
    border: 1px solid #c6c8ca;
  }

  .grid-body {
    outline: 0;
    border: 1px solid #c6c8ca;
    border-top: 0px none;
  }

  .cell {
    line-height: 33px;
    padding: 0px 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    outline: 0;

    &-edit {
      padding: 0px;
    }

    &-error {
      background-color: #f2dede !important;
    }
  }

  .cell > input {
    outline: 0;
    width: 100%;
    border: 0px none;
    line-height: 33px;
    height: 33px;
    padding: 0px 9px;

    &.error {
      background-color: #f2dede;
    }
  }

  .header-cell {
    position: relative;
    margin: 0;

    .header-title {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .ReactVirtualized__Table__sortableHeaderIcon {
        margin: 0;
        width: 20px;
      }
    }
  }

  .row-cell {
    border-right: 1px dotted #c6c8ca;
    border-bottom: 1px dotted #c6c8ca;

    &.selected-row {
      background-color: rgba(0, 0, 0, 0.075);

      &.selected-column {
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: -1px -1px 0 0 #c6c8ca;
        border-right: 1px solid #c6c8ca;
        border-bottom: 1px solid #c6c8ca;
      }
    }
  }

  .resize-handle {
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    cursor: col-resize;
    height: 100%;
    border-right: 1px solid #c6c8ca;
    z-index: 1000;
  }
`

export default Container
