import React from 'react'
import styled from 'styled-components/macro'
import { Input, Button, Checkbox, Select } from '../controls'
import Modal, { withModalManager } from '../Modal'
import withStateLifter from '../StateLifter'
import { FormHelper } from '../helpers'
import { User, Config } from '../../api'
import Loader from '../Loader'
import { withTranslations } from '../Translation'

const Form = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-gap: 12px;

  > label {
    display: flex;
    align-items: center;
  }
`

const fields = ['sipUsername', 'sipPassword', 'pbxServerId']

class UserEdit extends React.PureComponent {
  state = {
    fetching: true,
    fullName: '',
  }

  get lifter() {
    return this.props.lifter
  }

  componentDidMount() {
    Promise.all([Config.getPbxServers(), User.getOne(this.props.params.userId)])
      .then(([pbxServers, user]) => {
        FormHelper.setValues(this.lifter, fields, user)

        this.setState({
          fullName: user.fullName,
          pbxServers: pbxServers.map((x) => ({
            value: x.pbxServerId,
            label: x.pbxServerName,
          })),
        })
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  submit = () => {
    const {
      lifter,
      params: { userId },
      onSuccess,
      closeModal,
    } = this.props

    FormHelper.setTouched(lifter, fields, true)

    if (FormHelper.isInvalid(lifter, fields)) {
      return
    }

    this.setState({ submitting: true })

    const user = FormHelper.getValues(lifter, fields)

    User.update(userId, user)
      .then((result) => {
        if (onSuccess) onSuccess(result)

        closeModal()
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  renderActions = () => {
    return (
      <div className="text-right">
        <Button onClick={this.submit}>{this.props.trans.save}</Button>
      </div>
    )
  }

  handleTabClick = (index) => () => {
    this.setState({ activeTab: index })
  }

  tabSelected = (index) => {
    return this.state.activeTab === index ? ' tab-selected' : ''
  }

  render() {
    const { fetching, fullName, pbxServers } = this.state

    const { trans } = this.props

    if (fetching) return <Loader />

    return (
      <Modal
        header={`${trans.editUser} - ${fullName}`}
        footer={this.renderActions}
        css="width: 500px; min-height: 200px"
        className="pad"
        ref={(ref) => (this.modal = ref)}
        closeModal={this.props.closeModal}
      >
        <Form>
          <label>SIP Username</label>
          <Input
            name="sipUsername"
            placeholder={`${trans.forExample}: 99500`}
          />

          <label>SIP Password</label>
          <Input
            name="sipPassword"
            type="password"
            placeholder={trans.enterNewPassword}
          />

          <label>PBX Server</label>
          <Select
            name="pbxServerId"
            options={pbxServers}
            placeholder="PBX Server"
          />
        </Form>
      </Modal>
    )
  }
}

export default withModalManager(
  withTranslations('UserEdit')(withStateLifter(UserEdit))
)
