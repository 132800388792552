import React from 'react'
import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import { push } from 'connected-react-router'
import userManager from '../userManager'
import { User } from '../api'

class CallbackPage extends React.Component {
  render() {
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={(user) => {
          User.sync({ fullName: user.profile.name })

          this.props.dispatch(push('/'))
        }}
        errorCallback={(error) => {
          this.props.dispatch(push('/'))
          console.error(error)
        }}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    )
  }
}

export default connect()(CallbackPage)
