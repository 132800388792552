import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { Select, Input, DateInput } from '../controls'
import DataTable from '../DataTable/DataTable'
import ClientForm from './ClientForm'
import ClientDelete from './ClientDelete'
import { Client } from '../../api'
import { withTranslations } from '../Translation'

class ClientList extends React.Component {
  clients = []

  state = { clients: [], values: { clientName: '' } }

  memoizedColumns = null
  memoizedLocale = null
  memoizedTrans = null

  columns = () => {
    const { trans, locale } = this.props

    if (locale === this.memoizedLocale && trans === this.memoizedTrans)
      return this.memoizedColumns

    this.memoizedLocale = locale
    this.memoizedTrans = trans
    this.memoizedColumns = [
      {
        title: trans.clientName,
        name: 'clientName',
        required: true
      },
      {
        title: trans.contactPerson,
        name: 'contactPerson'
      },
      {
        title: trans.phoneNumber,
        name: 'phoneNumber'
      },
      {
        title: trans.email,
        name: 'email'
      }
    ]

    return this.memoizedColumns
  }

  onRowDoubleClick = ({ clientId }) => {
    this.setState({ clientId }, () => {
      this.editModal.openModal()
    })
  }

  getContextMenuItems = ({ clientId }) => {
    const { trans } = this.props

    var result = [
      {
        title: trans.change,
        action: () => {
          this.setState({ clientId }, () => {
            this.editModal.openModal()
          })
        }
      },
      {
        title: trans.refresh,
        action: () => {
          this.fetch()
        }
      },
      {
        title: trans.delete,
        action: () => {
          this.setState({ clientId }, () => {
            this.deleteModal.openModal()
          })
        }
      }
    ]

    return result
  }

  fetch = async () => {
    this.clients = await Client.getAll()
    this.filter()
  }

  componentDidMount() {
    this.fetch()
  }

  filter = () => {
    this.setState(state => {
      const { values } = state

      return {
        clients: this.clients.filter(
          c =>
            (!values.clientName ||
              (c.clientName || '')
                .toLowerCase()
                .includes(values.clientName.toLowerCase())) &&
            (!values.contactPerson ||
              (c.contactPerson || '')
                .toLowerCase()
                .includes(values.contactPerson.toLowerCase())) &&
            (!values.phoneNumber ||
              (c.phoneNumber || '')
                .toLowerCase()
                .includes(values.phoneNumber.toLowerCase())) &&
            (!values.email ||
              (c.email || '')
                .toLowerCase()
                .includes(values.email.toLowerCase()))
        )
      }
    })
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState(state => {
      return { values: { ...state.values, [name]: value } }
    }, this.filter)
  }

  handleSuccess = () => {
    this.fetch()
  }

  render() {
    const { trans } = this.props

    return (
      <div css="flex: 1; display: flex; flex-direction: column">
        <div css="margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center">
          <div css="display: flex; align-items: center;">
            <Input
              placeholder={trans.clientName}
              css="width: 200px"
              name="clientName"
              value={this.state.values.clientName}
              onChange={this.handleChange}
            />

            <Input
              placeholder={trans.contactPerson}
              css="margin-left: 10px; width: 200px"
              name="contactPerson"
              value={this.state.values.contactPerson}
              onChange={this.handleChange}
            />

            <Input
              placeholder={trans.phoneNumber}
              css="margin-left: 10px; width: 200px"
              name="phoneNumber"
              value={this.state.values.phoneNumber}
              onChange={this.handleChange}
            />

            <Input
              placeholder={trans.email}
              css="margin-left: 10px; width: 200px"
              name="email"
              value={this.state.values.email}
              onChange={this.handleChange}
            />

            <div className="gap-left">{this.state.clients.length}</div>
          </div>

          <ClientForm
            trigger={trans.newClient}
            onSuccess={this.handleSuccess}
          />

          <ClientForm
            ref={ref => (this.editModal = ref)}
            onSuccess={this.handleSuccess}
            clientId={this.state.clientId}
          />

          <ClientDelete
            clientId={this.state.clientId}
            ref={ref => (this.deleteModal = ref)}
            onSuccess={this.handleSuccess}
          />
        </div>

        <div css="display: flex; align-items: stretch; flex: 1">
          <DataTable
            excelExportName="clients"
            onRowDoubleClick={this.onRowDoubleClick}
            columns={this.columns()}
            rows={this.state.clients}
            getContextMenuItems={this.getContextMenuItems}
          />
        </div>
      </div>
    )
  }
}

export default withTranslations('ClientList')(ClientList)
