import React from 'react'
import styled from 'styled-components/macro'
import Excel from 'exceljs/modern.browser'
import {
  MultiGrid,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  ArrowKeyStepper,
} from 'react-virtualized'
import ContextMenu from '../ContextMenu'
import ClipboardImport from '../ClipboardImport'
import Container from './Container'
import { withTranslations } from '../Translation'

const ROW_HEIGHT = 34

class DataMatrix extends React.Component {
  constructor(props) {
    super(props)

    this._cache = new CellMeasurerCache({
      defaultWidth: 100,
      minWidth: 10,
      fixedHeight: true,
    })

    this.state = {
      scrollToRow: null,
      scrollToColumn: null,
      _cache: this._cache,
    }
  }

  handleScroll = (event) => {
    const { scrollTop: columnScrollTop } =
      this._grid._bottomLeftGrid._scrollingContainer

    const rowScrollLeft = this._grid._topRightGrid._scrollingContainer.scrollTop

    const { scrollTop, scrollLeft } = event.target

    if (columnScrollTop !== scrollTop) {
      this._grid._bottomLeftGrid._scrollingContainer.scrollTop = scrollTop
    }

    if (rowScrollLeft !== scrollLeft) {
      this._grid._topRightGrid._scrollingContainer.scrollLeft = scrollLeft
    }
  }

  componentWillUnmount() {
    this._grid._bottomRightGrid._scrollingContainer.removeEventListener(
      'scroll',
      this.handleScroll
    )
  }

  componentDidMount() {
    // react-virtualized MutliGrid doesn't always sync scroll due to react weird behavior
    // so we use native scroll event to sync always
    setTimeout(() => {
      if (
        this._grid &&
        this._grid._bottomRightGrid &&
        this._grid._bottomRightGrid._scrollingContainer
      ) {
        this._grid._bottomRightGrid._scrollingContainer.addEventListener(
          'scroll',
          this.handleScroll
        )
      }
    })
  }

  onCellFocus = (rowIndex, columnIndex) => () => {
    this.selectCell(rowIndex, columnIndex)
  }

  handleChange = (event) => {
    const { data, number, integer, unsigned, setData, valueKey } = this.props

    const { value } = event.target

    if (number && value) {
      if (!unsigned && (value.split('-').length > 2 || value.indexOf('-') > 0))
        return
      if (!integer && value.split('.').length > 2) return

      if (value !== '-' && value !== '.') {
        if (integer && isNaN(parseInt(value))) return

        if (!integer && isNaN(parseFloat(value))) return
      }
    }

    let datum = this.getInputDatum()

    if (datum) {
      datum[valueKey] = value
    } else {
      const { rows, columns, rowKey, columnKey } = this.props
      const { scrollToColumn, scrollToRow } = this.state

      const rowValue = rows[scrollToRow - 1].value
      const columnValue = columns[scrollToColumn - 1].value

      data.push({
        [rowKey]: rowValue,
        [columnKey]: columnValue,
        [valueKey]: value,
      })
    }

    setData([...data])
  }

  onInputFocus = (event) => {
    event.target.select()
  }

  handleKeyPress = (rowIndex, columnIndex) => (event) => {
    const {
      key,
      target: { value },
    } = event

    const { number, integer, unsigned } = this.props

    if (
      number &&
      (key < '0' ||
        key > '9' ||
        (integer && key === '.') ||
        (unsigned && key === '-') ||
        (!unsigned && key === '-' && (value.match(/\-/g) || []).length > 0) ||
        (!integer && key === '.' && (value.match(/\./g) || []).length > 0))
    )
      event.preventDefault()
  }

  cellRenderer = ({ key, rowIndex, columnIndex, parent, style }) => {
    const {
      columns,
      rows,
      totalsRow,
      totalsColumn,
      data,
      rowKey,
      columnKey,
      totalsRowHeader,
      totalsColumnHeader,
      valueKey,
      editable,
      formatter,
    } = this.props

    const { scrollToRow, scrollToColumn } = this.state

    let content

    if (rowIndex === 0 && columnIndex === 0) {
      content = (
        <div key={key} style={style} className="cell header-cell">
          {this.props.header}
        </div>
      )
    } else if (rowIndex === 0) {
      content = (
        <div className="cell row-header-cell" key={key} style={style}>
          {totalsColumn && columnIndex === columns.length + 1
            ? totalsColumnHeader
            : columns[columnIndex - 1].title}
        </div>
      )
    } else if (columnIndex === 0) {
      content = (
        <div className="cell column-header-cell" key={key} style={style}>
          {totalsRow && rowIndex === rows.length + 1
            ? totalsRowHeader
            : rows[rowIndex - 1].title}
        </div>
      )
    } else if (
      totalsRow &&
      totalsColumn &&
      rowIndex === rows.length + 1 &&
      columnIndex === columns.length + 1
    ) {
      content = (
        <div className="cell totals-cell" key={key} style={style}>
          {Array.isArray(valueKey)
            ? formatter(
                Object.assign(
                  ...valueKey.map((key) => ({
                    [key]: data.reduce(
                      (acc, cur) =>
                        (acc += cur[key] ? parseFloat(cur[key]) : 0),
                      0
                    ),
                  }))
                )
              )
            : data.reduce(
                (acc, cur) =>
                  (acc += cur[valueKey] ? parseFloat(cur[valueKey]) : 0),
                0
              ) || ''}
        </div>
      )
    } else if (totalsRow && rowIndex === rows.length + 1) {
      content = (
        <div className="cell totals-cell" key={key} style={style}>
          {Array.isArray(valueKey)
            ? formatter(
                Object.assign(
                  ...valueKey.map((key) => ({
                    [key]: data
                      .filter(
                        (d) => d[columnKey] === columns[columnIndex - 1].value
                      )
                      .reduce(
                        (acc, cur) =>
                          (acc += cur[key] ? parseFloat(cur[key]) : 0),
                        0
                      ),
                  }))
                )
              )
            : data
                .filter((d) => d[columnKey] === columns[columnIndex - 1].value)
                .reduce(
                  (acc, cur) =>
                    (acc += cur[valueKey] ? parseFloat(cur[valueKey]) : 0),
                  0
                ) || ''}
        </div>
      )
    } else if (totalsColumn && columnIndex === columns.length + 1) {
      content = (
        <div className="cell totals-cell" key={key} style={style}>
          {Array.isArray(valueKey)
            ? formatter(
                Object.assign(
                  ...valueKey.map((key) => ({
                    [key]: data
                      .filter((d) => d[rowKey] === rows[rowIndex - 1].value)
                      .reduce(
                        (acc, cur) =>
                          (acc += cur[key] ? parseFloat(cur[key]) : 0),
                        0
                      ),
                  }))
                )
              )
            : data
                .filter((d) => d[rowKey] === rows[rowIndex - 1].value)
                .reduce(
                  (acc, cur) =>
                    (acc += cur[valueKey] ? parseFloat(cur[valueKey]) : 0),
                  0
                ) || ''}
        </div>
      )
    } else {
      const cellValue = this.getValue(rowIndex, columnIndex)
      const isValueValid = this.isValueValid(cellValue)

      const editing =
        editable && rowIndex === scrollToRow && columnIndex === scrollToColumn

      content = (
        <div
          tabIndex={!editing ? 0 : undefined}
          onFocus={this.onCellFocus(rowIndex, columnIndex)}
          className={
            'cell data-cell' +
            (scrollToRow === rowIndex ? ' selected-row' : '') +
            (scrollToColumn === columnIndex ? ' selected-column' : '') +
            (editing ? ' cell-edit' : '') +
            (!editing && !isValueValid ? ' cell-error' : '')
          }
          style={style}
          onClick={() => this.selectCell(rowIndex, columnIndex)}
        >
          {editing ? (
            <input
              ref={(ref) => (this.input = ref)}
              autoFocus
              className={!isValueValid ? 'error' : undefined}
              value={cellValue}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress(rowIndex, columnIndex)}
              onFocus={this.onInputFocus}
            />
          ) : (
            cellValue
          )}
        </div>
      )
    }

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {content}
      </CellMeasurer>
    )
  }

  isValueValid = (value) => {
    return !this.props.required || !!value
  }

  getInputDatum = (create) => {
    const { data, rows, columns, rowKey, columnKey } = this.props
    const { scrollToRow, scrollToColumn } = this.state

    const rowValue = rows[scrollToRow - 1].value
    const columnValue = columns[scrollToColumn - 1].value

    const datum = data.find(
      (datum) => datum[rowKey] === rowValue && datum[columnKey] === columnValue
    )

    return datum
  }

  getValue = (rowIndex, columnIndex) => {
    const { data, rows, columns, rowKey, columnKey, valueKey, formatter } =
      this.props

    const rowValue = rows[rowIndex - 1].value
    const columnValue = columns[columnIndex - 1].value

    const datum = data.find(
      (datum) => datum[rowKey] === rowValue && datum[columnKey] === columnValue
    )

    return (datum && (formatter ? formatter(datum) : datum[valueKey])) || ''
  }

  static getDerivedStateFromProps(props, state) {
    if (state.rows !== props.rows || state.columns !== props.columns) {
      state._cache.clearAll()

      return {
        rows: props.rows,
        columns: props.columns,
        scrollToRow: null,
        scrollToColumn: null,
      }
    }

    return null
  }

  isInputValid = () => {
    const { scrollToColumn, scrollToRow } = this.state

    if (scrollToColumn > 0 && scrollToRow > 0) {
      const value = this.getValue(scrollToRow, scrollToColumn)
      return this.isValueValid(value)
    }

    return true
  }

  selectCell = (rowIndex, columnIndex) => {
    const { scrollToRow, scrollToColumn } = this.state

    if (
      rowIndex > 0 &&
      columnIndex > 0 &&
      (scrollToRow !== rowIndex || scrollToColumn !== columnIndex)
    ) {
      if (!this.isInputValid()) {
        this.input.focus()
        return
      }

      this.setState({
        scrollToRow: rowIndex,
        scrollToColumn: columnIndex,
      })
    }
  }

  columnWidth = (params) => {
    // need to increase width by 1 pixel, ellipsis to disappear
    return this._cache.columnWidth(params) + 1
  }

  handleScrollChange = ({ scrollToRow, scrollToColumn }) => {
    this.selectCell(scrollToRow, scrollToColumn)
  }

  exportToExcel = () => {
    const {
      header,
      columns,
      rows,
      data,
      rowKey,
      columnKey,
      valueKey,
      totalsRow,
      totalsColumn,
      totalsColumnHeader,
      totalsRowHeader,
      formatter,
    } = this.props
    var workbook = new Excel.Workbook()

    var sheet = workbook.addWorksheet(header)

    sheet.columns = [
      { header, key: 'header' },
      ...columns.map((c) => ({
        header: c.title,
        key: c.value === undefined ? c.title : c.value,
      })),
      ...(totalsColumn ? [{ header: totalsColumnHeader, key: 'total' }] : []),
    ]

    sheet.addRows(
      rows.map((row) => ({
        header: row.title,
        ...Object.assign(
          ...columns.map((col) => {
            const datum =
              data.find(
                (datum) =>
                  datum[rowKey] === row.value && datum[columnKey] === col.value
              ) || {}

            const columnValue = col.value === undefined ? col.title : col.value

            return {
              [columnValue]: formatter ? formatter(datum) : datum[valueKey],
            }
          })
        ),
        total: totalsColumn
          ? Array.isArray(valueKey)
            ? formatter(
                Object.assign(
                  ...valueKey.map((key) => ({
                    [key]: data
                      .filter((d) => d[rowKey] === row.value)
                      .reduce(
                        (acc, cur) =>
                          (acc += cur[key] ? parseFloat(cur[key]) : 0),
                        0
                      ),
                  }))
                )
              )
            : columns.reduce(
                (tot, col) =>
                  tot +
                  parseFloat(
                    (data.find(
                      (datum) =>
                        datum[rowKey] === row.value &&
                        datum[columnKey] === col.value
                    ) || {})[valueKey] || 0
                  ),
                0
              ) || ''
          : undefined,
      }))
    )

    if (totalsRow) {
      sheet.addRow({
        header: totalsRowHeader,
        ...Object.assign(
          ...columns.map((col) => ({
            [col.value === undefined ? col.title : col.value]:
              Array.isArray(valueKey) && formatter
                ? formatter(
                    Object.assign(
                      ...valueKey.map((key) => ({
                        [key]:
                          rows.reduce(
                            (tot, row) =>
                              tot +
                              parseFloat(
                                (data.find(
                                  (datum) =>
                                    datum[rowKey] === row.value &&
                                    datum[columnKey] === col.value
                                ) || {})[key] || 0
                              ),
                            0
                          ) || 0,
                      }))
                    )
                  )
                : rows.reduce(
                    (tot, row) =>
                      tot +
                      parseFloat(
                        (data.find(
                          (datum) =>
                            datum[rowKey] === row.value &&
                            datum[columnKey] === col.value
                        ) || {})[valueKey] || 0
                      ),
                    0
                  ) || '',
          }))
        ),
      })
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = (this.props.header || 'download') + '.xlsx'
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  importFromClipboard = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard
        .readText()
        .then((clipText) => this.importFromText(clipText))
        .catch(() => {
          this.importModal.openModal()
        })
    } else {
      this.importModal.openModal()
    }
  }

  clipboardImportMenuItem = () => ({
    title: this.props.trans.importFromClipboard,
    action: this.importFromClipboard,
  })

  excelExportMenuItem = () => ({
    title: this.props.trans.exportToExcel,
    action: this.exportToExcel,
  })

  handleClipboardTextSuccess = (text) => {
    this.importFromText(text)
  }

  importFromText = (text) => {
    const { scrollToRow, scrollToColumn } = this.state
    const { rows, columns, data, rowKey, columnKey, valueKey, setData } =
      this.props

    const lines = text.split(/\r?\n/)

    if (scrollToRow !== null && scrollToColumn !== null) {
      let rowIndex = scrollToRow - 1
      let columnIndex = scrollToColumn - 1

      for (const line of lines) {
        if (!line.trim()) break

        const values = line.split('\t')
        const row = rows[rowIndex]
        if (rowIndex >= rows.length) break

        let colIndex = columnIndex
        for (const value of values) {
          if (colIndex >= columns.length) break

          const column = columns[colIndex]
          const datum = data.find(
            (datum) =>
              datum[rowKey] === row.value && datum[columnKey] === column.value
          )

          if (datum) {
            datum[valueKey] = value
          } else {
            data.push({
              [rowKey]: row.value,
              [columnKey]: column.value,
              [valueKey]: value,
            })
          }

          colIndex++
        }

        rowIndex++
      }

      setData([...data])
    }
  }

  onGridContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { pageX, pageY } = event

    const menuItems = []

    if (this.props.excelExport) {
      menuItems.push(this.excelExportMenuItem())
    }

    if (this.props.clipboardImport) {
      menuItems.push(this.clipboardImportMenuItem())
    }

    if (menuItems.length) {
      setTimeout(() => {
        this.setState({
          isMenuOpen: true,
          menuItems: menuItems,
          pageX,
          pageY,
        })
      })
    }
  }

  closeMenu = () => {
    this.setState({ isMenuOpen: false })
  }

  render() {
    const {
      columns,
      rows,
      data,
      totalsRow,
      totalsColumn,
      clipboardImport,
      rowColumnMaxWidth,
      totalsRowHeader,
      totalsColumnHeader,
    } = this.props

    const { isMenuOpen, menuItems, pageX, pageY, scrollToRow, scrollToColumn } =
      this.state

    return (
      <>
        {clipboardImport && (
          <ClipboardImport
            ref={(ref) => (this.importModal = ref)}
            onSuccess={this.handleClipboardTextSuccess}
          />
        )}

        {isMenuOpen && (
          <ContextMenu
            close={this.closeMenu}
            items={menuItems}
            pageX={pageX}
            pageY={pageY}
          />
        )}

        <Container
          onKeyDown={this.handleKeyDown}
          onContextMenu={this.onGridContextMenu}
          rowColumnMaxWidth={rowColumnMaxWidth}
        >
          <ArrowKeyStepper
            className="arrow-key-stepper"
            onScrollToChange={this.handleScrollChange}
            mode="cells"
            rowCount={rows.length + 1}
            columnCount={columns.length + 1}
            isControlled
            scrollToRow={scrollToRow}
            scrollToColumn={scrollToColumn}
          >
            {({
              onSectionRendered,
              scrollToRow: rowIndex,
              scrollToColumn: columnIndex,
            }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <MultiGrid
                    // onScroll={({ may need in future
                    //   clientHeight,
                    //   clientWidth,
                    //   scrollHeight,
                    //   scrollLeft,
                    //   scrollTop,
                    //   scrollWidth
                    // }) => {
                    //   //console.log(this._grid, scrollTop, scrollHeight, clientHeight)
                    //   this.setState({
                    //     scrollLeft,//: scrollLeft + (scrollWidth - clientWidth + 8 ===
                    //     // scrollLeft
                    //     //   ? 2
                    //     //   : 0),
                    //     scrollTop:
                    //       scrollTop + (scrollHeight - clientHeight + 9 ===
                    //       scrollTop
                    //         ? 2
                    //         : 0)
                    //   })
                    // }}
                    // scrollTop={this.state.scrollTop}
                    // scrollLeft={this.state.scrollLeft}
                    ref={(ref) => (this._grid = ref)}
                    columns={columns}
                    rows={rows}
                    data={data}
                    cellRenderer={this.cellRenderer}
                    columnWidth={this.columnWidth}
                    fixedRowCount={1}
                    fixedColumnCount={1}
                    height={height}
                    rowHeight={ROW_HEIGHT}
                    rowCount={rows.length + 1 + (totalsRow ? 1 : 0)}
                    columnCount={columns.length + 1 + (totalsColumn ? 1 : 0)}
                    width={width}
                    overscanRowCount={0}
                    overscanColumnCount={0}
                    //deferredMeasurementCache={this._cache}
                    classNameTopRightGrid="row-header"
                    classNameBottomLeftGrid="column-header"
                    classNameBottomRightGrid="grid-body"
                    onSectionRendered={onSectionRendered}
                    scrollToRow={rowIndex}
                    scrollToColumn={columnIndex}
                    totalsRowHeader={totalsRowHeader}
                    totalsColumnHeader={totalsColumnHeader}
                  />
                )}
              </AutoSizer>
            )}
          </ArrowKeyStepper>
        </Container>
      </>
    )
  }
}

export default withTranslations('DataMatrix')(DataMatrix)
