import React from 'react'
import styled from 'styled-components/macro'

const Loader = styled.div`
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1;

  .overlay {
    background-color: white;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    opacity: 0.5;
  }

  .spinner {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 6px solid gainsboro;
    border-bottom: 6px solid rgb(23, 162, 184);
    border-left: 6px solid rgb(23, 162, 184);
    animation: spin1 1s infinite linear;
    opacity: 1;
  }

  @keyframes spin1 {
    100% {
      transform: rotate(360deg);
    }
  }
`

export default () => (
  <Loader>
    <div className="overlay"></div>
    <div className="spinner"></div>
  </Loader>
)
