import React from 'react'
import LocaleContext, { translationStore } from './LocaleContext'
import Loader from '../Loader'

const withTranslations = (fileKey) => (Component) => {
  class Translations extends React.Component {
    static contextType = LocaleContext

    constructor(props, context) {
      super(props, context)

      this.state = {
        fetching:
          !translationStore[fileKey] ||
          !translationStore[fileKey][context.locale],
      }
    }

    childRef = React.createRef()

    componentDidMount() {
      if (
        !translationStore[fileKey] ||
        !translationStore[fileKey][this.context.locale]
      ) {
        return import(
          /* webpackChunkName: "[request]" */ `../../translations/${fileKey}.${this.context.locale}.json`
        ).then((translations) => {
          if (!translationStore[fileKey]) {
            translationStore[fileKey] = { inUse: true }
          } else {
            translationStore[fileKey].inUse = true
          }

          translationStore[fileKey][this.context.locale] = translations
          this.setState({ fetching: false })
        })
      }
    }

    componentWillUnmount() {
      if (translationStore[fileKey]) {
        translationStore[fileKey].inUse = false
      }
    }

    render() {
      if (this.state.fetching) return <Loader />

      const fileStore = translationStore[fileKey]

      return (
        <Component
          ref={this.childRef}
          {...this.props}
          trans={(fileStore && fileStore[this.context.locale]) || {}}
          locale={this.context.locale}
          isDefaultLocale={this.context.locale === 'ka'}
        />
      )
    }
  }

  return Translations
}

export default withTranslations
