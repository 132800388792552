import React from 'react'
import ReactSelect from 'react-select'
import { DesktopQuery } from '../MediaQuery'
import styled from 'styled-components/macro'
import { LifterContext } from '../StateLifter'

const StyledSelect = styled(ReactSelect)`
  svg {
    border-left: 1px solid #ced4da !important;
    padding-left: 7px;
    width: 28px;
  }
`

class Select extends React.PureComponent {
  constructor(props, context) {
    super(props, context)

    const { name } = props

    if (context && context.getState && name) {
      const state = context.getState(name)

      if (!state || !state.option) {
        const value = state ? state.value : props.initialValue || ''

        const option = props.options
          ? props.isMulti
            ? props.options.filter((option) => value.includes(option.value))
            : props.options.find((opt) => opt.value === value)
          : undefined

        context.setState(name, {
          value,
          option,
          touched: false,
          invalid: this.invalidate(value),
        })
      }

      context.setNotifier(name, this.handleNotify)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.handleNotify()
    }
  }

  handleNotify = () => {
    if (!this.context.getState) return

    const { name, options, isMulti } = this.props

    const state = this.context.getState(name)

    if (
      !isMulti &&
      state &&
      ((state.value && !state.option && options.length) ||
        (state.option && state.value !== state.option.value))
    ) {
      const option = options.find((opt) => opt.value === state.value)

      if (option !== state.option) {
        this.context.setState(name, {
          option,
          invalid: this.invalidate(state.value),
        })
      }
    } else {
      this.forceUpdate()
    }
  }

  componentWillUnmount() {
    const { name } = this.props
    if (this.hasLifter && name) {
      this.context.removeNotifier(name)
    }
  }

  get hasLifter() {
    return this.context && !!this.context.getState
  }

  static contextType = LifterContext

  handleChange = (option) => {
    const { name, onChange, isMulti, onChanged } = this.props

    if (this.hasLifter) {
      const value =
        option && (isMulti ? option.map((opt) => opt.value) : option.value)

      const state = {
        value,
        option,
        touched: true,
        invalid: this.invalidate(value),
      }

      this.changePromise = new Promise((resolve) => {
        this.context.setState(name, state, () => {
          resolve()
          delete this.changePromise
          if (onChanged) onChanged(name, state)
        })
      })
    }

    if (onChange) onChange(option)
  }

  invalidate = (value) => {
    let invalid = false

    const { required } = this.props

    if (this.hasLifter && required) {
      if (required && !value) {
        invalid = true
      }
    }

    return invalid
  }

  handleBlur = async (event) => {
    const { name, onBlur } = this.props

    if (this.hasLifter && name) {
      if (this.changePromise) {
        await this.changePromise // wait for change event to update value in state
      }

      const state = {
        touched: true,
        invalid: this.invalidate(this.getValue()),
      }

      this.context.setState(name, state)
    }

    if (onBlur) this.onBlur(event)
  }

  getState = (name) => this.hasLifter && name && this.context.getState(name)

  getValue = () => {
    const state = this.getState(this.props.name)
    return (state && state.value) || ''
  }

  getOption = () => {
    const state = this.getState(this.props.name)
    return (state && state.option) || ''
  }

  isTouched = () => {
    const state = this.getState(this.props.name)
    return (state && state.touched) || false
  }

  isInvalid = () => {
    const state = this.getState(this.props.name)
    return (state && state.invalid) || false
  }

  customStyles = {
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        paddingLeft: 0,
      }
    },

    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        paddingLeft: 0,
      }
    },

    singleValue: (provided, state) => {
      const newStyle = {
        ...provided,
        textOverflow: 'initial',
      }

      return newStyle
    },
    control: (provided, state) => {
      const error = this.isInvalid() && this.isTouched()

      const newStyle = {
        ...provided,
        boxShadow: 'none',
        transition:
          'border-color .10s ease-in-out, box-shadow .10s ease-in-out;',
        borderColor: `${error ? '#dc3545' : '#ced4da'} !important`,
      }

      if (state.menuIsOpen) {
        newStyle.borderBottomLeftRadius = 0
        newStyle.borderBottomRightRadius = 0
      }

      if (state.isFocused) {
        newStyle.borderColor = `${error ? '#dc3545' : '#81b2e7'} !important`
        newStyle.boxShadow = error
          ? '0 0 0 2px rgba(220,53,69,.25)'
          : '0 0 0 2px rgba(129,178,231,.3)'
      }

      return newStyle
    },
    menu: (provided, state) => {
      return {
        ...provided,
        width: 'auto',
        overflow: 'hidden',
        marginTop: -3,
        left: -2,
        right: -2,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: '4px 4px 11px hsla(0, 0%, 0%, 0.1)',
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        borderRadius: 5,
        backgroundColor: 'whitesmoke',
      }
    },
    menuList: (provided, state) => {
      const error = this.isInvalid() && this.isTouched()

      return {
        ...provided,
        border: `1px solid ${error ? '#dc3545' : '#81b2e7'}`,
        borderRadius: 5,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: 0,
        boxShadow: error
          ? '0 0 0 4px rgba(220,53,69,.25)'
          : '0 0 0 4px rgba(129,178,231,.3)',
        maxHeight: provided.maxHeight - 3,
      }
    },
    option: (provided, state) => {
      const newStyle = { ...provided, paddingLeft: '10px' }

      if (state.isSelected) {
        newStyle.backgroundColor = '#76a3d2'
      }

      return newStyle
    },
  }

  render() {
    const option = this.getOption()
    const { isClearable } = this.props

    return (
      <DesktopQuery>
        {(isDesktop) => (
          <StyledSelect
            value={option}
            styles={this.customStyles}
            placeholder=""
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            //menuIsOpen
            menuPortalTarget={document.body}
            isSearchable={isDesktop}
            components={{
              ...(option && isClearable
                ? { DropdownIndicator: () => null }
                : {}),
              IndicatorSeparator: () => null,
            }}
            {...this.props}
          />
        )}
      </DesktopQuery>
    )
  }
}

export default Select
