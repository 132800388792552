import { get, put, post } from './base'

export const getAll = projectId => get(`quotas/${projectId}`)
export const update = (projectId, quotas) => put(`quotas/${projectId}`, quotas)
export const check = (
  projectId,
  callId,
  field1ValueId,
  field2ValueId,
  field3ValueId,
  field4ValueId,
  field5ValueId
) =>
  post(`quotas/${projectId}/check/${callId}`, {
    field1ValueId,
    field2ValueId,
    field3ValueId,
    field4ValueId,
    field5ValueId
  })

export const report = projectId => get(`quotas/${projectId}/report`)