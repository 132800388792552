import React from 'react'
import styled from 'styled-components/macro'
import ReactModal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -51%)',
    padding: 0,
    //border: '1px solid #9ac29a',
    //boxShadow: '0 0 0 3px rgba(0,123,255,.25)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    borderRadius: 0,
    backgroundColor: 'transparent',
    border: 'none'
  },
  overlay: {
    backgroundColor: 'rgba(108, 116, 125, 0.75)'
  }
}

ReactModal.setAppElement('#root')

const Header = styled.div`
  padding: 10px 12px;
  background-color: #76a3d2;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &.error {
    background-color: #e25b67;
  }

  &.info {
    background-color: #45b5c6;
  }
`

const Hr = styled.hr`
  border: 0px none;
  border-top: 1px solid #ced4da;
  width: 100%;
  margin: 0px;
  position: absolute;
`

const Footer = styled.div`
  padding: 10px 12px;
  color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
`

const Content = styled.div`
  overflow: visible;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom-left-radius: ${props => (!props.hasFooter ? '4px' : null)};
  border-bottom-right-radius: ${props => (!props.hasFooter ? '4px' : null)};
`

class Modal extends React.Component {
  renderHeader() {
    let { header } = this.props

    if (typeof header === 'function') {
      const HeaderContent = header
      header = <HeaderContent />
    }

    return header
  }

  renderFooter() {
    let { footer } = this.props

    if (typeof footer === 'function') {
      const FooterContent = footer
      footer = <FooterContent />
    }

    return footer
  }

  render() {
    const footer = this.renderFooter()

    const { type } = this.props

    return (
      <ReactModal
        isOpen
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <Header
          className={type}
          error={this.props.error}
          info={this.props.info}
        >
          {this.renderHeader()}
          <div>
            <button
              css="border: none; background: none; color: #fff; font-size: 20px !important;"
              onClick={this.props.closeModal}
            >
              ×
            </button>
          </div>
        </Header>

        <Content
          hasFooter={!!this.props.footer}
          className={this.props.className}
        >
          {this.props.children}
        </Content>

        {footer && (
          <div>
            <Hr />
          </div>
        )}

        {this.props.footer && <Footer>{footer}</Footer>}
      </ReactModal>
    )
  }
}

export default Modal
