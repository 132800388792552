import { get as _get, put as _put } from './base'

export const get = (projectId) => _get(`config/${projectId}`)
export const put = (projectId, config) => _put(`config/${projectId}`, config)

export const getCall = (projectId) => _get(`config/${projectId}/call`)
export const putCall = (projectId, config) =>
  _put(`config/${projectId}/call`, config)

export const getSip = () => _get(`config/sip`)
export const getPbxServers = () => _get(`config/pbxservers`)
