import React from 'react'
import LocaleContext, { translationStore } from './LocaleContext'
import Loader from '../Loader'

export let currentLocale = 'ka'

class LocaleProvider extends React.Component {
  state = { loading: true }

  setLocale = (locale) => {
    const fileKeys = Object.keys(translationStore)
    const inUseKeys = fileKeys.filter(
      (key) => translationStore[key].inUse && !translationStore[key][locale]
    )

    this.setState({ loading: true })
    Promise.all(
      inUseKeys.map((key) => {
        return import(
          /* webpackChunkName: "[request]" */ `../../translations/${key}.${locale}.json`
        ).then((translations) => {
          if (!translationStore[key]) {
            translationStore[key] = {}
          }
          translationStore[key][locale] = translations
        })
      })
    )
      .then(() => {
        this.setState({ locale })
        currentLocale = locale
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  state = { locale: 'ka', setLocale: this.setLocale }

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        <LocaleContext.Provider value={this.state}>
          {this.props.children}
        </LocaleContext.Provider>
      </>
    )
  }
}

export default LocaleProvider
